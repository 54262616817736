import React from "react";
import { CheckEmojy } from "../utils/emoji-code";

const Emojies = ({ text }) => {
  const emojiRegex =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FAB0}-\u{1FABF}\u{1FAC0}-\u{1FAFF}\u{1F200}-\u{1F251}\u{1F004}\u{1F0CF}\u{1F18E}\u{1F191}-\u{1F251}\u{1F004}\u{1F0CF}\u{1F191}-\u{1F251}\u{1F004}\u{1F0CF}\u{1F251}\u{2B50}\u{1F004}\u{1F0CF}]/u;

  const regex = emojiRegex;

  let newText = CheckEmojy(text);

  return (
    <React.Fragment>
      <p
        style={{
          fontSize:
            regex?.test(text) && (text?.length === 1 || text?.length === 2)
              ? "30px"
              : "",
        }}
      >
        {newText}
      </p>
    </React.Fragment>
  );
};
export default Emojies;
