import { TYPES } from "../actionTypes";

const {
  USER_LOGOUT,
  DELETE_RATING,
  SET_CLIENT_ID,
  USER_AUTH_FAIL,
  CLEAR_AUTH_DATA,
  USER_AUTH_LOADING,
  USER_AUTH_SUCCESS,
  UPDATE_USER_BY_ID,
  GET_ALL_USERS_FAIL,
  USER_ONLINE_STATUS,
  UPDATE_CONVERSATION,
  UPDATE_USER_PROFILE,
  UPDATE_CLIENT_BY_ID,
  UPDATE_ONLINE_STATUS,
  GET_ALL_USERS_LOADING,
  GET_ALL_USERS_SUCCESS,
  UPDATE_ALL_USERS_ARRAY,
  GET_USER_BY_ID_SUCCESS,
  UPDATE_CONVERSATION_BY_INDEX,
  UPDATE_CONVERSATION_WITH_SOCKET,
} = TYPES;

const initialState = {
  loading: false,
  allUsers: {},
  userData: {},
  userById: {},
  clientObj: {},
  error: false,
  isAuthenticated: false,
  showTyping: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
        isAuthenticated: true,
      };
    case UPDATE_CONVERSATION:
      let conversation = state?.userData?.data?.conversations?.findIndex(
        (conId) => {
          return conId?._id === action.payload?.conversationId;
        }
      );
      state.userData.data.conversations[conversation].messages = [
        ...state.userData.data.conversations[conversation].messages,
        action.payload,
      ];
      return {
        ...state,
        userData: state.userData,
      };
    case UPDATE_CONVERSATION_BY_INDEX:
      let conversationI = state?.userData?.data?.conversations?.findIndex(
        (conId) => {
          return conId?._id === action.payload?.conversationId;
        }
      );
      let messageI = state.userData.data.conversations[
        conversationI
      ].messages.findIndex((x) => {
        return x?.indexOf === action.payload.indexOf;
      });
      state.userData.data.conversations[conversationI].messages[messageI] =
        action.payload;
      return {
        ...state,
        userData: state.userData,
      };
    case UPDATE_CONVERSATION_WITH_SOCKET:
      let selectedConversationI =
        state.userData?.data?.conversations?.findIndex((x) => {
          return x?._id === action.payload?.conversationId;
        });
      state.userData.data.conversations[selectedConversationI].messages = [
        ...state.userData.data.conversations[selectedConversationI].messages,
        action.payload,
      ];
      return {
        ...state,
        userData: state.userData,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    case CLEAR_AUTH_DATA:
      return {
        ...state,
        loading: false,
        userData: {},
        isAuthenticated: false,
      };
    case USER_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        userData: action?.payload,
        error: true,
      };
    case GET_ALL_USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allUsers: action.payload,
      };
    case UPDATE_ALL_USERS_ARRAY:
      state.allUsers.data = [...state.allUsers.data, action.payload];
      return {
        ...state,
        loading: false,
        allUsers: state.allUsers,
      };
    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        allUsers: action?.payload,
        error: true,
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userById: action.payload,
      };
    case UPDATE_USER_BY_ID:
      if (state?.userById?.conversations.length === 0) {
        let conversations = [
          {
            messages: [
              {
                conversationId: action.payload?.conversationId,
                createdAt: action.payload?.createdAt,
                receiverId: action.payload?.receiverId,
                senderId: action.payload?.senderId,
                text: action.payload?.text,
                updatedAt: action.payload?.updatedAt,
                _id: action.payload?._id,
              },
            ],
          },
        ];
        state.userById.conversations = conversations;
      } else {
        state?.userById?.conversations[0]?.messages?.push(action.payload);
      }
      return {
        ...state,
        loading: false,
        userById: state.userById,
      };
    case SET_CLIENT_ID:
      return {
        ...state,
        clientObj: action.payload,
      };
    case UPDATE_CLIENT_BY_ID:
      let foundIndex = state.allUsers?.data?.findIndex((x) => {
        return x?._id === action.payload._id;
      });
      state.allUsers.data[foundIndex] = action.payload;
      return {
        ...state,
        loading: false,
        allUsers: state.allUsers,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        allUsers: {},
        userData: {},
        userById: {},
        clientObj: {},
        error: false,
        isAuthenticated: false,
        showTyping: false,
      };
    case USER_ONLINE_STATUS:
      state.userData.data.is_user_online = action.payload;
      return {
        ...state,
        userData: state.userData,
      };
    case DELETE_RATING:
      let filteredRating = state?.userData?.data?.ratings?.filter((rating) => {
        return rating?._id !== action.payload?._id;
      });
      state.userData.data.ratings = filteredRating;
      return {
        ...state,
        userData: state.userData,
      };
    case UPDATE_ONLINE_STATUS:
      let user = state?.allUsers?.data?.find((user) => {
        return user?._id === action.payload?.userId;
      });
      if (!user) return;
      let userIndex = state.allUsers?.data?.findIndex((user) => {
        return user?._id === action.payload?.userId;
      });
      state.allUsers.data[userIndex].is_user_online =
        action.payload?.onlineStatus;
      return {
        ...state,
        allUsers: state.allUsers,
      };
    default:
      return state;
  }
}
