import { combineReducers } from "redux";
import faqReducer from "./faqReducer";
import botReducer from "./botReducer";
import userReducer from "./userReducer";
import chatReducer from "./chatReducer";
import callReducer from "./callReducer";
import widgetReducer from "./widgetReducer";
import socketReducer from "./socketReducer";
import sessionReducer from "./sessionReducer";
import responseReducer from "./responseReducer";
import operatorReducer from "./operatorReducer";
import groupChatReducer from "./groupChatReducer";
import shareFileReducer from "./shareFileReducer";
import departmentReducer from "./departmentReducer";
import engageEntryReducer from "./engageEntryReducer";
import applicationReducer from "./applicationReducer";
import notificationReducer from "./notificationReducer";
import emailTemplateReducer from "./emailTemplateReducer";
import corfirmationModalReducer from "./corfirmationModalReducer";

const rootReducer = combineReducers({
  faqReducer,
  botReducer,
  userReducer,
  chatReducer,
  callReducer,
  socketReducer,
  widgetReducer,
  sessionReducer,
  operatorReducer,
  responseReducer,
  groupChatReducer,
  shareFileReducer,
  departmentReducer,
  engageEntryReducer,
  applicationReducer,
  notificationReducer,
  emailTemplateReducer,
  corfirmationModalReducer,
});

export default rootReducer;
