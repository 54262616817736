import { TYPES } from "../actionTypes";
import { NAVIGATOR } from "../../constant";

const {
  USER_LOGOUT,
  APP_STATICS,
  SET_ACTIVE_TAB,
  UPDATE_APP_LOGO,
  GET_ONLINE_USER,
  GET_APPLICATION_ERROR,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_LOADING,
} = TYPES;

const initialState = {
  loading: false,
  appSettings: {},
  appStatics: {},
  activeTab: sessionStorage.getItem("activeTab")
    ? sessionStorage.getItem("activeTab")
    : NAVIGATOR.NOTIFICATIONS,
  error: false,
};

export default function applicationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        appSettings: action.payload,
      };
    case GET_APPLICATION_ERROR:
      return {
        ...state,
        loading: false,
        appSettings: action?.payload,
        error: true,
      };
    case APP_STATICS:
      return {
        ...state,
        appStatics: action.payload,
      };
    case GET_ONLINE_USER:
      state.appStatics.markers = action.payload;
      return {
        ...state,
        appStatics: state.appStatics,
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action?.payload,
      };
    case UPDATE_APP_LOGO:
      state.appSettings.logo = action.payload.logo;
      return {
        ...state,
        appSettings: state.appSettings,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        appSettings: {},
        appStatics: {},
        activeTab: NAVIGATOR.WEB_CHATS,
        error: false,
      };
    default:
      return state;
  }
}
