import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";

const ImageSelector = ({ setDocument, setFileType, selectedFileTypes }) => {
  const getFileTypes = () => {
    let filesObj = {};

    for (let index = 0; index < selectedFileTypes?.length; index++) {
      filesObj[`image/${selectedFileTypes[index]}`] = [
        `.${selectedFileTypes[index]}`,
      ];
    }

    return filesObj;
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: getFileTypes(),
  });

  useEffect(() => {
    if (acceptedFiles?.length > 0) setDocument(acceptedFiles);
  }, [acceptedFiles]);
  return (
    <React.Fragment>
      <div className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} hidden />
        </div>
      </div>
      {selectedFileTypes?.length ? (
        <li onClick={() => setFileType("files")}>
          <button
            className="dropdown-item d-flex align-items-center"
            onClick={open}
          >
            Upload
            <div className="icon ms-auto">
              <i className="fal fa-paperclip"></i>
            </div>
          </button>
        </li>
      ) : null}
    </React.Fragment>
  );
};
export default ImageSelector;
