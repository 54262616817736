import React, { useEffect, useRef } from "react";

const VideoFrame = ({ peer, userVideoAudio, peerConnections }) => {
  const ref = useRef();
  useEffect(() => {
    peer.on("stream", (stream) => {
      ref.current.srcObject = stream;
    });
    peer.on("track", (track, stream) => {});
  }, [peer]);

  return (
    <React.Fragment>
      <div
        className={`${
          peerConnections?.length === 0
            ? "col-12"
            : peerConnections?.length === 3
            ? "col-12 col-sm-6"
            : peerConnections?.length >= 6
            ? "col-12 col-sm-3"
            : "col-12 col-sm-4"
        } card p-4`}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
          }}
          className="h-100 d-grid justify-content-center align-items-center"
        >
          <video
            autoPlay
            ref={ref}
            playsInline
            className={`img-fluid rounded mx-auto ${
              userVideoAudio?.hasOwnProperty(peer?.userName) &&
              userVideoAudio[peer?.userName]?.video
                ? "d_flex"
                : "d_none"
            }`}
          />
          <div
            className={` avatar avatar-xl m-auto ${
              userVideoAudio?.hasOwnProperty(peer?.userName) &&
              userVideoAudio[peer?.userName]?.video
                ? "d_none"
                : "d_flex"
            }`}
          >
            {peer?.picture ? (
              <img
                className={`avatar avatar-xl m-auto ${
                  userVideoAudio?.hasOwnProperty(peer?.userName) &&
                  userVideoAudio[peer?.userName]?.video
                    ? "d_none"
                    : "d_flex"
                }`}
                src={peer?.picture}
                style={{ maxHeight: 400 }}
              />
            ) : (
              <span
                className={`avatar-text ${
                  userVideoAudio?.hasOwnProperty(peer?.userName) &&
                  userVideoAudio[peer?.userName]?.video
                    ? "d_none"
                    : "d_flex"
                }`}
              >
                {peer?.userName?.charAt(0)?.toUpperCase()}
              </span>
            )}
          </div>
        </div>
        <i
          className={`${
            userVideoAudio?.hasOwnProperty(peer?.userName) &&
            userVideoAudio[peer?.userName]?.audio
              ? "fa fa-microphone"
              : "fa fa-microphone-slash"
          }`}
          style={{
            position: "absolute",
            top: "0",
            margin: "5px",
            color: "white",
            backgroundColor: "black",
            width: 25,
            height: 25,
            fontSize: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 20,
          }}
        />
      </div>
      {/* <div className="col-md-4 card card-body d-grid justify-content-center, align-items-center">
        <div
          style={{ position: "relative", minHeight: "300px", width: "100%" }}
          className="d-grid justify-content-center, align-items-center"
        >
          <video
            autoPlay
            ref={ref}
            className={
              !userVideoAudio[peer?.userName]?.screenSharing ? "flipped" : ""
            }
            style={{
              width: "100%",
              backgroundColor: "gray",
              display:
                userVideoAudio?.hasOwnProperty(peer?.userName) &&
                userVideoAudio[peer?.userName]?.video
                  ? "block"
                  : "none",
            }}
          />
          <div
            className="avatar avatar-xl user_profile_pic_div m-auto"
            style={{
              display:
                userVideoAudio?.hasOwnProperty(peer?.userName) &&
                userVideoAudio[peer?.userName]?.video
                  ? "none"
                  : "block",
            }}
          >
            {peer?.picture ? (
              <img
                className="avatar-img round_avatar"
                src={peer?.picture}
                alt="#"
                style={{
                  display:
                    userVideoAudio?.hasOwnProperty(peer?.userName) &&
                    userVideoAudio[peer?.userName]?.video
                      ? "none"
                      : "block",
                }}
              />
            ) : (
              <span
                className="avatar-text"
                style={{
                  display:
                    userVideoAudio?.hasOwnProperty(peer?.userName) &&
                    userVideoAudio[peer?.userName]?.video
                      ? "none"
                      : "flex",
                }}
              >
                {peer?.userName?.charAt(0)?.toUpperCase()}
              </span>
            )}
          </div>
          <i
            className={`${
              userVideoAudio?.hasOwnProperty(peer?.userName) &&
              userVideoAudio[peer?.userName]?.audio
                ? "fa fa-microphone"
                : "fa fa-microphone-slash"
            }`}
            style={{
              position: "absolute",
              top: "0",
              margin: "10px",
              color: "white",
            }}
          />
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default VideoFrame;
