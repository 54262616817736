import React, { memo, useState, useEffect } from "react";
import CardHeader from "../../assets/img/core/card-header.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { addFaq, editFaq } from "../../redux/actions/faqAction";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, ReactQuill, MultipleSelect } from "../../components";
import Modal from "react-bootstrap/Modal";

let REQUIRED_FIELD = `Field is required`;

const FaqModal = ({ setModalShow, modalShow }) => {
  const [quillValue, setQuillValue] = useState("");
  const [addFaqLoading, setAddFaqLoading] = useState(false);
  const [selectedWidgets, setSelectedWidgets] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (quillValue === "<p><br></p>") setQuillValue("");
  }, [quillValue]);

  const { appSettings } = useSelector((state) => state.applicationReducer);
  const { faqObj, allFaqs } = useSelector((state) => state.faqReducer);
  const { allDepartments } = useSelector((state) => state.departmentReducer);
  const { allWidgets } = useSelector((state) => state.widgetReducer);

  let INITIAL_VALUES = {
    title: "",
    content: "",
    widgets: allWidgets && [allWidgets[0]?._id],
    department: allDepartments && allDepartments[0]?._id,
    language: "en",
    is_engage_active: false,
    is_widget: false,
  };

  let selectedObj = "";
  if (faqObj?.purpose === "edit") {
    selectedObj = allFaqs?.find((x) => {
      return x?._id === faqObj?.faqId;
    });
  }

  useEffect(() => {
    if (faqObj?.purpose === "edit") {
      setQuillValue(selectedObj?.content);
    }
  }, [selectedObj, faqObj]);
  useEffect(() => {
    if (faqObj?.purpose !== "edit") {
      setQuillValue("");
    }
  }, [faqObj]);

  let editedValues = {
    title: selectedObj?.title,
    content: selectedObj?.content,
    widgets: selectedObj?.widgets,
    department: selectedObj?.department,
    language: selectedObj?.language,
    is_engage_active: selectedObj?.is_engage_active,
    is_widget: selectedObj?.is_widget,
  };

  useEffect(() => {
    if (faqObj?.purpose === "edit") setSelectedWidgets(selectedObj?.widgets);
  }, [selectedObj]);
  useEffect(() => {
    if (faqObj?.purpose === "addNew") setSelectedWidgets([]);
  }, [faqObj]);

  const validationSchema = yup.object({
    title: yup
      .string(REQUIRED_FIELD)
      .min(3, "Title must be 3 characters long")
      .required(REQUIRED_FIELD),
    content: yup
      .string(REQUIRED_FIELD)
      .min(12, "Content must be 5 characters long")
      .required(REQUIRED_FIELD),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: faqObj?.purpose === "edit" ? editedValues : INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!addFaqLoading) {
        if (selectedWidgets?.length > 0) values.widgets = selectedWidgets;
        setAddFaqLoading(true);
        let res;
        if (faqObj?.purpose === "edit") {
          res = await dispatch(editFaq(selectedObj?._id, values));
          ToastContainer(res);
        } else {
          res = await dispatch(addFaq(values));
          ToastContainer(res);
        }
        if (res?.success) {
          resetForm();
          setModalShow(false);
        }
        setAddFaqLoading(false);
      }
    },
  });
  const handleEditorChange = (html) => {
    if (html === "<p><br></p>") {
      setQuillValue("");
      formik.setFieldValue("content", "");
      return;
    } else {
      setQuillValue(html);
      formik.setFieldValue("content", html);
    }
  };
  const handleEditorBlur = () => {
    formik.setFieldTouched("content", true);
  };
  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          id="modal-bot"
          tabIndex="-1"
          aria-labelledby="modal-bot"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-body py-0">
              <div className="profile modal-gx-n">
                <div className="profile-img text-primary rounded-top-xl">
                  <img src={CardHeader} width="100%" />
                  <div className="position-absolute top-0 start-0 p-5">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setModalShow(false)}
                      className="btn-close btn-close-white btn-close-arrow opacity-100"
                    />
                  </div>
                </div>
                <div className="profile-body">
                  <div className="avatar avatar-lg">
                    <span className="avatar-text bg-primary">
                      <i className="fal fa-question-square"></i>
                    </span>
                  </div>
                  <h4 className="fw-bold mb-1">
                    {faqObj?.purpose === "edit" ? "Edit" : "New"} FAQ Article
                  </h4>
                  {faqObj?.purpose === "addNew" && (
                    <p style={{ fontSize: "16px" }}>
                      Help your clients with this new article.
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-py">
                <div className="card-body">
                  <form autoComplete="off">
                    <div className="row gy-6">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="faq-title"
                            placeholder="Enter title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="faq-title">Enter Title</label>
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.title && formik.errors.title}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <div
                          className={
                            localStorage.getItem(`theme`) === "light"
                              ? "form-floating light"
                              : "form-floating dark"
                          }
                        >
                          <ReactQuill
                            placeholder="FAQ Content"
                            quillValue={quillValue}
                            handleChange={handleEditorChange}
                            handleEditorBlur={handleEditorBlur}
                          />
                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.content && formik.errors.content}
                          </small>
                        </div>
                      </div>
                      <div className="col-12">
                        <MultipleSelect
                          label="Chat Widget"
                          allItems={allWidgets}
                          selectedItems={selectedWidgets}
                          setSelectedItems={setSelectedWidgets}
                        />
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="faq-department"
                            name="department"
                            value={formik.values.department}
                            onChange={formik.handleChange}
                          >
                            {allDepartments?.map((x, index) => (
                              <option key={index} value={x?._id}>
                                {x?.title}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="faq-department">Department</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="faq-language"
                            name="language"
                            value={formik.values.language}
                            onChange={formik.handleChange}
                          >
                            <option value="en" defaultValue>
                              EN
                            </option>
                            <option value="de">DE</option>
                            <option value="es">ES</option>
                          </select>
                          <label htmlFor="faq-language">Language</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-8">
                <div className="d-flex align-items-center mb-4 px-6">
                  <small className="text-muted me-auto">Options</small>
                </div>
                <div className="card border-0">
                  <div className="card-body">
                    <div className="row gx-5">
                      <div className="col-auto">
                        <div className="btn btn-sm btn-icon btn-dark">
                          <i className="fal fa-lock"></i>
                        </div>
                      </div>
                      <div className="col">
                        <h5>Active</h5>
                        <p>Is this faq active?</p>
                      </div>
                      <div className="col-auto align-self-center">
                        <div className="form-check form-switch ps-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="faq-options-1"
                            name="is_engage_active"
                            value={formik.values.is_engage_active}
                            onChange={formik.handleChange}
                            checked={formik.values.is_engage_active}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="faq-options-1"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card border-0">
                  <div className="card-body">
                    <div className="row gx-5">
                      <div className="col-auto">
                        <div className="btn btn-sm btn-icon btn-dark">
                          <i className="fal fa-lock"></i>
                        </div>
                      </div>
                      <div className="col">
                        <h5>Widget</h5>
                        <p>Show this Article on the Chat Widget?</p>
                      </div>
                      <div className="col-auto align-self-center">
                        <div className="form-check form-switch ps-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="faq-options-2"
                            name="is_widget"
                            value={formik.values.is_widget}
                            onChange={formik.handleChange}
                            checked={formik.values.is_widget}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="faq-options-2"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="hr-bold modal-gx-n my-0" />

              <div className="modal-py">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                  data-bs-dismiss="modal"
                  style={{ height: "56px" }}
                >
                  {addFaqLoading ? (
                    <div
                      className="spinner-grow text-light"
                      role="status"
                      style={{
                        backgroundColor: "white",
                        width: "15px",
                        height: "15px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {faqObj?.purpose === "edit" ? "Update" : "Save"}
                      <span className="icon ms-auto">
                        <i className="fal fa-chevron-right"></i>
                      </span>
                    </React.Fragment>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default memo(FaqModal);
