export const images = [
  "jpeg",
  "jpg",
  "png",
  "gif",
  "webp",
  "svg",
  "apng",
  "bmp",
  "ico",
  "avif",
  "tiff",
  "tif",
];

export const getFileTypes = (appFileTypes, purpose) => {
  const files = purpose ? images : appFileTypes;
  const formattedString = files?.map((fileType) => {
    return `.${fileType}`;
  });

  return formattedString;
};
