import { TYPES } from "../actionTypes";
import { io } from "socket.io-client";
import {
  userAuth,
  allUsers,
  updateUserById,
  updateUserPic,
} from "../../services/user-services";
import { axiosClient } from "../../utils";
import Cookies from "js-cookie";
import { BASE_URL } from "../../utils/axios-instance";
///////////////////////////////////////////////////////////////////////
const {
  DELETE_RATING,
  SET_CLIENT_ID,
  USER_AUTH_FAIL,
  CLEAR_AUTH_DATA,
  USER_AUTH_LOADING,
  USER_AUTH_SUCCESS,
  UPDATE_USER_BY_ID,
  GET_ALL_USERS_FAIL,
  UPDATE_USER_PROFILE,
  GET_ALL_USERS_LOADING,
  GET_ALL_USERS_SUCCESS,
  GET_USER_BY_ID_SUCCESS,
  UPDATE_SINGLE_OPERATOR,
  UPDATE_ALL_USERS_ARRAY,
} = TYPES;
///////////////////////////////////////////////////////////////////////
export const UserAuthLoading = () => ({
  type: USER_AUTH_LOADING,
});
export const UserAuthSuccess = (data) => ({
  type: USER_AUTH_SUCCESS,
  payload: data,
});
export const UserAuthFail = (err) => ({
  type: USER_AUTH_FAIL,
  payload: err,
});
/////////////
export const GetAllUsersLoading = () => ({
  type: GET_ALL_USERS_LOADING,
});
export const GetAllUsersSuccess = (data) => ({
  type: GET_ALL_USERS_SUCCESS,
  payload: data,
});
export const GetAllUsersFail = (err) => ({
  type: GET_ALL_USERS_FAIL,
  payload: err,
});
/////////////
export const SetClientIdAction = (data) => ({
  type: SET_CLIENT_ID,
  payload: data,
});
///////////////////////////////////////////////////////////////////////
export const auth = () => async (dispatch) => {
  dispatch(UserAuthLoading());
  return await userAuth()
    .then((res) => {
      dispatch(UserAuthSuccess(res?.data));
      return res?.data;
    })
    .catch((err) => {
      if (
        !err?.response?.data?.success ||
        err?.response?.data?.message === "Token Is Not Valid"
      ) {
        Cookies.remove("accessToken");
        Cookies.remove("isAuthenticated");
        Cookies.remove("data");
      }
      dispatch(UserAuthFail(err?.response?.data));
      return err?.response?.data;
    });
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////
export const getAllUsers = () => async (dispatch) => {
  dispatch(GetAllUsersLoading());
  allUsers()
    .then((res) => {
      dispatch(GetAllUsersSuccess(res?.data));
      return res?.data;
    })
    .catch((err) => {
      dispatch(GetAllUsersFail(err?.response?.data));
      return err?.response?.data;
    });
};
/////////////
export const getUserById = (recipientId) => async (dispatch) => {
  // dispatch({
  //   type: GET_USER_BY_ID_SUCCESS,
  //   payload: recipientId,
  // });
  axiosClient()
    .get(`/user/getUserById/${recipientId}`)
    .then((res) => {
      dispatch({
        type: GET_USER_BY_ID_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
/////////////
export const updateUserProfile = (text, userId) => async (dispatch) => {
  return updateUserById(userId, text)
    .then((res) => {
      dispatch(UpdateProfile(res?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
/////////////
export const updateUserPhoto = (payload) => async (dispatch) => {
  await updateUserPic(payload)
    .then((res) => {
      dispatch(UpdateProfile(res?.data));
    })
    .catch((err) => {});
};
/////////////
export const createClient = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`/user/createClient`, payload)
    .then((res) => {
      dispatch(UpdateAllUsersArray(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
/////////////
export const setClientId = (clientId, purpose) => async (dispatch) => {
  let data = {};
  if (purpose === "addNew") {
    data = {
      purpose: purpose,
    };
  } else if (purpose === "edit") {
    data = {
      clientId: clientId,
      purpose: purpose,
    };
  }
  dispatch(SetClientIdAction(data));
};
export const verifyUserAccount = (userId) => async (dispatch) => {
  return axiosClient()
    .put(`/user/verifyUserAccount`, { userId: userId })
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const deleteRating = (ratingId) => async (dispatch) => {
  return await axiosClient()
    .put(`/rating/deleteRating/${ratingId}`)
    .then((res) => {
      dispatch({
        type: DELETE_RATING,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
//////////////////--OTHER-FUNCTIONS--//////////////////
export const UpdateUserById = (payload) => ({
  type: UPDATE_USER_BY_ID,
  payload: payload,
});
export const UpdateProfile = (payload) => ({
  type: UPDATE_USER_PROFILE,
  payload: payload,
});
export const ClearAuthData = (payload) => ({
  type: CLEAR_AUTH_DATA,
  payload: payload,
});
export const UpdateOperatorData = (payload) => ({
  type: UPDATE_SINGLE_OPERATOR,
  payload: payload,
});
export const UpdateAllUsersArray = (payload) => ({
  type: UPDATE_ALL_USERS_ARRAY,
  payload: payload,
});
