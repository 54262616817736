import React, { useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  NOTIFICATION_TYPES,
  TIME_FORMATES,
  NAVIGATOR,
  USER_ROLES,
} from "../constant";
import { denyChatTransfer } from "../redux/actions/notificationAction";
import { acceptChatTransfer } from "../redux/actions/chatAction";
import { TYPES } from "../redux/actionTypes";
import { ToastContainer as Toaster } from ".";
import { useNavigate } from "react-router-dom";

const Notifications = ({
  item,
  number,
  verifyAccount,
  acceptNotiloading,
  handleNotification,
  handlePrivateMessage,
  navigateWithNotification,
  handleSingleNotification,
  handleAccountVerification,
}) => {
  const [expand, setExpand] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [acceptChatLoading, setAcceptCHatLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { socket } = useSelector((state) => state.socketReducer);
  const { userData } = useSelector((state) => state.userReducer);
  const { appSettings } = useSelector((state) => state.applicationReducer);

  const Loading = () => {
    return (
      <div
        className="spinner-grow text-light"
        role="status"
        style={{
          backgroundColor: "white",
          width: "15px",
          height: "15px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  const handleTransferChat = async (purpose) => {
    switch (purpose) {
      case "deny":
        if (!denyLoading) {
          setDenyLoading(true);
          let res = await dispatch(denyChatTransfer(item?._id));
          Toaster(res);
          setDenyLoading(false);
          if (res?.success) {
            socket.emit("notification", res?.data);
          }
        }
        break;
      case "accept":
        if (!acceptChatLoading) {
          setAcceptCHatLoading(true);
          let res = await dispatch(
            acceptChatTransfer({
              conversationId: item?.conversationId,
              payload: {
                idToPull: item?.notified_by?._id,
                idToPush: item?.notified_to[0]?._id,
                notificationId: item?._id,
              },
            })
          );
          setAcceptCHatLoading(false);
          if (res?.success) {
            // Adding new conversation
            dispatch({
              type: TYPES.CREATE_NEW_CONVERSATION,
              payload: res?.data,
            });

            let recipient = res?.data?.recipients?.find((user) => {
              return (
                user?.user_role === USER_ROLES?.CLIENT ||
                user?.user_role === USER_ROLES?.GUEST
              );
            });

            setTimeout(() => {
              // Setting active tab of navigator and sidebar
              dispatch({
                type: TYPES.SET_ACTIVE_TAB,
                payload: NAVIGATOR.WEB_CHATS,
              });
              // Navigating to the chat screen
              navigate(`/chat-direct/${recipient?._id}`);
            }, 1000);
            // Socket for replacing operator from widget
            socket.emit("replaceOperator", {
              operatorData: userData?.data,
              clientData: recipient,
            });
            // Socket for removing conversation from other user
            socket.emit("requestRemovingChat", {
              conversationId: item?.conversationId,
              receiverId: item?.notified_by?._id,
            });
          }
        }
        break;
    }
  };

  switch (item?.notification_type) {
    case NOTIFICATION_TYPES?.FROM_CLIENT:
      return (
        <React.Fragment>
          <div
            style={{ cursor: item?.is_read ? "pointer" : "" }}
            className="card border-0 mb-5"
            onClick={item?.is_read ? () => navigateWithNotification(item) : ""}
          >
            <div className="card-body">
              <div className="row gx-5">
                <div className="col-auto">
                  <a href="#" className="avatar">
                    {item?.notified_by?.picture ? (
                      <img
                        className="avatar-img round_avatar"
                        src={item?.notified_by?.picture}
                        alt=""
                      />
                    ) : (
                      <span className="avatar-text">
                        {item?.notified_by?.name?.charAt(0).toUpperCase()}
                      </span>
                    )}
                    <div className="badge badge-circle bg-primary border-outline position-absolute bottom-0 end-0">
                      <i className="fal fa-user" />
                    </div>
                  </a>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="me-auto mb-0">
                      <a>{item?.notified_by?.name}</a>
                    </h5>
                    <span className="extra-small text-muted ms-2">
                      {moment(item?.createdAt).format(
                        appSettings?.application_time_format ===
                          TIME_FORMATES[0]?.format
                          ? "LT"
                          : "HH:mm"
                      )}
                    </span>
                  </div>
                  <div className="d-flex">
                    <div className="me-auto">
                      {item?.is_read ? "sent" : "send"} you a chat request
                    </div>
                    <div className="dropdown ms-5">
                      {!item?.is_read && (
                        <a
                          className="icon text-muted"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fal fa-ellipsis-h" />
                        </a>
                      )}
                      <ul className="dropdown-menu">
                        {!item?.is_read && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleSingleNotification(item)}
                            >
                              Mark as read
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!item?.is_read && (
              <div className="card-footer">
                <div className="row gx-4">
                  <div className="col">
                    <button
                      className="btn btn-sm btn-primary w-100"
                      onClick={() => handleNotification(item, number)}
                    >
                      {acceptNotiloading?.index === number &&
                      acceptNotiloading?.is_loading ? (
                        <Loading />
                      ) : (
                        "Accept"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    case NOTIFICATION_TYPES?.ACCOUNT_VERIFICATION:
      return (
        <React.Fragment>
          <div className="card border-0 mb-5">
            <div className="card-body">
              <div className="row gx-5">
                <div className="col-auto">
                  <a href="#" className="avatar">
                    {item?.notified_by?.picture ? (
                      <img
                        className="avatar-img round_avatar"
                        src={item?.notified_by?.picture}
                        alt=""
                      />
                    ) : (
                      <span className="avatar-text">
                        {item?.notified_by?.name?.charAt(0).toUpperCase()}
                      </span>
                    )}
                    <div className="badge badge-circle bg-success border-outline position-absolute bottom-0 end-0">
                      <i className="fal fa-check" />
                    </div>
                  </a>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="me-auto mb-0">{item?.notified_by?.name}</h5>
                    <span className="extra-small text-muted ms-2">
                      {moment(item?.createdAt).format(
                        appSettings?.application_time_format ===
                          TIME_FORMATES[0]?.format
                          ? "LT"
                          : "HH:mm"
                      )}
                    </span>
                  </div>
                  <div className="d-flex">
                    <div className="me-auto">{item?.message?.text}</div>
                  </div>
                </div>
              </div>
            </div>
            {!item?.is_read && (
              <div className="card-footer">
                <div className="row gx-4">
                  <div className="col">
                    <a
                      href="#"
                      className="btn btn-sm btn-soft-primary w-100"
                      onClick={() => handleSingleNotification(item)}
                    >
                      Hide
                    </a>
                  </div>
                  <div className="col">
                    <a
                      href="#"
                      className="btn btn-sm btn-primary w-100"
                      onClick={() => handleAccountVerification(item)}
                    >
                      {verifyAccount ? <Loading /> : "Verify"}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    case NOTIFICATION_TYPES?.TRANSFER_CHAT_NOTIFICATION:
      return (
        <React.Fragment>
          <div className="card border-0 mb-5">
            <div className="card-body">
              <div className="row gx-5">
                <div className="col-auto">
                  <a href="#" className="avatar">
                    {item?.notified_by?.picture ? (
                      <img
                        className="avatar-img round_avatar"
                        src={item?.notified_by?.picture}
                        alt=""
                      />
                    ) : (
                      <span className="avatar-text">
                        {item?.notified_by?.name?.charAt(0).toUpperCase()}
                      </span>
                    )}
                    <div className="badge badge-circle bg-primary border-outline position-absolute bottom-0 end-0">
                      <i
                        className="fal fa-comment"
                        style={{ fontWeight: "bolder" }}
                      />
                    </div>
                  </a>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="me-auto mb-0">{item?.notified_by?.name}</h5>
                    <span className="extra-small text-muted ms-2">
                      {moment(item?.createdAt).format(
                        appSettings?.application_time_format ===
                          TIME_FORMATES[0]?.format
                          ? "LT"
                          : "HH:mm"
                      )}
                    </span>
                  </div>
                  <div className="d-flex">
                    <div className="me-auto">
                      <p
                        style={{
                          maxHeight: expand ? "100%" : "20px",
                          transition: "all 0.5s ease",
                          overflow: "hidden",
                        }}
                      >
                        {item?.invitation_message}
                      </p>{" "}
                      {item?.invitation_message?.length > 37 && (
                        <span
                          className="read_more_btn"
                          onClick={() => setExpand(!expand)}
                        >
                          {expand ? "Read Less" : "Read More"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!item?.is_read && (
              <div className="card-footer">
                <div className="row gx-4">
                  <div className="col">
                    <button
                      className="btn btn-sm btn-soft-primary w-100"
                      onClick={() => handleTransferChat("deny")}
                    >
                      {denyLoading ? <Loading /> : "Deny"}
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-sm btn-primary w-100"
                      onClick={() => handleTransferChat("accept")}
                    >
                      {acceptChatLoading ? <Loading /> : "Accept"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    case NOTIFICATION_TYPES?.DENY_CHAT_TRANSFER_REQUEST:
      return (
        <React.Fragment>
          <div className="card border-0 mb-5">
            <div className="card-body">
              <div className="row gx-5">
                <div className="col-auto">
                  <a href="#" className="avatar">
                    {item?.notified_by?.picture ? (
                      <img
                        className="avatar-img round_avatar"
                        src={item?.notified_by?.picture}
                        alt=""
                      />
                    ) : (
                      <span className="avatar-text">
                        {item?.notified_by?.name?.charAt(0).toUpperCase()}
                      </span>
                    )}
                    <div className="badge badge-circle bg-danger border-outline position-absolute bottom-0 end-0">
                      <i
                        className="fal fa-times "
                        style={{ fontWeight: "bolder" }}
                      />
                    </div>
                  </a>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="me-auto mb-0">{item?.notified_by?.name}</h5>
                    <span className="extra-small text-muted ms-2">
                      {moment(item?.createdAt).format(
                        appSettings?.application_time_format ===
                          TIME_FORMATES[0]?.format
                          ? "LT"
                          : "HH:mm"
                      )}
                    </span>
                  </div>
                  <div className="d-flex">
                    <div className="me-auto">
                      <small
                        style={{
                          overflow: "hidden",
                          fontStyle: "italic",
                          transition: "all 0.5s ease",
                          maxHeight: expand ? "100%" : "20px",
                        }}
                      >
                        Chat could not be taken
                      </small>
                    </div>
                    {item?.is_read ? (
                      <i className="fal fa-envelope-open" />
                    ) : (
                      <a title="Mark as read">
                        <i
                          className="fal fa-envelope"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleSingleNotification(item)}
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    case NOTIFICATION_TYPES?.PRIVATE_MESSAGE:
      return (
        <div
          className="card border-0 mb-5"
          onClick={() => handlePrivateMessage(item)}
          style={{ cursor: "pointer" }}
        >
          <div className="card-body">
            <div className="row gx-5">
              <div className="col-auto">
                <a href="#" className="avatar">
                  {item?.notified_by?.picture ? (
                    <img
                      className="avatar-img round_avatar"
                      src={item?.notified_by?.picture}
                      alt=""
                    />
                  ) : (
                    <span className="avatar-text">
                      {item?.notified_by?.name?.charAt(0).toUpperCase()}
                    </span>
                  )}
                  <div className="badge badge-circle bg-primary border-outline position-absolute bottom-0 end-0">
                    <i className="fal fa-comment " />
                  </div>
                </a>
              </div>
              <div className="col">
                <div className="d-flex align-items-center mb-2">
                  <h5 className="me-auto mb-0">{item?.notified_by?.name}</h5>
                  <span className="extra-small text-muted ms-2">
                    {moment(item?.updatedAt).format(
                      appSettings?.application_time_format ===
                        TIME_FORMATES[0]?.format
                        ? "LT"
                        : "HH:mm"
                    )}
                  </span>
                </div>
                <div className="d-flex">
                  <div className="me-auto">Send you a private message</div>
                  {item?.is_read ? (
                    <i className="fal fa-envelope-open" />
                  ) : (
                    <a title="Mark as read">
                      <i
                        className="fal fa-envelope"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSingleNotification(item);
                        }}
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case NOTIFICATION_TYPES?.BLOCKED_GUEST_OR_CLIENT:
      return (
        <React.Fragment>
          <div className="card border-0 mb-5">
            <div className="card-body">
              <div className="row gx-5">
                <div className="col-auto">
                  <a href="#" className="avatar">
                    {item?.notified_by?.picture ? (
                      <img
                        className="avatar-img round_avatar"
                        src={item?.notified_by?.picture}
                        alt=""
                      />
                    ) : (
                      <span className="avatar-text">
                        {item?.notified_by?.name?.charAt(0).toUpperCase()}
                      </span>
                    )}
                    <div className="badge badge-circle bg-danger border-outline position-absolute bottom-0 end-0">
                      <i className="fal fa-ban " />
                    </div>
                  </a>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="me-auto mb-0">{item?.notified_by?.name}</h5>
                    <span className="extra-small text-muted ms-2">
                      {moment(item?.updatedAt).format(
                        appSettings?.application_time_format ===
                          TIME_FORMATES[0]?.format
                          ? "LT"
                          : "HH:mm"
                      )}
                    </span>
                  </div>
                  <div className="d-flex">
                    <div className="me-auto">Blocked a guest / client</div>
                    {item?.is_read ? (
                      <i className="fal fa-envelope-open" />
                    ) : (
                      <a title="Mark as read">
                        <i
                          className="fal fa-envelope"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSingleNotification(item);
                          }}
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    default:
      return;
  }
};
export default Notifications;
