import { TYPES } from '../actionTypes';
import {axiosClient} from '../../utils';
///////////////////////////////////////////////////////////////////////
const {
  GET_ALL_ENGAGE_ENTRY_LOADING,
  GET_ALL_ENGAGE_ENTRY_SUCCESS,
  GET_ALL_ENGAGE_ENTRY_FAIL,
  UPDATE_ENGAGE_ENTRY,
  UPDATE_ENGAGE_ENTRY_DETAILS,
  UPDATE_DELETED_ENTRY_DETAILS,
  SET_ENGAGE_ID,
} = TYPES;
///////////////////////////////////////////////////////////////////////
export const GetAllEngageEntryLoading = () => ({
  type: GET_ALL_ENGAGE_ENTRY_LOADING,
});

export const GetAllEngageEntrySuccess = (data) => ({
  type: GET_ALL_ENGAGE_ENTRY_SUCCESS,
  payload: data,
});

export const GetAllEngageEntryFail = (err) => ({
  type: GET_ALL_ENGAGE_ENTRY_FAIL,
  payload: err,
});
///////////////////////////////////////////////////////////////////////
export const SetEngageIdAction = (data) => ({
  type: SET_ENGAGE_ID,
  payload: data,
});
//////////////////////////
export const getAllEngageEntry = () => async (dispatch) => {
  dispatch(GetAllEngageEntryLoading());
  return axiosClient()
    .get(`/engageEntry/allEngageEntry`)
    .then((res) => {
      dispatch(GetAllEngageEntrySuccess(res?.data.data));
      return res?.data;
    })
    .catch((err) => {
      dispatch(GetAllEngageEntryFail(err?.response?.data?.data));
      return err?.response?.data;
    });
};
////////////////
export const addNewEngageEntry = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`engageEntry/createEngageEntry`, payload)
    .then((res) => {
      dispatch(UpdateEngageEntry(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
///////////////////////
export const deleteEngageEntry = (EngageEntryId) => async (dispatch) => {
  return axiosClient()
    .delete(`/engageEntry/deleteEngageEntry/${EngageEntryId}`)
    .then((res) => {
      dispatch(updateDeletedEngageEntry(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////
export const setEngageId = (engageId, purpose) => async (dispatch) => {
  let data = {};
  if (purpose === 'addNew') {
    data = {
      purpose: purpose,
    };
  } else if (purpose === 'edit') {
    data = {
      engageId: engageId,
      purpose: purpose,
    };
  }
  dispatch(SetEngageIdAction(data));
};
////////////////////////
export const editEngageEntry = (EngageEntryId, payload) => async (dispatch) => {
  return axiosClient()
    .put(`/engageEntry/editEngageEntry/${EngageEntryId}`, payload)
    .then((res) => {
      dispatch(UpdateEngageEntryData(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////
export const UpdateEngageEntry = (data) => ({
  type: UPDATE_ENGAGE_ENTRY,
  payload: data,
});
export const updateDeletedEngageEntry = (data) => ({
  type: UPDATE_DELETED_ENTRY_DETAILS,
  payload: data,
});
export const UpdateEngageEntryData = (data) => ({
  type: UPDATE_ENGAGE_ENTRY_DETAILS,
  payload: data,
});
