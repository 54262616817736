import React, { useState } from "react";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { TIME_FORMATES } from "../constant";
import { TYPES } from "../redux/actionTypes";
import { MessageDropDown } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

const UploadFile = ({
  msg,
  number,
  review,
  member1,
  handleMessage,
  messagesArray,
  handleAutoScroll,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.userReducer);
  const { appSettings } = useSelector((state) => state.applicationReducer);

  const DownloadFile = (link) => {
    fetch(link)
      .then((res) => res.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = link.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName?.replaceAll("%20", " "));
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  const handleImagePreview = (imgObj) => {
    let imageData = {
      imgUrl: imgObj?.document[0]?.location,
      sender: imgObj?.senderId?.name,
      date: imgObj?.createdAt,
      _id: imgObj?._id,
    };
    dispatch({
      type: TYPES.IMAGE_PREVIEW,
      payload: {
        imageData: imageData,
        purpose: "conversation",
      },
    });
  };

  const handleImageLoading = () => {
    setImageLoaded(true);
    handleAutoScroll();
  };
  return (
    <React.Fragment>
      {msg?.type === "images" ? (
        <React.Fragment>
          <div
            className={`message mt-5 ${
              review
                ? msg?.senderId?._id === member1?._id && "message-out"
                : msg?.senderId?._id === userData?.data?._id && "message-out"
            }`}
          >
            {msg?.senderId?._id !== messagesArray[number + 1]?.senderId?._id ? (
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target={
                  !review && msg?.senderId?._id === userData?.data?._id
                    ? "#modal-profile"
                    : "#modal-user-profile"
                }
                className="avatar avatar-responsive"
              >
                {msg?.senderId?.picture ? (
                  <img
                    className="avatar-img round_avatar"
                    src={msg?.senderId?.picture}
                    alt=""
                  />
                ) : (
                  <span className="avatar-text">
                    {msg?.senderId?.name?.charAt(0)?.toUpperCase()}
                  </span>
                )}
              </a>
            ) : (
              <span className="avatar avatar-responsive" />
            )}

            <div className="message-inner">
              <div className="message-body">
                <div className="message-content">
                  <div className="message-gallery">
                    <div className="row gx-3">
                      {msg?.document?.map((y, index) => (
                        <div className="col" key={index}>
                          <LazyLoadImage
                            src={y?.location}
                            alt="Img"
                            className="img-fluid rounded"
                            effect="blur"
                            delayTime={5000}
                            onLoad={handleImageLoading}
                            onClick={() => handleImagePreview(msg)}
                            data-bs-toggle="modal"
                            data-bs-target="#modal-media-preview"
                          />
                          {!imageLoaded && (
                            <Spinner animation="border" size="sm" />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  {!review && (
                    <div className="message-action">
                      <MessageDropDown
                        senderId={msg?.senderId?._id}
                        handleMessage={handleMessage}
                        messageId={msg?._id}
                        messageObj={msg}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="message-footer">
                <span className="extra-small text-muted">
                  {moment(msg?.createdAt).format(
                    appSettings?.application_time_format ===
                      TIME_FORMATES[0]?.format
                      ? "LT"
                      : "HH:mm"
                  )}
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            className={`message mt-5 ${
              review
                ? msg?.senderId?._id === member1?._id && "message-out"
                : msg?.senderId?._id === userData?.data?._id && "message-out"
            }`}
          >
            {msg?.senderId?._id !== messagesArray[number + 1]?.senderId?._id ? (
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target={
                  !review && msg?.senderId?._id === userData?.data?._id
                    ? "#modal-profile"
                    : "#modal-user-profile"
                }
                className="avatar avatar-responsive"
              >
                {msg?.senderId?.picture ? (
                  <img
                    className="avatar-img round_avatar"
                    src={msg?.senderId?.picture}
                    alt=""
                  />
                ) : (
                  <span className="avatar-text">
                    {msg?.senderId?.name?.charAt(0)?.toUpperCase()}
                  </span>
                )}
              </a>
            ) : (
              <span className="avatar avatar-responsive" />
            )}

            <div className="message-inner">
              <div className="message-body">
                <div className="message-content">
                  <div className="message-text">
                    {msg?.document?.map((x, index) => (
                      <div className="row align-items-center gx-4" key={index}>
                        <div className="col-auto">
                          <button
                            onClick={() => DownloadFile(x?.location)}
                            className="avatar avatar-sm"
                            style={{ border: "none" }}
                          >
                            <div className="avatar-text bg-white text-primary">
                              <i className="fal fa-arrow-down" />
                            </div>
                          </button>
                        </div>
                        <div className="col overflow-hidden">
                          <h6 className="text-truncate text-reset">
                            <a href="#" className="text-reset">
                              {x?.originalname}
                            </a>
                          </h6>
                          <ul className="list-inline text-uppercase extra-small opacity-75 mb-0">
                            <li className="list-inline-item">
                              {(x?.size / (1024 * 1024))?.toFixed(1)} MB
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                    <span className="extra-small message-footer">
                      {moment(msg?.createdAt).format(
                        appSettings?.application_time_format ===
                          TIME_FORMATES[0]?.format
                          ? "LT"
                          : "HH:mm"
                      )}
                    </span>
                  </div>
                  {!review && (
                    <div className="message-action">
                      <MessageDropDown
                        senderId={msg?.senderId?._id}
                        handleMessage={handleMessage}
                        messageId={msg?._id}
                        messageObj={msg}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default UploadFile;
