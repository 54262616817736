import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CardHeader from "../../assets/img/core/card-header.png";
import { ToastContainer as Toaster } from "../toast";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import {
  newConversation,
  updateConversationById,
} from "../../redux/actions/chatAction";
import { ToastContainer as Toast } from "..";

const UserProfileModal = () => {
  const [userById, setUserById] = useState({});

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { socket } = useSelector((state) => state.socketReducer);
  const { allConversations } = useSelector((state) => state.chatReducer);
  const { userData } = useSelector((state) => state.userReducer);

  let currentConversation = allConversations?.find((conversation) => {
    return conversation?.recipients?.find((user) => {
      return user?._id === userById?._id;
    });
  });

  useEffect(() => {
    setUserById(
      allConversations
        ?.find((x) => {
          return x?.recipients?.find((y) => {
            return y?._id === id;
          });
        })
        ?.recipients?.find((z) => {
          return z?._id !== userData?.data?._id;
        })
    );
  }, [id, allConversations, userData]);

  const getRating = (ratings) => {
    let totalRateSum = 0;
    let numOfRatings = ratings?.length;

    for (let index = 0; index < ratings?.length; index++) {
      totalRateSum += ratings[index]?.ratings;
    }

    let averageRating = totalRateSum / numOfRatings;

    return averageRating;
  };

  const handleConversation = async (item) => {
    if (
      allConversations?.find((x) => {
        return x?.recipients?.find((y) => {
          return y?._id === item?._id;
        });
      })
    ) {
      navigate(`/chat-direct/${item?._id}`);
    } else {
      let res = await dispatch(
        newConversation({
          receiverId: [item?._id],
        })
      );
      Toast(res);
      if (res?.success) {
        setTimeout(() => {
          socket.emit("addNewConversation", {
            newConversation: res?.data,
            to: item?._id,
          });
          navigate(`/chat-direct/${item?._id}`);
        }, 1000);
      }
    }
  };

  const handleConversationSound = async (isMuted) => {
    let res = {};
    let mutedUsers = {
      is_mute: currentConversation?.is_mute,
    };
    if (currentConversation?.is_mute?.includes(userData?.data?._id)) {
      let filterUser = currentConversation?.is_mute?.filter((user) => {
        return user !== userData?.data?._id;
      });
      mutedUsers = {
        is_mute: filterUser,
      };
    } else {
      mutedUsers = {
        is_mute: [...currentConversation?.is_mute, userData?.data?._id],
      };
    }

    res = await dispatch(
      updateConversationById(currentConversation?._id, mutedUsers)
    );
    Toaster(res);
  };

  return (
    <div
      className="modal fade"
      id="modal-user-profile"
      tabIndex="-1"
      aria-labelledby="modal-user-profile"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-fullscreen-xl-down">
        <div className="modal-content">
          <div className="modal-body py-0">
            <div className="profile modal-gx-n">
              <div className="profile-img text-primary rounded-top-xl">
                <img src={CardHeader} width="100%" />

                <div className="position-absolute top-0 start-0 p-5">
                  <button
                    type="button"
                    className="btn-close btn-close-white btn-close-arrow opacity-100"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>

              <div className="profile-body">
                <div
                  className={`avatar avatar-xl ${
                    userById?.is_user_online ? "avatar-online" : null
                  }`}
                >
                  {userById?.picture ? (
                    <img
                      className="avatar-img round_avatar"
                      src={userById?.picture}
                      alt="#"
                    />
                  ) : (
                    <span className="avatar-text">
                      {userById?.name?.charAt(0).toUpperCase()}
                    </span>
                  )}
                </div>

                <h4 className="mb-1">{userById?.name}</h4>
                {userById?.is_user_online ? <p>Online</p> : null}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {userById?.ratings ? (
                    <ReactStars
                      count={5}
                      isHalf={true}
                      size={15}
                      value={getRating(userById?.ratings)}
                      edit={false}
                      activeColor="#a7a6a8"
                      emptyIcon={<i className="fal fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      filledIcon={<i className="fa fa-star"></i>}
                    />
                  ) : null}
                </div>
              </div>
            </div>

            <hr className="hr-bold modal-gx-n my-0" />

            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>Location</h5>
                    <p>USA, Houston</p>
                  </div>

                  <div className="col-auto">
                    <div className="btn btn-sm btn-icon btn-dark">
                      <i className="fal fa-globe"></i>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>E-mail</h5>
                    <p>{userById?.email}</p>
                  </div>

                  <div className="col-auto">
                    <div className="btn btn-sm btn-icon btn-dark">
                      <i className="fal fa-envelope"></i>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>Phone</h5>
                    <p>{userById?.phone}</p>
                  </div>

                  <div className="col-auto">
                    <div className="btn btn-sm btn-icon btn-dark">
                      <i className="fal fa-headset"></i>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <hr className="hr-bold modal-gx-n my-0" />

            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>Notifications</h5>
                    <p>
                      {currentConversation?.is_mute?.includes(
                        userData?.data?._id
                      )
                        ? "Unmute"
                        : "Mute"}{" "}
                      sound notifications
                    </p>
                  </div>

                  <div className="col-auto">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="user-notification-check"
                        onClick={(e) =>
                          handleConversationSound(e.target.checked)
                        }
                        checked={currentConversation?.is_mute?.includes(
                          userData?.data?._id
                        )}
                        defaultChecked={currentConversation?.is_mute?.includes(
                          userData?.data?._id
                        )}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="user-notification-check"
                      ></label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <hr className="hr-bold modal-gx-n my-0" />

            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <a
                  onClick={() => handleConversation(userById)}
                  className="text-reset"
                >
                  Send Message
                </a>
              </li>

              <li className="list-group-item">
                <a
                  href="#"
                  className="text-danger"
                  onClick={() =>
                    Toaster({
                      success: false,
                      message: "Under Construction",
                    })
                  }
                >
                  Block User
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(UserProfileModal);
