/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { deleteBotById, editBot } from "../redux/actions/botAction";
import { useDispatch } from "react-redux";
import { setBotId } from "../redux/actions/botAction";
import { ToastContainer } from "./toast";
import { handleModal } from "../redux/actions/corfirmationModalAction";

const Bot = ({ item, setModalShow }) => {
  const dispatch = useDispatch();

  const setBot = (botId) => {
    dispatch(setBotId(botId, "edit"));
    setModalShow(true);
  };
  const handleBotStatus = async (botId, status) => {
    let res = await dispatch(editBot(botId, status));
    ToastContainer(res);
  };
  const handleDeleteBot = async (botId) => {
    let res = await dispatch(deleteBotById(botId));
    ToastContainer(res);
  };
  const confirmationModal = (botId) => {
    dispatch(
      handleModal({
        is_show: true,
        function: handleDeleteBot,
        param: botId,
      })
    );
  };
  return (
    <div className="card border-0">
      <div className="card-body">
        <div className="row align-items-center gx-5">
          <div className="col-auto">
            <a href="#" className="avatar @@active">
              <span className="avatar-text">
                {item?.title?.charAt(0).toUpperCase()?.toUpperCase()}
              </span>
            </a>
          </div>

          <div className="col">
            <h5
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            >
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modal-bot"
                onClick={() => setBot(item?._id)}
              >
                {item?.title}
              </a>
            </h5>
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            >
              Department: {item?.department?.title}
            </p>
          </div>

          <div className="col-auto">
            <div className="dropdown">
              {!item?.is_engage_active && (
                <small style={{ color: "red", marginRight: "10px" }}>
                  <i className="fal fa-ban" />
                </small>
              )}
              <a
                className="icon text-muted"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => setBot(item?._id)}
                    // data-bs-toggle="modal"
                    // data-bs-target="#modal-bot"
                  >
                    Edit Bot Message
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleBotStatus(item?._id, {
                        is_engage_active: !item?.is_engage_active,
                      })
                    }
                  >
                    {item?.is_engage_active ? `Pause` : `Active`}
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a
                    className="dropdown-item text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => confirmationModal(item?._id)}
                  >
                    Delete Bot Message
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Bot);
