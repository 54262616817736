/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { USER_ROLES } from "../constant";
import { ToastContainer } from "./toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setClientId } from "../redux/actions/userAction";
import { blockClient } from "../redux/actions/clientAction";
import { handleModal } from "../redux/actions/corfirmationModalAction";

const Clients = ({ item, setModalShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { socket } = useSelector((state) => state.socketReducer);
  const { userData } = useSelector((state) => state.userReducer);

  const handleNewClient = (clientId) => {
    dispatch(setClientId(clientId, "edit"));
    setModalShow(true);
  };
  let enableEditOption = false;
  if (
    userData?.data?.user_role === USER_ROLES.SUPER_OPERATOR ||
    userData?.data?.user_role === USER_ROLES.OPERATOR
  )
    enableEditOption = true;
  // updateClientProfile({ is_block: payload?.status }, payload?.clientId)

  const handleUserStatus = async (payload) => {
    let res = await dispatch(
      blockClient(payload?.clientId, { is_block: payload?.status })
    );
    if (res?.data?.notification) {
      socket.emit("notification", res?.data?.notification);
    }
    ToastContainer(res);
  };
  const confirmationModal = (status, clientId) => {
    dispatch(
      handleModal({
        is_show: true,
        function: handleUserStatus,
        param: {
          status: status,
          clientId: clientId,
        },
      })
    );
  };
  return (
    <div className="card border-0">
      <div className="card-body">
        <div className="row align-items-center gx-5">
          <div className="col-auto">
            <a
              href="#"
              className={`avatar ${item?.is_user_online && "avatar-online"}`}
            >
              {item?.picture ? (
                <img
                  className="avatar-img round_avatar"
                  src={item?.picture}
                  alt="item"
                />
              ) : (
                <span className="avatar-text">
                  {item?.name?.charAt(0)?.toUpperCase()}
                </span>
              )}
            </a>
          </div>
          <div className="col">
            <h5>
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modal-invite"
                onClick={() => handleNewClient(item?._id)}
              >
                {item?.name}{" "}
              </a>
            </h5>
            <p>{item?.is_user_online && "Online"}</p>
          </div>
          <div className="col-auto">
            <div className="dropdown">
              {(item?.is_block || !item?.is_account_active) && (
                <small style={{ color: "red", marginRight: "10px" }}>
                  <i className="fal fa-ban" />
                </small>
              )}
              <a
                className="icon text-muted"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu">
                {/* <li onClick={() => navigate(`/chat-direct/${item?._id}`)}>
                  <a className="dropdown-item" style={{ cursor: "pointer" }}>
                    New message
                  </a>
                </li> */}
                {enableEditOption ? (
                  <React.Fragment>
                    <li>
                      <a
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleNewClient(item?._id)}
                        data-bs-toggle="modal"
                        data-bs-target="#modal-invite"
                      >
                        Edit contact
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          confirmationModal(!item?.is_block, item?._id)
                        }
                      >
                        {item?.is_block ? "Unblock user" : "Block user"}
                      </a>
                    </li>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Clients);
