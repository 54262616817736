import { TYPES } from "../actionTypes";

const {
  USER_LOGOUT,
  DELETE_OPERATOR,
  SET_OPERATOR_ID,
  UPDATE_ONLINE_STATUS,
  UPDATE_ALL_OPERATORS,
  GET_ALL_OPERATORS_FAIL,
  UPDATE_SINGLE_OPERATOR,
  GET_ALL_OPERATORS_SUCCESS,
  GET_ALL_OPERATORS_LOADING,
} = TYPES;

const initialState = {
  loading: false,
  allOperators: [],
  operatorObj: {},
  error: false,
};

export default function operatorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_OPERATORS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_OPERATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        allOperators: action.payload,
      };
    case GET_ALL_OPERATORS_FAIL:
      return {
        ...state,
        loading: false,
        // allOperators: action?.payload,
        allOperators: [],
        error: true,
      };
    case UPDATE_ALL_OPERATORS:
      state.allOperators.push(action.payload);
      return {
        ...state,
        loading: false,
        allOperators: state.allOperators,
      };
    case DELETE_OPERATOR:
      let newArray = [];
      newArray = state?.allOperators?.filter((x) => {
        return x?._id !== action.payload?._id;
      });
      return {
        ...state,
        loading: false,
        allOperators: newArray,
      };
    case SET_OPERATOR_ID:
      return {
        ...state,
        operatorObj: action.payload,
      };
    case UPDATE_SINGLE_OPERATOR:
      let foundIndex = state.allOperators?.findIndex((x) => {
        return x?._id === action.payload?._id;
      });
      state.allOperators[foundIndex] = action.payload;
      return {
        ...state,
        loading: false,
        allOperators: state.allOperators,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        allOperators: [],
        operatorObj: {},
        error: false,
      };
    case UPDATE_ONLINE_STATUS:
      let operator = state?.allOperators?.find((operator) => {
        return operator?._id === action.payload?.userId;
      });
      if (!operator) return;
      let opIndex = state.allOperators?.findIndex((operator) => {
        return operator?._id === action.payload?.userId;
      });
      state.allOperators[opIndex].is_user_online = action.payload?.onlineStatus;
      return {
        ...state,
        allOperators: state.allOperators,
      };
    default:
      return state;
  }
}
