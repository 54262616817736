import { TYPES } from "../actionTypes";
import { BASE_URL } from "../../utils/axios-instance";
import io from "socket.io-client";

const { CONNECT_SOCKET_SUCCESS } = TYPES;
// const Socket = io.connect(BASE_URL, {
//   timeout: 10000, // Connection timeout (10 seconds)
//   forceNew: true, // To create a new Manager instance
//   autoConnect: true, // Don't connect immediately on instantiation
//   reconnection: true, // Enable automatic reconnection
//   reconnectionAttempts: 5, // Number of reconnection attempts
//   reconnectionDelay: 2000, // Initial delay between reconnection attempts (2 seconds)
//   randomizationFactor: 0.5, // Randomization factor for reconnection delays (0.5 = 50%)
//   reconnectionDelayMax: 10000, // Maximum delay between reconnection attempts (10 seconds)
//   transports: ["websocket", "polling"], // Transport mechanisms used to establish a communication channel between the client and the server
// });

const Socket = io.connect(BASE_URL);

/////////////
export const ConnectSocketSuccess = (data) => ({
  type: CONNECT_SOCKET_SUCCESS,
  payload: data,
});
/////////////
export const connectSocket = () => async (dispatch) => {
  dispatch(ConnectSocketSuccess(Socket));
};
