import React, { memo } from "react";

const ClientChatBox = ({ data, setGroupMembers, groupMembers }) => {
  const handleGroupMembers = (is_checked, userId) => {
    switch (is_checked) {
      case true:
        setGroupMembers([...groupMembers, userId]);
        break;
      case false:
        setGroupMembers(
          groupMembers?.filter((x) => {
            return x !== userId;
          })
        );
        break;
    }
  };
  return (
    <div className="card border-0 mt-5">
      <div className="card-body">
        <div className="row align-items-center gx-5">
          <div className="col-auto">
            <div
              className={`avatar ${data?.is_user_online && "avatar-online"}`}
            >
              {data?.picture ? (
                <img
                  className="avatar-img round_avatar"
                  src={data?.picture}
                  alt=""
                />
              ) : (
                <span className="avatar-text">
                  {data?.name?.charAt(0)?.toUpperCase()}
                </span>
              )}
            </div>
          </div>
          <div className="col">
            <h5 style={{ textTransform: "capitalize" }}>{data?.name}</h5>
            <p>{data?.is_user_online && "Online"}</p>
          </div>
          <div className="col-auto">
            <div className="form-check">
              <input
                style={{
                  zIndex: 999999,
                  position: "relative",
                  backgroundColor: "#97acca",
                }}
                className="form-check-input"
                type="checkbox"
                value=""
                id={`id-member-${data?._id}`}
                onChange={(e) =>
                  handleGroupMembers(e.target.checked, data?._id)
                }
                checked={groupMembers?.includes(data?._id)}
              />
              <label
                className="form-check-label"
                htmlFor={`id-member-${data?._id}`}
              ></label>
            </div>
          </div>
        </div>
        <label
          className="stretched-label"
          htmlFor={`id-member-${data?._id}`}
        ></label>
      </div>
    </div>
  );
};
export default memo(ClientChatBox);
