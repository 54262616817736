// Importing APIs functions from api.js
import { post, get, put } from '../api';

// Importing all urls from urlUtils.js
import {
  getLoginUrl,
  getSignupUrl,
  getAuthUrl,
  getALlUsersUrl,
  updateUserByIdUrl,
  updateUserPhotoUrl,
} from '../utils';

// Sequence of passing props 1) url || 2) body || 3) params
export const login = (data) => {
  const loginUrl = getLoginUrl();
  return post(loginUrl, data);
};

export const signup = (data) => {
  const signupUrl = getSignupUrl();
  return post(signupUrl, data);
};

export const userAuth = () => {
  const authUrl = getAuthUrl();
  return get(authUrl);
};

export const allUsers = () => {
  const allUsersUrl = getALlUsersUrl();
  return get(allUsersUrl);
};

export const updateUserById = (recipientId, data) => {
  const userByIdUrl = updateUserByIdUrl(recipientId);
  return put(userByIdUrl, data);
};

export const updateUserPic = (data) => {
  const userPhotoUrl = updateUserPhotoUrl();
  return post(userPhotoUrl, data);
};
