import React, { createContext, useReducer } from "react";
import Cookies from "js-cookie";
import { axiosClient } from "../utils";
import { login as userLogin } from "../services/user-services";

const initialAuthState = {
  isAuthenticated: Cookies.get("isAuthenticated")
    ? JSON.parse(Cookies.get("isAuthenticated"))
    : false,
  user: Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null,
};

export const setSession = async (accessToken, user) => {
  const userData = JSON.stringify({ ...user });
  if (accessToken) {
    Cookies.set("accessToken", accessToken, { expires: 1 });
    Cookies.set("isAuthenticated", "true", { expires: 1 });
    Cookies.set("user", JSON.stringify(userData), { expires: 1 });
  } else {
    Cookies.remove("data");
    Cookies.remove("accessToken");
    Cookies.remove("isAuthenticated");
  }
};
const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  twoStepAuth: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  // Login
  const login = async (param, password, session) => {
    const response = await userLogin({
      param: param?.trim(),
      password: password?.trim(),
      sessions: session,
    });
    const { data: user, token } = response.data;
    if (!user || !token) return response.data;
    setSession(token, user);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
    return response.data;
  };

  const twoStepAuth = async (token, user) => {
    setSession(token, user);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
    return {
      token,
      user,
    };
  };

  // Logout
  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  // Register
  const register = async (email, name, password) => {
    const response = await axiosClient.post("/api/account/register", {
      email,
      name,
      password,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken, user);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        twoStepAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
