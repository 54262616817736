import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BackToCall = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleBackToCall = () => {
    if (sessionStorage.getItem("call-type") == "meeting") {
      navigate(`/meeting/${sessionStorage.getItem("meeting-conversationId")}`);
    } else if (sessionStorage.getItem("call-type") == "direct") {
      navigate(`/call/${sessionStorage.getItem("recipientId")}`);
    }
  };
  return (
    <React.Fragment>
      {sessionStorage.getItem("streamCheck") &&
      !pathname?.includes("call") &&
      !pathname?.includes("meeting") ? (
        <div
          className="onHoverScale fade_in"
          style={{
            position: "absolute",
            zIndex: 999999999,
            top: 0,
            right: 0,
            width: 250,
            height: 200,
            backgroundColor:
              localStorage.getItem("theme") === "light" ? "#fff" : "#1E2126",
            boxShadow: "0px 0px 10px -5px black",
            margin: 50,
            borderRadius: 10,
            paddingLeft: 20,
            paddingRight: 20,
            display: "grid",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleBackToCall}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p
              style={{
                textAlign: "center",
                margin: 0,
              }}
            >
              On going call
              <span className="typing-dots">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </p>
            <div className="active-call" />
          </div>
          <div
            style={{
              width: 70,
              height: 70,
              backgroundColor: "#2787F5",
              borderRadius: 100,
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {sessionStorage.getItem("streamCheck") === "v-o-i-c-e" && (
              <i
                className="fal fa-phone"
                style={{ fontSize: 23, color: "white" }}
              />
            )}
            {sessionStorage.getItem("streamCheck") === "s-t-r-e-a-m" && (
              <i
                className="fal fa-video"
                style={{ fontSize: 23, color: "white" }}
              />
            )}
          </div>
          <p
            className="onHoverUnderline"
            style={{
              textAlign: "center",
            }}
          >
            Back to call
          </p>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default BackToCall;
