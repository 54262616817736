import React, { memo, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { ToastContainer as Toaster } from "../../components";
import { signup } from "../../services/user-services";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer as Snackbar } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { USER_ROLES } from "../../constant";

let REQUIRED_FIELD = `Field is required`;

const Signup = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const application_id = queryParams.get("App");

  const validationSchema = yup.object({
    name: yup.string(REQUIRED_FIELD).trim(" ").required(REQUIRED_FIELD),
    email: yup
      .string(REQUIRED_FIELD)
      .email("Enter a valid email")
      .trim(" ")
      .required(REQUIRED_FIELD),
    phone: yup.string(REQUIRED_FIELD).trim(" ").required(REQUIRED_FIELD),
    password: yup
      .string(REQUIRED_FIELD)
      .min(5, "Password must be 5 characters long")
      .trim(" ")
      .required(REQUIRED_FIELD),
    confirm_password: yup
      .string(REQUIRED_FIELD)
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .trim(" ")
      .min(5, "Confirm password must be 5 characters long")
      .required(REQUIRED_FIELD),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      language: "en",
      department: [],
      confirm_password: "",
      is_account_active: false,
      user_role: USER_ROLES?.EDITOR,
      application_id: application_id,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      values?.name?.trim(" ");
      values?.email?.trim(" ");
      values?.phone?.trim(" ");
      values?.password?.trim(" ");
      delete values?.confirm_password;
      if (!signUpLoading) {
        setSignUpLoading(true);
        signup(values)
          .then((res) => {
            setSignUpLoading(false);
            Toaster(res?.data);
            setTimeout(() => {
              Toaster({
                success: true,
                message:
                  "Please contact administrator for account verification",
              });
              setTimeout(() => {
                navigate("/");
              }, 6000);
            }, 1000);
            resetForm();
          })
          .catch((err) => {
            setSignUpLoading(false);
            Toaster(err?.response?.data);
          });
      }
    },
  });
  const handleNavigate = (link) => {
    navigate(link);
  };
  const handlePasswordVisible = (val) => {
    setPasswordVisible(val);
  };
  const handleConfirmPasswordVisible = (val) => {
    setConfirmPasswordVisible(val);
  };
  return (
    <div className="container">
      <div
        className="row align-items-center justify-content-center vh-100 gx-0 hide-scrollbar"
        style={{ overflow: "auto" }}
      >
        <div className="col-12 col-md-5 col-lg-4">
          <div className="card card-shadow border-0">
            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row g-6">
                  <div className="col-12">
                    <div className="text-center">
                      <h3 className="fw-bold mb-2">Sign Up</h3>
                      <p>Follow the easy steps</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control signup_inputs custom-input"
                        id="signup-name"
                        placeholder="Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="signup-name">Name</label>
                      <small style={{ color: "red", marginLeft: "10px" }}>
                        {formik.touched.name && formik.errors.name}
                      </small>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control signup_inputs custom-input"
                        id="signup-email"
                        placeholder="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="signup-email">Email</label>
                      <small style={{ color: "red", marginLeft: "10px" }}>
                        {formik.touched.email && formik.errors.email}
                      </small>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control signup_inputs hide_arrows custom-input"
                        id="signup-number"
                        placeholder="Phone Number"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="signup-number">Phone Number</label>
                      <small style={{ color: "red", marginLeft: "10px" }}>
                        {formik.touched.phone && formik.errors.phone}
                      </small>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="userGroup"
                        name="user_role"
                        value={formik.values.user_role}
                        onChange={formik.handleChange}
                      >
                        <option value={USER_ROLES?.EDITOR} defaultValue>
                          Editor
                        </option>
                        <option value={USER_ROLES?.JUNIOR_OPERATOR}>
                          Junior Operator
                        </option>
                        <option value={USER_ROLES?.OPERATOR}>Operator</option>
                      </select>
                      <label htmlFor="userGroup">User Group</label>
                      <small style={{ color: "red", marginLeft: "10px" }} />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="operator-language"
                        name="language"
                        value={formik.values.language}
                        onChange={formik.handleChange}
                      >
                        <option value="en" defaultValue>
                          EN
                        </option>
                        <option value="de">DE</option>
                        <option value="es">ES</option>
                      </select>
                      <label
                        htmlFor="operator-language"
                        className="form-label text-muted"
                      >
                        Language
                      </label>
                      <small style={{ color: "red", marginLeft: "10px" }} />
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="form-floating"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor:
                          localStorage.getItem("theme") === "light"
                            ? "#Ebf1f7"
                            : "#1E2126",
                        borderRadius: "0.6rem",
                        paddingRight: "10px",
                      }}
                    >
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control signup_inputs custom-input"
                        id="signup-password"
                        placeholder="Password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="signup-password">Password</label>
                      {passwordVisible ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{ cursor: "pointer" }}
                          onClick={() => handlePasswordVisible(false)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          style={{ cursor: "pointer" }}
                          onClick={() => handlePasswordVisible(true)}
                        />
                      )}
                    </div>
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      {formik.touched.password && formik.errors.password}
                    </small>
                  </div>
                  <div className="col-12">
                    <div
                      className="form-floating"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor:
                          localStorage.getItem("theme") === "light"
                            ? "#Ebf1f7"
                            : "#1E2126",
                        borderRadius: "0.6rem",
                        paddingRight: "10px",
                      }}
                    >
                      <input
                        type={confirmPasswordVisible ? "text" : "password"}
                        className="form-control signup_inputs custom-input"
                        id="signup-confirm-password"
                        placeholder="Confirn Password"
                        name="confirm_password"
                        value={formik.values.confirm_password}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="signup-confirm-password">
                        Confirm Password
                      </label>
                      {confirmPasswordVisible ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleConfirmPasswordVisible(false)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleConfirmPasswordVisible(true)}
                        />
                      )}
                    </div>
                    <small style={{ color: "red", marginLeft: "10px" }}>
                      {formik.touched.confirm_password &&
                        formik.errors.confirm_password}
                    </small>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-block btn-lg btn-primary w-100"
                      type="submit"
                    >
                      {signUpLoading ? (
                        <div
                          className="spinner-grow text-light"
                          role="status"
                          style={{
                            backgroundColor: "white",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Create Account"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="text-center mt-8">
            <p>
              Already have an account?{" "}
              <a
                style={{ cursor: "pointer", color: "#2787F5" }}
                onClick={() => handleNavigate("/")}
              >
                Sign in
              </a>
            </p>
          </div>
        </div>
      </div>
      <Snackbar />
    </div>
  );
};
export default memo(Signup);
