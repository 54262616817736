import { useEffect } from "react";
import NProgress from "nprogress";

const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div
      sx={{
        backgroundColor: "#ececec",
        minHeight: "100%",
      }}
    />
  );
};

export default LoadingScreen;
