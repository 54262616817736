import { TYPES } from "../actionTypes";

const {
  USER_LOGOUT,
  ADD_NEW_NOTIFICATION,
  MESSAGE_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
  UPDATE_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_FAIL,
  GET_ALL_NOTIFICATIONS_LOADING,
  GET_ALL_NOTIFICATIONS_SUCCESS,
} = TYPES;

const initialState = {
  loading: false,
  allNotifications: [],
  error: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allNotifications: action.payload,
      };
    case UPDATE_ALL_NOTIFICATIONS:
      let foundIndex = state?.allNotifications?.findIndex((x) => {
        return x?._id === action.payload?.data?._id;
      });
      state.allNotifications[foundIndex].is_read = action.payload.data.is_read;
      return {
        ...state,
        loading: false,
        allNotifications: state.allNotifications,
      };
    case GET_ALL_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        allNotifications: action?.payload,
        error: true,
      };
    case ADD_NEW_NOTIFICATION:
      if (state.allNotifications[0]?._id !== action.payload?._id) {
        state.allNotifications = [action.payload, ...state.allNotifications];
      }
      return {
        ...state,
        allNotifications: state.allNotifications,
      };
    case CLEAR_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: [],
      };
    case MESSAGE_NOTIFICATION:
      let findNotification = state.allNotifications?.find((notification) => {
        return notification?._id === action.payload?._id;
      });
      if (findNotification) {
        let notificationIndex = state.allNotifications?.findIndex(
          (notification) => {
            return notification?._id === action.payload?._id;
          }
        );
        state.allNotifications[notificationIndex] = action.payload;
      } else {
        state.allNotifications = [action.payload, ...state.allNotifications];
      }
      return {
        ...state,
        allNotifications: state.allNotifications,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        allNotifications: [],
        error: false,
      };
    default:
      return state;
  }
}
