import { TYPES } from "../actionTypes";

const { SHOW_MODAL, USER_LOGOUT } = TYPES;

const initialState = {
  modal: {
    is_show: false,
    function: "",
    param: "",
  },
};

export default function corfirmationModalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        modal: {
          is_show: false,
          function: "",
          param: "",
        },
      };
    default:
      return state;
  }
}
