import { TYPES } from "../actionTypes";
import { axiosClient } from "../../utils";

const {
  SET_WIDGET_ID,
  CREATE_WIDGET,
  READ_WIDGET,
  UPDATE_WIDGET,
  DELETE_WIDGET,
} = TYPES;

export const setWidgetId = (widgetId, purpose) => async (dispatch) => {
  let data = {};
  if (purpose === "addNew") {
    data = {
      purpose: purpose,
    };
  } else if (purpose === "edit") {
    data = {
      widgetId: widgetId,
      purpose: purpose,
    };
  }
  dispatch({
    type: SET_WIDGET_ID,
    payload: data,
  });
};
export const createWidget = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`/widget/createWidget`, payload)
    .then((res) => {
      dispatch({
        type: CREATE_WIDGET,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const getAllWidgets = () => async (dispatch) => {
  return axiosClient()
    .get(`/widget/all/widgets`)
    .then((res) => {
      dispatch({
        type: READ_WIDGET,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      dispatch({
        type: READ_WIDGET,
        payload: [],
      });
      return err?.response?.data;
    });
};
export const editWidget = (widgetId, payload) => async (dispatch) => {
  return axiosClient()
    .put(`/widget/editWidget/${widgetId}`, payload)
    .then((res) => {
      dispatch({
        type: UPDATE_WIDGET,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};

export const deleteWidgetById = (widgetId) => async (dispatch) => {
  return axiosClient()
    .delete(`/widget/deleteWidget/${widgetId}`)
    .then((res) => {
      dispatch({
        type: DELETE_WIDGET,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
