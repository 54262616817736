import { TYPES } from "../actionTypes";
import { axiosClient } from "../../utils";
///////////////////////////////////////////////////////////////////////
const {
  GET_ALL_NOTIFICATIONS_LOADING,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_FAIL,
  UPDATE_ALL_NOTIFICATIONS,
  CLEAR_ALL_NOTIFICATIONS,
  ADD_NEW_NOTIFICATION,
} = TYPES;
///////////////////////////////////////////////////////////////////////
export const GetAllNotificationLoading = () => ({
  type: GET_ALL_NOTIFICATIONS_LOADING,
});
export const GetAllNotificationSuccess = (data) => ({
  type: GET_ALL_NOTIFICATIONS_SUCCESS,
  payload: data,
});
export const GetAllNotificationFail = (err) => ({
  type: GET_ALL_NOTIFICATIONS_FAIL,
  payload: err,
});
///////////////////////////////////////////////////////////////////////
export const getAllNotofications = () => async (dispatch) => {
  dispatch(GetAllNotificationLoading());
  return axiosClient()
    .get(`/notification/allNotifications`)
    .then((res) => {
      dispatch(GetAllNotificationSuccess(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      dispatch(GetAllNotificationFail(err?.response?.data?.data));
      return err?.response?.data;
    });
};
/////////////////////////
export const updateNotificationStatus = (_id) => async (dispatch) => {
  return axiosClient()
    .put(`/notification/updateNotificationStatus/${_id}`)
    .then((res) => {
      dispatch(UpdateConversation(res?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const clearAllNotifications = () => async (dispatch) => {
  return axiosClient()
    .put(`/notification/clearAll`)
    .then((res) => {
      dispatch({
        type: CLEAR_ALL_NOTIFICATIONS,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const transferChatNotification = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`/notification/transferChatNotification`, payload)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const denyChatTransfer = (notificationId) => async (dispatch) => {
  return axiosClient()
    .post(`/notification/denyChatTransfer/${notificationId}`)
    .then((res) => {
      dispatch(
        UpdateConversation({
          data: {
            is_read: true,
            _id: notificationId,
          },
        })
      );
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
///////////////////////////////////////////////////////////////////////
export const UpdateConversation = (payload) => ({
  type: UPDATE_ALL_NOTIFICATIONS,
  payload: payload,
});

export const addNewNotification = (payload) => ({
  type: ADD_NEW_NOTIFICATION,
  payload: payload,
});
