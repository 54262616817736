import { initReactI18next } from "react-i18next";
import * as i18n from "i18next";

i18n.use(initReactI18next).init({
  lng: "en",
  resources: {
    en: {
      translation: {
        Settings: "Settings",
        Operators: "Operators",
        FAQ: "FAQ",
        ChatBot: "Chat Bot",
        StandardResponses: "Standard Responses",
        Customers: "Customers",
        GroupChats: "Group Chats",
        OfflineMessages: "Offline Messages",
        WebChats: "Web Chats",
        Notifications: "Notifications",
        SearchMessagesOrUsers: "Search messages or users",
        Profile: "Profile",
        Accounts: "Accounts",
        Rating: "Rating",
        CurrentRating: "Current Rating",
        Stars: "Stars",
        Account: "Account",
        ProfileSettings: "Profile settings",
        ChangeYourProfileSettings: "Change your profile settings",
        ConnectedAccounts: "Connected accounts",
        ConnectWithYourAccounts: "Connect with your accounts",
        Twitter: "Twitter",
        UserRole: "User Role",
        Department: "Department",
        Language: "Language",
        Name: "Name",
        Email: "Email",
        Phone: "Phone",
        Bio: "Bio",
        Loading: "Loading...",
        Save: "Save",
        ConnectWithYourAccounts: "Connect With Your Accounts",
        Facebook: "Facebook",
        Instagram: "Instagram",
        Appearance: "Appearance",
        ChooseLightOrDarkTheme: "Choose light or dark theme",
        Security: "Security",
        Password: "Password",
        ChangeYourPassword: "Change your password",
        CurrentPassword: "Current password",
        NewPassword: "New password",
        VerifyPassword: "Verify password",
        TwoStepVerifications: "Two-step verifications",
        EnableTwoStepVerifications: "Enable two-step verifications",
        Notifications: "Notifications",
        Sound: "Sound",
        EnableSoundNotifications: "Enable sound notifications",
        BrowserNotifications: "Browser notifications",
        EnableBrowserNotifications: "Enable browser notifications",
        PushNotifications: "Push notifications",
        EnablePushNotifications: "Enable push notifications",
        Devices: "Devices",
        Browser: "Browser",
        active: "active",
        "Create New Operator": "Create new operator",
        MainSettings: "Main settings",
        GeneralSettings: "General settings",
        TitleEmailLanguageAndTimezone: "Title, Email, Language and Timezone",
        Title: "Title",
        MainLanguage: "Main Language",
        YourTimezone: "Your Timezone",
        TimeFormat: "Time Format",
        DateFormat: "Date Format",
        EUDSGVOrAgreement: "EU-DSGV or Agreement",
        GeneralSettingsForYourClients: "General Settings For Your Clients",
        Register: "Register",
        CanClientsRegisterFromTheFrontEndForm:
          "Can clients register from the front end form",
        CanClientsRateTheOperators: "Can clients rate the operators",
        Transcript: "Transcript",
        CanClientsRequestTheChatTranscript:
          "Can clients request the chat transcript",
        FileUpload: "File Upload",
        FileUploadActivatedByStandard: "File upload activated by standard",
        TurnOff: "Turn Off",
        TurnOffAllChatWidgetsAndOutsideCommunications:
          "Turn off all chat widgets and outside communications",
        EmailSettings: "Email Settings",
        TheWayYouAreSendingEmails: "The way you are sending emails",
        SMTP: "SMTP",
        SendingEmailsViaSMTP: "Sending emails via SMTP",
        FromEmail: "From Email",
        Host: "Host",
        Prefix: "Prefix",
        Username: "User name",
        Password: "Password",
        KeepSMTPAlive: "Keep SMTP Alive",
        ShouldTheConnectionStayAlive: "Should the connection stay alive",
        SMTPAuthenticatio: "SMTP Authenticatio",
        DoesTheSmtpServerNeedsAuthentication:
          "Does the smtp server needs authentication",
        SendingEmailsViaOAuth2: "Sending emails via oAuth2",
        AuthorizationEndpointURL: "Authorization Endpoint URL",
        TokenEndpointURL: "Token Endpoint URL",
        ClientID: "Client ID",
        ClientSecret: "Client Secret",
        UploadSettings: "Upload Settings",
        DefineFileTypesAndlocation: "Define file types and location",
        FolderPath: "Folder Path",
        AllowedFilesClient: "Allowed Files Client",
        AllowedFilesOperator: "Allowed Files Operator",
        BlockClients: "Block Clients",
        SetEmailAddressesAndIPstoBlock: "Set email addresses and IP's to block",
        BlockEmailsOrDomains: "Block emails or Domains",
        BlockIPsOrSubnets: "Block IP's or Subnets",
        PushNotifications: "Push Notifications",
        SetTheKeyForTheNativePushNotifications:
          "Set the key for the native push notifications",
        PushTokenKey: "Push Token Key",
        PushUserKey: "Push User Key",
        License: "License",
        EnterYourlicenseCode: "Enter your license code",
        LicenseKey: "License Key",
        Departments: "Departments",
        CreateNewDepartment: "Create New Department",
        "No Department Found": "No Department Found",
        AutoEngages: "Auto Engages",
        CreateNewEngageEntry: "Create New Engage Entry",
        "No Engage Entry Found": "No Engage Entry Found",
        Storage: "Storage",
        ClearStorage: "Clear storage",
        Cache: "Cache",
        MaximumCacheSize: "Maximum cache size",
        KeepMedia: "Keep Media",
        PhotosVideosAndOtherFiles: "Photo's videos and other files",
        ExtraPages: "Extra Pages",
        Widget: "Widget",
        LiveChatWidgetStartChatAndProfile:
          "Live chat widget start chat and profile",
        LiveChatWidgetbotfaqandrating: "Live Chat Widget bot/faq and rating",
        LiveChatWidgetVideo: "Live Chat Widget Video",
        SignIn: "SignIn",
        SigninPage: "Sign in Page",
        TwoFactorAuthentificator: "Two Factor Authenticator",
        WhenTurnedOnItWillNeedAkey: "When turned on it will need a key",
        SignUp: "Sign Up",
        SignUpPage: "Sign Up Page",
        PasswordReset: "Password Reset",
        PasswordResetPage: "Password Reset Page",
        LockScreen: "Lock Screen",
        LockScreenPage: "Lock Screen Page",
        SearchSettings: "Search Settings",
        CreateNewArticle: "Create New Article",
        "No FAQ found": "No FAQ found",
        SearchFAQs: "Search FAQs",
        CreateNewBotMessage: "Create New Bot Message",
        "No Bot Found": "No Bot found",
        SearchBotsHere: "Search Bots Here",
        SearchResponse: "Search Response",
        CreateNewResponse: "Create new response",
        "No Response Found": "No response Found",
        SearchCustomers: "Search Customers",
        CreateNewCustomer: "Create New Customer",
        "No Client Found": "No Client Found",
        SearchMessagesOrUsers: "Search messages or users",
        NewGroupChat: "New Group Chat",
        New: "New",
        ClearAll: "Clear all",
        SendYouaPrivateMessage: "Send you a private message",
        NoNotificationsFound: "No notifications found",
        SearchUsersByMail: "Search users by Mail",
        ManageChatWidgets: "Manage Chat Widgets",
        NoConversationCreatedYet: "No conversation created yet",
        Dashboard: "Dashboard",
        CurrentlyOnline: "Currently Online",
        Visitors: "Visitors",
        SinceLastWeek: "Since last week",
        Conversations: "Conversations",
        Clients: "Clients",
        Guests: "Guests",
        TopClients: "Top Clients",
        TopOperators: "Top Operators",
        zippchatAllinoneCommunication: "zipp.chat - All in one Communication",
        Allrightsreserved: "All rights reserved",
      },
    },
    de: {
      translation: {
        Settings: "Einstellungen",
        Operators: "Betreiberinnen",
        FAQ: "FAQ",
        ChatBot: "Chat Bot",
        StandardResponses: "Standardantworten",
        Customers: "Kunden",
        GroupChats: "Gruppenchats",
        OfflineMessages: "Offline-Nachrichten",
        WebChats: "Web-Chats",
        Notifications: "Benachrichtigungen",
        SearchMessagesOrUsers: "Suchen Sie Nachrichten oder Benutzer",
        Profile: "Profil",
        Accounts: "Konten",
        Rating: "Bewertung",
        CurrentRating: "Aktuelle Bewertung",
        Stars: "Sterne",
        Account: "Konto",
        ProfileSettings: "Profileinstellungen",
        ChangeYourProfileSettings: "Ändern Sie Ihre Profileinstellungen",
        ConnectedAccounts: "Verbundene Konten",
        ConnectWithYourAccounts: "Verbinden Sie sich mit Ihren Konten",
        Twitter: "Twitter",
        UserRole: "Benutzer-Rolle",
        Department: "Abteilung",
        Language: "Sprache",
        Name: "Name",
        Email: "Email",
        Phone: "Teléfono",
        Bio: "Bio",
        Loading: "Wird geladen...",
        Save: "Speichern",
        ConnectWithYourAccounts: "Verbinden Sie sich mit Ihren Konten",
        Facebook: "Facebook",
        Instagram: "Instagram",
        Appearance: "Aussehen",
        ChooseLightOrDarkTheme: "Wählen Sie helles oder dunkles Design",
        Security: "Sicherheit",
        Password: "Passwort",
        ChangeYourPassword: "Ändern Sie Ihr Passwort",
        CurrentPassword: "Aktuelles Passwort",
        NewPassword: "Neues Kennwort",
        VerifyPassword: "Passwort bestätigen",
        TwoStepVerifications: "Bestätigungen in zwei Schritten",
        EnableTwoStepVerifications:
          "Aktivieren Sie Zwei-Schritt-Verifizierungen",
        Notifications: "Benachrichtigungen",
        Sound: "Klang",
        EnableSoundNotifications: "Tonbenachrichtigungen aktivieren",
        BrowserNotifications: "Browser-Benachrichtigungen",
        EnableBrowserNotifications: "Aktivieren Sie Browser-Benachrichtigungen",
        PushNotifications: "Mitteilungen",
        EnablePushNotifications: "Aktivieren Sie Push-Benachrichtigungen",
        Devices: "Geräte",
        Browser: "Browser",
        active: "aktiv",
        "Create New Operator": "Neuen Operator erstellen",
        MainSettings: "Haupteinstellungen",
        GeneralSettings: "Allgemeine Einstellungen",
        TitleEmailLanguageAndTimezone: "Titel, E-Mail, Sprache und Zeitzone",
        Title: "Titel",
        MainLanguage: "Muttersprache",
        YourTimezone: "Deine Zeitzone",
        TimeFormat: "Zeitformat",
        DateFormat: "Datumsformat",
        EUDSGVOrAgreement: "EU-DSGV oder Abkommen",
        GeneralSettingsForYourClients:
          "Allgemeine Einstellungen für Ihre Clients",
        Register: "Registrieren",
        CanClientsRegisterFromTheFrontEndForm:
          "Können sich Kunden über das Frontend-Formular registrieren?",
        CanClientsRateTheOperators: "Können Kunden die Betreiber bewerten",
        Transcript: "Abschrift",
        CanClientsRequestTheChatTranscript:
          "Können Kunden das Chat-Transkript anfordern?",
        FileUpload: "Datei-Upload",
        FileUploadActivatedByStandard: " Datei-Upload standardmäßig aktiviert",
        TurnOff: "Ausschalten",
        TurnOffAllChatWidgetsAndOutsideCommunications:
          "Schalten Sie alle Chat-Widgets und externe Kommunikation aus",
        EmailSettings: "Email Einstellungen",
        TheWayYouAreSendingEmails:
          "Die Art und Weise, wie Sie E-Mails versenden",
        SMTP: "SMTP",
        SendingEmailsViaSMTP: "Versenden von E-Mails über SMTP",
        FromEmail: "Desde el e-mail",
        Host: "Gastgeber",
        Prefix: "Präfix",
        Username: "Nutzername",
        Password: "Passwort",
        KeepSMTPAlive: "Halten Sie SMTP am Lebens",
        ShouldTheConnectionStayAlive: "Sollte die Verbindung bestehen bleiben",
        SMTPAuthenticatio: "SMTP-Authentifizierung",
        DoesTheSmtpServerNeedsAuthentication:
          "Benötigt der SMTP-Server eine Authentifizierung",
        SendingEmailsViaOAuth2: "Versenden von E-Mails über oAuth2",
        AuthorizationEndpointURL: "Autorisierungsendpunkt-URL",
        TokenEndpointURL: "Token-Endpunkt-URL",
        ClientID: "Kunden ID",
        ClientSecret: "Client-Geheimnis",
        UploadSettings: "Upload-Einstellungen",
        DefineFileTypesAndlocation: "Definieren Sie Dateitypen und Speicherort",
        FolderPath: "Ordnerpfad",
        AllowedFilesClient: "Client für zulässige Dateien",
        AllowedFilesOperator: "Operator für zulässige Dateien",
        BlockClients: "Clients blockieren",
        SetEmailAddressesAndIPstoBlock:
          "Stellen Sie E-Mail-Adressen und IPs zum Blockieren ein",
        BlockEmailsOrDomains: "Blockieren Sie E-Mails oder Domains",
        BlockIPsOrSubnets: "Blockieren Sie IPs oder Subnetze",
        PushNotifications: "Mitteilungen",
        SetTheKeyForTheNativePushNotifications:
          "Legen Sie den Schlüssel für die nativen Push-Benachrichtigungen fest",
        PushTokenKey: "Token-Taste drücken",
        PushUserKey: "Benutzertaste drücken",
        License: "Licencia",
        EnterYourlicenseCode: "Geben Sie Ihren Lizenzcode ein",
        LicenseKey: "Lizenzschlüssel",
        Departments: "Abteilungen",
        CreateNewDepartment: "Neue Abteilung erstellen",
        "No Department Found": "Keine Abteilung gefunden",
        AutoEngages: "Schaltet sich automatisch ein",
        CreateNewEngageEntry: "Neuen Engage-Eintrag erstellen",
        "No Engage Entry Found": "Kein Engagement-Eintrag gefunden",
        Storage: "Lagerung",
        ClearStorage: "Lager räumen",
        Cache: "Zwischenspeicher",
        MaximumCacheSize: "Maximale Cache-Größe",
        KeepMedia: "Medien behalten",
        PhotosVideosAndOtherFiles: "Fotos, Videos und andere Dateien",
        ExtraPages: "Zusätzliche Seiten",
        Widget: "Widget",
        LiveChatWidgetStartChatAndProfile:
          "Live-Chat-Widget startet Chat und Profil",
        LiveChatWidgetbotfaqandrating: "Live-Chat-Widget-Bot/FAQ und Bewertung",
        LiveChatWidgetVideo: "Live-Chat-Widget-Video",
        SignIn: "Anmelden",
        SigninPage: "Anmeldeseite",
        TwoFactorAuthentificator: "Zwei-Faktor-Authentizierung",
        WhenTurnedOnItWillNeedAkey:
          "Beim Einschalten wird ein Schlüssel benötigt",
        SignUp: "Anmeldung",
        SignUpPage: "Anmeldeseite",
        PasswordReset: "Passwort zurücksetzen",
        PasswordResetPage: "Seite zum Zurücksetzen des Passworts",
        LockScreen: "Bildschirm sperren",
        LockScreenPage: "Bildschirmseite sperren",
        SearchSettings: "Sucheinstellungen",
        CreateNewArticle: "Neuen Artikel erstellen",
        "No FAQ found": "Keine FAQ gefunden",
        SearchFAQs: "Häufig gestellte Fragen durchsuchen",
        CreateNewBotMessage: "Neue Bot-Nachricht erstellen",
        "No Bot Found": "Nein, aber gefunden",
        SearchBotsHere: "Suchen Sie hier nach Bots",
        SearchResponse: "Suchantwort",
        CreateNewResponse: "Neue Antwort erstellen",
        "No Response Found": "Keine Antwort gefunden",
        SearchCustomers: "Kunden suchen",
        CreateNewCustomer: "Neuen Kunden anlegen",
        "No Client Found": "Kein Kunde gefunden",
        SearchMessagesOrUsers: "Suchen Sie Nachrichten oder Benutzer",
        NewGroupChat: "Neuer Gruppenchat",
        New: "Neu",
        ClearAll: "Alles löschen",
        SendYouaPrivateMessage: "Sende dir eine private Nachricht",
        NoNotificationsFound: "Keine Benachrichtigungen gefunden",
        SearchUsersByMail: "Suchen Sie Benutzer per E-Mail",
        ManageChatWidgets: "Chat-Widgets verwalten",
        NoConversationCreatedYet: "Noch keine Konversation erstellt",
        Dashboard: "Armaturenbrett",
        CurrentlyOnline: "Momentan Online",
        Visitors: "Besucher",
        SinceLastWeek: "Seit letzter Woche",
        Conversations: "Gespräche",
        Clients: "Kunden",
        Guests: "Gäste",
        TopClients: "Top-Kunden",
        TopOperators: "Top-Operatoren",
        zippchatAllinoneCommunication:
          "zipp.chat - Alles in einer Kommunikation",
        Allrightsreserved: "Alle Rechte vorbehalten",
      },
    },
    es: {
      translation: {
        Settings: "Ajustes",
        Operators: "Operadoras",
        FAQ: "Preguntas más frecuentes",
        ChatBot: "Chat Bot",
        StandardResponses: "Respuestas estándar",
        Customers: "Clientes",
        GroupChats: "Chats grupales",
        OfflineMessages: "Mensajes sin conexión",
        WebChats: "Charlas web",
        Notifications: "Notificaciones",
        SearchMessagesOrUsers: "Buscar mensajes o usuarias",
        Profile: "Perfil",
        Accounts: "cuentas",
        Rating: "Clasificación",
        CurrentRating: "Valoración actual",
        Stars: "Estrellas",
        Account: "Cuenta",
        ProfileSettings: "Configuración de perfil",
        ChangeYourProfileSettings: "Cambiar la configuración de su perfil",
        ConnectedAccounts: "Cuentas conectadas",
        ConnectWithYourAccounts: "Conéctese con sus cuentas",
        Twitter: "Gorjeo",
        UserRole: "Rol del usuario",
        Department: "Departamento",
        Language: "Idioma",
        Name: "Nombre",
        Email: "Correo electrónico",
        Phone: "Teléfono",
        Bio: "biografía",
        Loading: "Cargando...",
        Save: "Ahorrar",
        ConnectWithYourAccounts: "Conéctese con sus cuentas",
        Facebook: "Facebook",
        Instagram: "Instagram",
        Appearance: "Apariencia",
        ChooseLightOrDarkTheme: "Elige un tema claro u oscuro",
        Security: "Seguridad",
        Password: "Clave",
        ChangeYourPassword: "Cambia tu contraseña",
        CurrentPassword: "contraseña actual",
        NewPassword: "Nueva contraseña",
        VerifyPassword: "Verificar contraseña",
        TwoStepVerifications: "Verificaciones en dos pasos",
        EnableTwoStepVerifications: "Habilitar verificaciones en dos pasos",
        Notifications: "Notificaciones",
        Sound: "Sonar",
        EnableSoundNotifications: "Habilitar notificaciones de sonido",
        BrowserNotifications: "Notificaciones del navegador",
        EnableBrowserNotifications: "Habilitar notificaciones del navegador",
        Mitteilungen: "Notificaciones Push",
        EnablePushNotifications: "Habilitar notificaciones automáticas",
        Devices: "Dispositivos",
        Browser: "Navegadora",
        active: "activa",
        "Create New Operator": "Crear nueva operadora",
        MainSettings: "Ajustes principales",
        GeneralSettings: "Configuración general",
        TitleEmailLanguageAndTimezone:
          "Título, correo electrónico, idioma y zona horaria",
        Title: "Título",
        MainLanguage: "Lenguaje principal",
        YourTimezone: "Tu zona horaria",
        TimeFormat: "Formato de tiempo",
        DateFormat: "Formato de fecha",
        EUDSGVOrAgreement: "EU-DSGV o Acuerdo",
        GeneralSettingsForYourClients:
          "Configuraciones generales para tus clientas",
        Register: "Registro",
        CanClientsRegisterFromTheFrontEndForm:
          "¿Pueden los clientes registrarse desde el formulario front-end?",
        CanClientsRateTheOperators:
          "¿Pueden los clientes calificar a las operadoras?",
        Transcript: "Transcripción",
        CanClientsRequestTheChatTranscript:
          "¿Pueden los clientes solicitar la transcripción del chat?",
        FileUpload: "Subir archivo",
        FileUploadActivatedByStandard:
          "Carga de archivos activada por estándar",
        TurnOff: "Apagar",
        TurnOffAllChatWidgetsAndOutsideCommunications:
          "Apague todos los widgets de chat y comunicaciones externas",
        EmailSettings: "Ajustes del correo electrónico",
        TheWayYouAreSendingEmails:
          "La forma en que está enviando correos electrónicos",
        SMTP: "SMTP",
        SendingEmailsViaSMTP: "Envío de correos electrónicos a través de SMTP",
        FromEmail: "Desde el e-mail",
        Host: "Anfitriona",
        Prefix: "Prefijo",
        Username: "Nombre de usuario",
        Password: "Clave",
        KeepSMTPAlive: "Mantener viva SMTP",
        ShouldTheConnectionStayAlive: "Si la conexión se mantiene viva",
        SMTPAuthenticatio: "Autenticación SMTP",
        DoesTheSmtpServerNeedsAuthentication:
          "¿El servidor smtp necesita autenticación?",
        SendingEmailsViaOAuth2:
          "Envío de correos electrónicos a través de oAuth2",
        AuthorizationEndpointURL: "URL de extremo de autorización",
        TokenEndpointURL: "URL de punto final de token",
        ClientID: "Identificación del cliente",
        ClientSecret: "Secreto del cliente",
        UploadSettings: "Configuración de carga",
        DefineFileTypesAndlocation: "Definir tipos de archivo y ubicación",
        FolderPath: "Ruta de la carpeta",
        AllowedFilesClient: "Cliente de archivos permitidos",
        AllowedFilesOperator: "Operadora de archivos permitidos",
        BlockClients: "Bloquear clientes",
        SetEmailAddressesAndIPstoBlock:
          "Establecer direcciones de correo electrónico e IP para bloquear",
        BlockEmailsOrDomains: "Bloquear correos electrónicos o dominios",
        BlockIPsOrSubnets: "Bloquear IP's o subredes",
        PushNotifications: "Notificaciones push",
        SetTheKeyForTheNativePushNotifications:
          "Establecer la clave para las notificaciones automáticas nativas",
        PushTokenKey: "Clave de token de inserción",
        PushUserKey: "Pulsar tecla de usuario",
        License: "Licencia",
        EnterYourlicenseCode: "Introduzca su código de licencia",
        LicenseKey: "Clave de licencia",
        Departments: "Departamentos",
        CreateNewDepartment: "Crear Nuevo Departamento",
        "No Department Found": "No se encontró ningún departamento",
        AutoEngages: "Enganches automáticos",
        CreateNewEngageEntry: "Crear nueva entrada de Engage",
        "No Engage Entry Found": "No se encontró ninguna entrada de Engage",
        Storage: "Almacenamiento",
        ClearStorage: "Almacenaje vacío",
        Cache: "Cache",
        MaximumCacheSize: "Tamaño máximo de caché",
        KeepMedia: "Mantener medios",
        PhotosVideosAndOtherFiles: "Vídeos de fotos y otros archivos.",
        ExtraPages: "Páginas adicionales",
        Widget: "Widget",
        LiveChatWidgetStartChatAndProfile:
          "Widget de chat en vivo iniciar chat y perfil",
        LiveChatWidgetbotfaqandrating:
          "Chat en vivo Widget bot/preguntas frecuentes y calificación",
        LiveChatWidgetVideo: "Video del widget de chat en vivo",
        SignIn: "Sign In",
        SigninPage: "Página de inicio de sesión",
        TwoFactorAuthentificator: "Autenticación de dos factores",
        WhenTurnedOnItWillNeedAkey:
          "Cuando esté encendido, necesitará una llave.",
        SignUp: "Inscribirse",
        SignUpPage: "Página de registro",
        PasswordReset: "Restablecimiento de contraseña",
        PasswordResetPage: "Página de restablecimiento de contraseña",
        LockScreen: "Bloquear pantalla",
        LockScreenPage: "Página de bloqueo de pantalla",
        SearchSettings: "Opciones de búsqueda",
        CreateNewArticle: "Crear nuevo artículo",
        "No FAQ found": "No se encontraron preguntas frecuentes",
        SearchFAQs: "Buscar preguntas frecuentes",
        CreateNewBotMessage: "Crear nuevo mensaje de bot",
        "No Bot Found": "No pero encontrado",
        SearchBotsHere: "Buscar bots aquí",
        SearchResponse: "Respuesta de búsqueda",
        CreateNewResponse: "Crear nueva respuesta",
        "No Response Found": "No se encontró respuesta",
        SearchCustomers: "Buscar clientes",
        CreateNewCustomer: "Crear nueva cliente",
        "No Client Found": "No se encontró ningún cliente",
        SearchMessagesOrUsers: "Buscar mensajes o usuarias",
        NewGroupChat: "Nuevo chat grupal",
        New: "nueva",
        ClearAll: "Limpiar todo",
        SendYouaPrivateMessage: "enviarte un mensaje privado",
        NoNotificationsFound: "No se encontraron notificaciones",
        SearchUsersByMail: "Buscar usuarios por correo",
        ManageChatWidgets: "Administrar widgets de chat",
        NoConversationCreatedYet: "Aún no se ha creado ninguna conversación",
        Dashboard: "Tablero",
        CurrentlyOnline: "Actualmente en linea",
        Visitors: "Visitantes",
        SinceLastWeek: "Desde la semana pasada",
        Conversations: "Conversaciones",
        Clients: "Clientas",
        Guests: "Invitadas",
        TopClients: "Clientes principales",
        TopOperators: "Operadores principales",
        zippchatAllinoneCommunication: "zipp.chat - Comunicación todo en uno",
        Allrightsreserved: "Reservados todos los derechos",
      },
    },
  },
  keySeparator: false,
  interpolation: { escapeValue: false },
});

export default i18n;
