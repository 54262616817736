import React, { memo, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { USER_ROLES } from "../../constant";
import { ToastContainer as Toaster } from "../";
import { useSelector, useDispatch } from "react-redux";
import CardHeader from "../../assets/img/core/card-header.png";
import { transferChatNotification } from "../../redux/actions/notificationAction";

let REQUIRED_FILED = `Required field`;

const TransferModal = ({ showTransferModal, setShowTransferModal }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { socket } = useSelector((state) => state.socketReducer);
  const { allOperators } = useSelector((state) => state.operatorReducer);
  const { conversationById } = useSelector((state) => state.chatReducer);

  let INITIAL_VALUES = {
    invitation_message: "",
    receiverId: "select",
    is_invite_to_operator: false,
  };
  const validationSchema = yup.object({
    invitation_message: yup
      .string(REQUIRED_FILED)
      .min(5, "Response must be 5 characters long")
      .required(REQUIRED_FILED),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!loading && showTransferModal) {
        if (values?.receiverId === "select") {
          Toaster({
            seccess: false,
            message: "Please select operator",
          });
        } else {
          setLoading(true);
          if (conversationById?._id) {
            values.conversationId = conversationById?._id;
          }
          let res = await dispatch(transferChatNotification(values));
          if (res?.success) {
            resetForm();
            setShowTransferModal(false);
            socket.emit("notification", res?.data);
          }
          setLoading(false);
          Toaster(res);
        }
      }
    },
  });

  let filteredOperators = allOperators?.filter((operator) => {
    return operator?.user_role !== USER_ROLES?.EDITOR;
  });

  return (
    <React.Fragment>
      <Modal
        show={showTransferModal}
        onHide={() => setShowTransferModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={formik.handleSubmit}>
          <div
            // className="modal fade"
            id="modal-transfer"
            tabIndex="-1"
            aria-labelledby="modal-transfer"
            aria-hidden="true"
          >
            <div className="modal-content">
              <div className="modal-body py-0">
                <div className="profile modal-gx-n">
                  <div className="profile-img text-primary rounded-top-xl">
                    <img src={CardHeader} width="100%" />
                    <div className="position-absolute top-0 start-0 p-5">
                      <button
                        className="btn-close btn-close-white btn-close-arrow opacity-100"
                        onClick={() => setShowTransferModal(false)}
                      />
                    </div>
                  </div>
                  <div className="profile-body">
                    <div className="avatar avatar-lg">
                      <span className="avatar-text bg-primary">
                        <i className="fal fa-paper-plane"></i>
                      </span>
                    </div>
                    <h4 className="fw-bold mb-1">Transfer or Invite</h4>
                    <p style={{ fontSize: "16px" }}>
                      Transfer or invite this client to another operator.
                    </p>
                  </div>
                </div>
                <div className="modal-py">
                  <div className="card-body">
                    <form autoComplete="off">
                      <div className="row gy-6">
                        <div className="col-12">
                          <div className="form-floating">
                            <textarea
                              className="form-control"
                              placeholder="Description"
                              id="transfer-message"
                              rows="8"
                              data-autosize="true"
                              style={{ minHeight: "100px" }}
                              name="invitation_message"
                              value={formik.values.invitation_message}
                              onChange={formik.handleChange}
                            ></textarea>
                            <label htmlFor="transfer-message">
                              Transfer / Invitation Message
                            </label>
                            <small style={{ color: "red", marginLeft: "10px" }}>
                              {formik.touched.invitation_message &&
                                formik.errors.invitation_message}
                            </small>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <select
                              className="form-select"
                              id="operator"
                              name="receiverId"
                              value={formik.values.receiverId}
                              onChange={formik.handleChange}
                            >
                              <option value="select">Select Operator</option>
                              {filteredOperators?.length > 0 &&
                                filteredOperators?.map((operator, index) => (
                                  <option value={operator?._id} key={index}>
                                    {operator?.name}
                                  </option>
                                ))}
                            </select>
                            <label htmlFor="department">
                              Available Operators
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="d-flex align-items-center mb-4 px-6">
                    <small className="text-muted me-auto">Options</small>
                  </div>
                  <div className="card border-0">
                    <div className="card-body">
                      <div className="row gx-5">
                        <div className="col-auto">
                          <div className="btn btn-sm btn-icon btn-dark">
                            <i className="fal fa-gift"></i>
                          </div>
                        </div>
                        <div className="col">
                          <h5>Invite</h5>
                          <p>Send an invitation to this Operator?</p>
                        </div>
                        <div className="col-auto align-self-center">
                          <div className="form-check form-switch ps-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="transfer-options-1"
                              name="is_invite_to_operator"
                              value={formik.values.is_invite_to_operator}
                              onChange={formik.handleChange}
                              checked={formik.values.is_invite_to_operator}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="transfer-options-1"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="hr-bold modal-gx-n my-0" />
                <div className="modal-py">
                  <button
                    type="submit"
                    style={{ height: "56px" }}
                    className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                  >
                    {loading ? (
                      <div
                        className="spinner-grow text-light"
                        role="status"
                        style={{
                          backgroundColor: "white",
                          width: "15px",
                          height: "15px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        Send
                        <span className="icon ms-auto">
                          <i className="fal fa-chevron-right"></i>
                        </span>
                      </React.Fragment>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </React.Fragment>
  );
};
export default memo(TransferModal);
