import React, { memo, useState } from "react";
import CardHeader from "../../assets/img/core/card-header.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { axiosClient } from "../../utils";
import { useAuth } from "../../hooks";
import Cookies from "js-cookie";
import { TYPES } from "../../redux/actionTypes";
import { USER_ROLES } from "../../constant";
import ReactStars from "react-rating-stars-component";
import {
  updateUserProfile,
  updateUserPhoto,
  ClearAuthData,
} from "../../redux/actions/userAction";

const Profile = () => {
  const [signOutLoading, setSignOutLoading] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
  const [uploadImageLoading, setUploadimageLoading] = useState(false);

  const data = new FormData();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.userReducer);
  const { socket } = useSelector((state) => state.socketReducer);

  const validationSchema = yup.object({
    name: yup.string("Enter your name"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userData?.data?.name,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (values?.name === "") {
      } else {
        setUpdateProfileLoading(true);
        let updateRes = await dispatch(
          updateUserProfile(values, userData?.data?._id)
        );
        notifySuccess(updateRes?.message);
        setUpdateProfileLoading(false);
        setEditProfile(false);
      }
    },
  });
  const handleSignOut = async () => {
    Cookies.remove(`accessToken`);
    localStorage.removeItem(`sessionId`);
    sessionStorage.clear();
    dispatch(ClearAuthData({}));
    setSignOutLoading(true);
    dispatch({
      type: TYPES.USER_LOGOUT,
    });
    setTimeout(() => {
      setSignOutLoading(false);
      logout();
      socket.disconnect();
      navigate("/");
      setTimeout(() => {
        window.location.reload();
      }, 10);
    }, 1000);
    axiosClient().post(`/user/removeSession`, {
      sessions: { id: localStorage.getItem(`sessionId`) },
    });
  };
  const handleUserProfilePhoto = async (e) => {
    data.append("picture", e.target.files[0]);
    setUploadimageLoading(true);
    await dispatch(updateUserPhoto(data));
    setUploadimageLoading(false);
  };
  const handleUpdateProfile = (val) => {
    setEditProfile(val);
  };
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const getRating = (ratings) => {
    let totalRateSum = 0;
    let numOfRatings = ratings?.length;

    for (let index = 0; index < ratings?.length; index++) {
      totalRateSum += ratings[index]?.ratings;
    }

    let averageRating = totalRateSum / numOfRatings;

    return averageRating;
  };

  let onlineStatusTimeout;
  const handleOnlineStatus = (status) => {
    clearTimeout(onlineStatusTimeout);

    onlineStatusTimeout = setTimeout(() => {}, 1000);
  };

  let activeStatusTimeout;
  const handleActiveStatus = (status) => {
    clearTimeout(activeStatusTimeout);

    activeStatusTimeout = setTimeout(() => {}, 1000);
  };

  return (
    <div
      className="modal fade"
      id="modal-profile"
      tabIndex="-1"
      aria-labelledby="modal-profile"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-fullscreen-xl-down">
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body py-0">
              <div className="profile modal-gx-n">
                <div className="profile-img text-primary rounded-top-xl">
                  <img src={CardHeader} width="100%" />

                  <div className="position-absolute top-0 start-0 py-6 px-5">
                    <button
                      type="button"
                      className="btn-close btn-close-white btn-close-arrow opacity-100"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>

                <div className="profile-body">
                  <div
                    className={`avatar avatar-xl user_profile_pic_div ${
                      socket?.connected ? "avatar-online" : null
                    }`}
                  >
                    {userData?.data?.picture ? (
                      <img
                        className="avatar-img round_avatar"
                        src={userData?.data?.picture}
                        alt="#"
                      />
                    ) : (
                      <span className="avatar-text">
                        {userData?.data?.name?.charAt(0).toUpperCase()}
                      </span>
                    )}
                    {uploadImageLoading ? (
                      <div
                        className="spinner-grow text-light"
                        role="status"
                        style={{
                          backgroundColor: "white",
                          minWidth: "74px",
                          minHeight: "74px",
                          zIndex: 100,
                          position: "absolute",
                        }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        <input
                          id="imageUpload"
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => handleUserProfilePhoto(e)}
                        />
                        <label
                          className="user_profile_upload_icon d-grid"
                          htmlFor="imageUpload"
                        >
                          <i className="fal fa-camera camera_icon" />
                        </label>
                      </React.Fragment>
                    )}
                  </div>
                  <small
                    style={{
                      fontSize: 12,
                      fontStyle: "italic",
                    }}
                  >
                    Upload 1x1 pic
                  </small>
                  <h4 className="mb-1 mt-5">{userData?.data?.name}</h4>
                  {socket?.connected ? <p>Online</p> : null}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {userData?.data?.ratings ? (
                      <ReactStars
                        count={5}
                        isHalf={true}
                        size={15}
                        value={getRating(userData?.data?.ratings)}
                        edit={false}
                        activeColor="#a7a6a8"
                        emptyIcon={<i className="fal fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        filledIcon={<i className="fa fa-star"></i>}
                      />
                    ) : null}
                  </div>
                </div>
              </div>

              <hr className="hr-bold modal-gx-n my-0" />
              {editProfile ? (
                <React.Fragment>
                  <div className="col-12" style={{ marginTop: "15px" }}>
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control signup_inputs"
                        id="signup-name"
                        placeholder="Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="signup-name">Name</label>
                    </div>
                  </div>
                  <div className="col-12" style={{ marginTop: "15px" }}>
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control signup_inputs"
                        id="signup-email"
                        defaultValue={userData?.data?.email}
                        disabled
                        style={{ cursor: "not-allowed" }}
                      />
                      <label htmlFor="signup-email">Email</label>
                    </div>
                  </div>
                  <div className="col-12" style={{ marginTop: "15px" }}>
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control signup_inputs"
                        id="signup-phone"
                        defaultValue={userData?.data?.phone}
                        disabled
                        style={{ cursor: "not-allowed" }}
                      />
                      <label htmlFor="signup-phone">Phone number</label>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      className="edit_profile_cancel_btn"
                      onClick={() => handleUpdateProfile(false)}
                    >
                      cancel
                    </button>
                    <button className="edit_profile_save_btn" type="submit">
                      {updateProfileLoading ? (
                        <div
                          className="spinner-grow text-light"
                          role="status"
                          style={{
                            backgroundColor: "#0F79F4",
                            width: "15px",
                            height: "15px",
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "save changes"
                      )}
                    </button>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="edit_profile_btn"
                      onClick={() => handleUpdateProfile(true)}
                    >
                      Edit Profile
                    </button>
                  </div>
                  {[
                    USER_ROLES.SUPER_OPERATOR,
                    USER_ROLES.OPERATOR,
                    USER_ROLES.JUNIOR_OPERATOR,
                    USER_ROLES.EDITOR,
                  ]?.includes(userData?.data?.user_role) && (
                    <React.Fragment>
                      <hr className="hr-bold modal-gx-n my-0" />
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          <div className="row align-items-center gx-6">
                            <div className="col">
                              <h5>Online</h5>
                              <p>Are you online, serve clients?</p>
                            </div>

                            <div className="col-auto">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="online-status"
                                  onChange={(e) =>
                                    handleOnlineStatus(e.target.checked)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="online-status"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </React.Fragment>
                  )}

                  <hr className="hr-bold modal-gx-n my-0" />

                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="row align-items-center gx-6">
                        <div className="col">
                          <h5>Active status</h5>
                          <p>Show when you're active for inhouse.</p>
                        </div>

                        <div className="col-auto">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="profile-status"
                              onChange={(e) =>
                                handleActiveStatus(e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="profile-status"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <hr className="hr-bold modal-gx-n my-0" />

                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="row align-items-center gx-6">
                        <div className="col">
                          <h5>Location</h5>
                          <p>USA, Houston</p>
                        </div>

                        <div className="col-auto">
                          <div className="btn btn-sm btn-icon btn-dark">
                            <i className="fal fa-globe"></i>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="list-group-item">
                      <div className="row align-items-center gx-6">
                        <div className="col">
                          <h5>E-mail</h5>
                          <p>{userData?.data?.email}</p>
                        </div>

                        <div className="col-auto">
                          <div className="btn btn-sm btn-icon btn-dark">
                            <i className="fal fa-envelope"></i>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li className="list-group-item">
                      <div className="row align-items-center gx-6">
                        <div className="col">
                          <h5>Phone</h5>
                          <p>{userData?.data?.phone}</p>
                        </div>

                        <div className="col-auto">
                          <div className="btn btn-sm btn-icon btn-dark">
                            <i className="fal fa-headset"></i>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <hr className="hr-bold modal-gx-n my-0" />
                  <ul className="list-group list-group-flush">
                    <li
                      className="list-group-item"
                      style={{
                        display: "flex",
                        justifyContent: !signOutLoading ? "start" : "center",
                      }}
                    >
                      <a
                        onClick={handleSignOut}
                        data-bs-dismiss="modal"
                        className="text-danger"
                        style={{ cursor: "pointer", width: "100%" }}
                      >
                        {signOutLoading ? (
                          <div
                            className="spinner-grow text-light"
                            role="status"
                            style={{
                              backgroundColor: "red",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          "Logout"
                        )}
                      </a>
                    </li>
                  </ul>
                </React.Fragment>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default memo(Profile);
