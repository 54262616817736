/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { ToastContainer } from "./toast";
import { ToastContainer as Toast } from ".";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { newConversation } from "../redux/actions/chatAction";
import { handleModal } from "../redux/actions/corfirmationModalAction";
import { deleteOperator, setOperatorId } from "../redux/actions/operatorAction";
import { USER_ROLES } from "../constant";

const Operator = ({ item, online, setModalShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { socket } = useSelector((state) => state.socketReducer);
  const { allConversations } = useSelector((state) => state.chatReducer);
  const { userData } = useSelector((state) => state.userReducer);

  const handleDelete = async (operatorId) => {
    let res = await dispatch(deleteOperator(operatorId));
    ToastContainer(res);
  };
  const handleEditOperator = (operatorId, purpose) => {
    dispatch(setOperatorId(operatorId, purpose));
    setModalShow(true);
  };
  const confirmationModal = (operatorId) => {
    dispatch(
      handleModal({
        is_show: true,
        function: handleDelete,
        param: operatorId,
      })
    );
  };
  let user_role = "";
  switch (item?.user_role) {
    case USER_ROLES?.EDITOR:
      user_role = "Editor";
      break;
    case USER_ROLES?.JUNIOR_OPERATOR:
      user_role = "Junior Operator";
      break;
    case USER_ROLES?.OPERATOR:
      user_role = "Operator";
      break;
    case USER_ROLES?.SUPER_OPERATOR:
      user_role = "Super Operator";
      break;
    default:
      user_role = "";
  }
  const handleConversation = async (item) => {
    if (
      allConversations?.find((x) => {
        return x?.recipients?.find((y) => {
          return y?._id === item?._id;
        });
      })
    ) {
      navigate(`/chat-direct/${item?._id}`);
    } else {
      let res = await dispatch(
        newConversation({
          receiverId: [item?._id],
        })
      );
      Toast(res);
      if (res?.success) {
        setTimeout(() => {
          socket.emit("addNewConversation", {
            newConversation: res?.data,
            to: item?._id,
          });
          navigate(`/chat-direct/${item?._id}`);
        }, 1000);
      }
    }
  };
  return (
    <div className="card border-0">
      <div className="card-body">
        <div className="row align-items-center gx-5">
          <div className="col-auto">
            <a className={`avatar ${item?.is_user_online && "avatar-online"}`}>
              {item?.picture ? (
                <img
                  className="avatar-img round_avatar"
                  src={item?.picture}
                  alt=""
                />
              ) : (
                <span className="avatar-text">
                  {item?.name?.charAt(0).toUpperCase()}
                </span>
              )}
            </a>
          </div>

          <div className="col">
            <h5>
              <a
                style={{ textTransform: "capitalize" }}
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modal-operator"
                onClick={() => handleEditOperator(item?._id, "edit")}
              >
                {item?.name}
              </a>
            </h5>
            <p>{item?.is_user_online && "Online"}</p>
          </div>

          <div className="col-auto">
            <div className="dropdown">
              <a
                className="icon text-muted"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-v"></i>
              </a>

              <ul className="dropdown-menu">
                {userData?.data?._id !== item?._id && (
                  <li>
                    <a
                      onClick={() => handleConversation(item)}
                      className="dropdown-item"
                      style={{ cursor: `pointer` }}
                    >
                      {allConversations?.find((x) => {
                        return x?.recipients?.find((y) => {
                          return y?._id === item?._id;
                        });
                      })
                        ? "Send Message"
                        : "New message"}
                    </a>
                  </li>
                )}
                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: `pointer` }}
                    data-bs-toggle="modal"
                    data-bs-target="#modal-operator"
                    onClick={() => handleEditOperator(item?._id, "edit")}
                  >
                    Edit {user_role}
                  </a>
                </li>
                {item?.user_role !== USER_ROLES?.SUPER_OPERATOR && (
                  <React.Fragment>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item text-danger"
                        style={{ cursor: `pointer` }}
                        onClick={() => confirmationModal(item?._id)}
                      >
                        Delete {user_role}
                      </a>
                    </li>
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Operator);
