import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { ReactQuill, ToastContainer } from "..";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import CardHeader from "../../assets/img/core/card-header.png";
import { emailTempleteEnum } from "../../constant";
import {
  createEmailTemplate,
  editEmailTemplate,
} from "../../redux/actions/emailTemplateAction";

let REQUIRED_FILED = `Required field`;

const EmailTemplateModal = ({ setAddEmailModalShow, addEmailModalShow }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { allEmailTemplates } = useSelector(
    (state) => state.emailTemplateReducer
  );

  let INITIAL_VALUE = {
    template_slug: addEmailModalShow?.template?.template_slug ?? "",
    title: addEmailModalShow?.template?.title ?? "",
    description: addEmailModalShow?.template?.description ?? "",
    subject: addEmailModalShow?.template?.subject ?? "",
    body: addEmailModalShow?.template?.body ?? "",
    add_button: addEmailModalShow?.template?.add_button ?? false,
    button_url: addEmailModalShow?.template?.button_url ?? "",
    button_text: addEmailModalShow?.template?.button_text ?? "",
  };

  const validationSchema = yup.object({
    template_slug: yup.string(REQUIRED_FILED).required(REQUIRED_FILED),
    title: yup
      .string(REQUIRED_FILED)
      .min(3, "Title must be 3 characters long")
      .required(REQUIRED_FILED),
    description: yup
      .string(REQUIRED_FILED)
      .min(5, "Description must be 5 characters long")
      .required(REQUIRED_FILED),
    subject: yup
      .string(REQUIRED_FILED)
      .min(3, "Subject must be 3 characters long")
      .required(REQUIRED_FILED),
    body: yup.string(REQUIRED_FILED).required(REQUIRED_FILED),
    add_button: yup.boolean().required(), // Ensure add_button is part of the schema
    button_url: yup.string(REQUIRED_FILED).when("add_button", {
      is: true,
      then: (schema) =>
        schema
          .min(5, "Button URL must be 5 characters long")
          .matches(
            /^https?:\/\/.+/,
            "Button URL must start with http:// or https://"
          )
          .required(REQUIRED_FILED),
      otherwise: (schema) => schema.notRequired(),
    }),
    button_text: yup.string(REQUIRED_FILED).when("add_button", {
      is: true,
      then: (schema) =>
        schema
          .min(3, "Button label must be 3 characters long")
          .max(50, "Button label must be less than 50 characters")
          .required(REQUIRED_FILED),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUE,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!loading) {
        if (addEmailModalShow?.template === "Add New") {
          setLoading(true);
          const res = await dispatch(createEmailTemplate(values));
          ToastContainer(res);
          setLoading(false);
          if (res?.success) {
            resetForm();
            setAddEmailModalShow({
              isShow: false,
              template: "",
            });
          }
        } else {
          setLoading(true);
          const res = await dispatch(
            editEmailTemplate(addEmailModalShow?.template?._id, values)
          );
          ToastContainer(res);
          setLoading(false);
          if (res?.success) {
            resetForm();
            setAddEmailModalShow({
              isShow: false,
              template: "",
            });
          }
        }
      }
    },
  });

  useEffect(() => {
    if (formik?.values?.body === "<p><br></p>")
      formik.setFieldValue("body", "");
  }, [formik?.values?.body]);

  useEffect(() => {
    if (!formik.values.add_button) {
      formik.setFieldValue("button_url", "");
      formik.setFieldValue("button_text", "");
    }
  }, [formik.values.add_button]);

  const handleEditorChange = (html) => {
    if (html == "<p><br></p>") {
      formik.setFieldValue("body", "");
      return;
    } else {
      formik.setFieldValue("body", html);
    }
  };
  const handleEditorBlur = () => {
    formik.setFieldTouched("body", true);
  };

  const getPlaceholders = (template_slug) => {
    switch (template_slug) {
      case "welcome_email":
        return "You can use placeholders: %user_name% , %email% , %password% , %phone% , %application_name% , %user_role% , %invitation_message%";
      case "verification_code":
        return "You can use placeholders: %user_name% , %application_name% , %otp%";
      case "transcript_pdf":
        return "You can use placeholders: %user_name% , %email% ,  %application_name%";
      case "new_message":
        return "You can use placeholders: %user_name% , %email% , %application_name%";
      case "new_conversation":
        return "You can use placeholders: %user_name% , %email% , %application_name%";
      default:
        return "";
    }
  };

  return (
    <Modal
      show={addEmailModalShow?.isShow}
      onHide={() =>
        setAddEmailModalShow({
          isShow: false,
          template: "",
        })
      }
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          id="modal-bot"
          tabIndex="-1"
          aria-labelledby="modal-bot"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-body py-0">
              <div className="profile modal-gx-n">
                <div className="profile-img text-primary rounded-top-xl">
                  <img src={CardHeader} width="100%" />
                  <div className="position-absolute top-0 start-0 p-5">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setAddEmailModalShow({
                          isShow: false,
                          template: "",
                        })
                      }
                      className="btn-close btn-close-white btn-close-arrow opacity-100"
                    />
                  </div>
                </div>
                <div className="profile-body">
                  <div className="avatar avatar-lg">
                    <span className="avatar-text bg-primary">
                      <i className="fal fa-envelope fa-lg"></i>
                    </span>
                  </div>
                  <h4
                    className="fw-bold mb-1"
                    style={{ textTransform: "capitalizes" }}
                  >
                    Email Template
                  </h4>
                  <p style={{ fontSize: "16px" }}>
                    Setup email templates according to your need
                  </p>
                </div>
              </div>
              <div className="modal-py">
                <div className="card-body">
                  <form autoComplete="off">
                    <div className="row gy-6">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="template-title"
                            placeholder="Enter Title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="template-title">Title</label>
                          {formik.touched.title && formik.errors.title && (
                            <small style={{ color: "red", marginLeft: "10px" }}>
                              {formik.touched.title && formik.errors.title}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="template-description"
                            placeholder="Enter Description"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="template-description">
                            Description
                          </label>
                          {formik.touched.description &&
                            formik.errors.description && (
                              <small
                                style={{ color: "red", marginLeft: "10px" }}
                              >
                                {formik.touched.description &&
                                  formik.errors.description}
                              </small>
                            )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="template_slug"
                            name="template_slug"
                            value={formik?.values?.template_slug}
                            onChange={formik.handleChange}
                            disabled={addEmailModalShow?.template?._id}
                          >
                            <option value="" disabled>
                              Select Template Type
                            </option>
                            {emailTempleteEnum?.map((option, index) => (
                              <option
                                value={option}
                                key={index}
                                style={{ textTransform: "capitalize" }}
                                disabled={allEmailTemplates?.find((temp) => {
                                  return temp?.template_slug === option;
                                })}
                              >
                                {option
                                  ?.replaceAll("_", " ")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="template_slug">Template Type</label>
                        </div>
                        {formik.touched.template_slug &&
                          formik.errors.template_slug && (
                            <small style={{ color: "red", marginLeft: "10px" }}>
                              {formik.touched.template_slug &&
                                formik.errors.template_slug}
                            </small>
                          )}
                      </div>

                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="template-subject"
                            placeholder="Enter Subject"
                            name="subject"
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="template-subject">
                            Email Subject
                          </label>
                          {formik.touched.subject && formik.errors.subject && (
                            <small style={{ color: "red", marginLeft: "10px" }}>
                              {formik.touched.subject && formik.errors.subject}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div
                          className={
                            localStorage.getItem(`theme`) === "light"
                              ? "form-floating light"
                              : "form-floating dark"
                          }
                        >
                          <ReactQuill
                            placeholder="Email Body"
                            quillValue={formik?.values?.body}
                            handleChange={handleEditorChange}
                            handleEditorBlur={handleEditorBlur}
                          />
                          {formik.touched?.body && formik.errors?.body && (
                            <small style={{ color: "red", marginLeft: "10px" }}>
                              {formik.touched?.body && formik.errors?.body}
                            </small>
                          )}
                          <div id="bot-help" className="form-text">
                            {getPlaceholders(formik?.values?.template_slug)}
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="row gx-5">
                          <div className="col">
                            <h5>Add Button</h5>
                            <p>Should add button in email template</p>
                          </div>
                          <div className="col-auto align-self-center">
                            <div className="form-check form-switch ps-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="bot-options-3"
                                name="add_button"
                                value={formik.values.add_button}
                                onChange={formik.handleChange}
                                checked={formik.values.add_button}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="bot-options-3"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {formik.values.add_button ? (
                        <>
                          <div className="col-12">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                id="template-button-url"
                                placeholder="Enter Button Url"
                                name="button_url"
                                value={formik.values.button_url}
                                onChange={formik.handleChange}
                              />
                              <label htmlFor="template-button-url">
                                Button Url
                              </label>
                              {formik.touched.button_url &&
                                formik.errors.button_url && (
                                  <small
                                    style={{ color: "red", marginLeft: "10px" }}
                                  >
                                    {formik.touched.button_url &&
                                      formik.errors.button_url}
                                  </small>
                                )}
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                id="template-button-text"
                                placeholder="Enter Button Text"
                                name="button_text"
                                value={formik.values.button_text}
                                onChange={formik.handleChange}
                              />
                              <label htmlFor="template-button-text">
                                Button Text
                              </label>
                              {formik.touched.button_text &&
                                formik.errors.button_text && (
                                  <small
                                    style={{ color: "red", marginLeft: "10px" }}
                                  >
                                    {formik.touched.button_text &&
                                      formik.errors.button_text}
                                  </small>
                                )}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </form>
                </div>
              </div>

              <hr className="hr-bold modal-gx-n my-0" />

              <div className="modal-py">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                  style={{ height: "56px" }}
                >
                  {loading ? (
                    <div
                      className="spinner-grow text-light"
                      role="status"
                      style={{
                        backgroundColor: "white",
                        width: "15px",
                        height: "15px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>
                      Save
                      <span className="icon ms-auto">
                        <i className="fal fa-chevron-right"></i>
                      </span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default EmailTemplateModal;
