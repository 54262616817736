export const CheckEmojy = (text) => {
  const emojiRegex =
    /(?<!:\/\/)(:\)|:-\)|:D|\^_\^|<3|:\(|:'\(|:-\(|:'\)|;\)|;-\)|:O|:o|;\(|:P|:-P|:\*|:\||:\/|:x|B-\)|:s|:\$|:&|:#|:!|:~|:@|:%|:\^\)|:\*\*|:\+1:|:-1:)(?!\/)/g;

  const emojiMap = {
    ":)": "😊",
    ":-)": "😃",
    ":D": "😄",
    "^_^": "😊",
    "<3": "❤️",
    ":(": "😞",
    ":'(": "😢",
    ":-(": "😟",
    ":')": "😂",
    ";)": "😉",
    ";-)": "😉",
    ":O": "😯",
    ":o": "😲",
    ";(": "😔",
    ":P": "😛",
    ":-P": "😜",
    ":*": "😘",
    ":|": "😐",
    ":/": "😕",
    ":x": "😠",
    "B-)": "😎",
    ":s": "😕",
    ":$": "😳",
    ":&": "😠",
    ":#": "😡",
    ":!": "😡",
    ":~": "😬",
    ":@": "😡",
    ":%": "😡",
    ":^)": "😊",
    ":**": "😘",
    ":+1:": "👍",
    ":-1:": "👎",
  };

  const result = text.replace(emojiRegex, (match) => emojiMap[match] || match);
  return result;
};
