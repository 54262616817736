/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { TYPES } from "../redux/actionTypes";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/img/brand/brand.png";
import { ProfileModal } from "../components/modal";
import { USER_ROLES, NAVIGATOR } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { auth, getAllUsers } from "../redux/actions/userAction";
import { getApplicationStatics } from "../redux/actions/applicationAction";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Navigator = ({ style }) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [showNoti, setShowNoti] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { socket } = useSelector((state) => state.socketReducer);
  const { appSettings } = useSelector((state) => state.applicationReducer);
  const { allConversations } = useSelector((state) => state.chatReducer);
  const { allGroups } = useSelector((state) => state.groupChatReducer);
  const { userData, allUsers } = useSelector((state) => state.userReducer);
  const { appStatics, activeTab } = useSelector(
    (state) => state.applicationReducer
  );
  const { allNotifications } = useSelector(
    (state) => state.notificationReducer
  );

  useEffect(() => {
    if (Object?.keys(appStatics)?.length <= 0) {
      dispatch(getApplicationStatics());
    }
    if (!userData?.data?._id) {
      getAuth();
    }
    if (!allUsers?.data?.length > 0) {
      dispatch(getAllUsers());
    }
  }, []);

  const getAuth = async () => {
    let res = await dispatch(auth());
    if (!res?.success || res?.message === "Token Is Not Valid") {
      localStorage.clear();
      Cookies.remove("accessToken");
      handleNavigate("/");
    }
  };

  const handleNavigate = (link) => {
    navigate(link);
  };

  const handleNavigatorTabs = (value) => {
    dispatch({
      type: TYPES.SET_ACTIVE_TAB,
      payload: value,
    });
    sessionStorage.setItem("activeTab", value);
  };

  let Theme1 = localStorage.getItem("theme");

  const handleTheme = (value) => {
    localStorage.setItem("theme", value);
    setTheme(value);
    if (Theme1 !== value) {
      window.location.reload();
    }
  };

  const getScreenWidth = () => {
    return window.innerWidth;
  };

  window.addEventListener("resize", function () {
    getScreenWidth();
  });

  const checkIfiPhone = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // alert("You are using an iPhone!");
    } else {
      setTimeout(() => {
        setShowNoti(true);
      }, 10 * 1000);
    }
  };

  window.onload = checkIfiPhone;

  return (
    <React.Fragment>
      <nav
        className="navigation d-flex flex-column text-center navbar navbar-light hide-scrollbar"
        style={getScreenWidth() <= 1199 ? style : {}}
      >
        {showNoti && Notification?.permission === "default" ? (
          <Modal show={showNoti} backdrop="static" centered>
            <Modal.Header>
              <Modal.Title style={{ textTransform: "capitalize" }}>
                Set notifications for calls and messages
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>
              <Button
                variant="primary"
                onClick={() => {
                  setShowNoti(false);
                  Notification?.requestPermission()
                    .then(function (permission) {})
                    .catch((err) => {});
                }}
              >
                Set Notifications
              </Button>
            </Modal.Body>
          </Modal>
        ) : null}
        <div className="nav-logo-div">
          <a
            onClick={() => handleNavigate("/")}
            style={{
              cursor: "pointer",
              display: "flex",
              position: "relative",
            }}
            className="onLargerScreen"
          >
            <img
              onClick={() => handleNavigatorTabs(NAVIGATOR?.NOTIFICATIONS)}
              id="tab-dashboard"
              href="#tab-content-dashboard"
              title="Dashboard"
              data-bs-toggle="tab"
              role="tab"
              src={appSettings?.logo ? appSettings?.logo : Logo}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                borderRadius: 5,
                transform: "Scale(0.9)",
              }}
              alt="logo"
            />
            {allNotifications?.filter((x) => {
              return x?.is_read === false;
            }).length > 0 && (
              <div
                className="badge badge-circle bg-danger"
                style={{
                  position: "absolute",
                  transform: "translate(-5px, -5px)",
                }}
              >
                <span>
                  {
                    allNotifications?.filter((x) => {
                      return x?.is_read === false;
                    }).length
                  }
                </span>
              </div>
            )}
          </a>
        </div>
        <ul className="d-flex nav navbar-nav flex-row flex-xl-column flex-grow-1 justify-content-between justify-content-xl-center align-items-center w-100 py-4 py-lg-2 px-lg-3">
          <li className="nav-item d-none d-xl-block invisible flex-xl-grow-1">
            <a className="nav-link py-0 py-lg-8" href="#" title="">
              <div className="icon icon-xl">
                <i className="fal fa-times fa-lg"></i>
              </div>
            </a>
          </li>
          {[USER_ROLES.CLIENT, USER_ROLES.GUEST].includes(
            userData?.data?.user_role
          ) && (
            <React.Fragment>
              <li
                className="nav-item"
                onClick={() => handleNavigatorTabs(NAVIGATOR?.GROUP_CHATS)}
              >
                <a
                  className={`nav-link py-0 py-lg-8 active`}
                  id="tab-chats"
                  href="#tab-content-chats"
                  title="Group Chats"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  <div className="icon icon-xl icon-badged">
                    <i className="fal fa-comments-alt fa-lg"></i>
                    <div className="badge badge-circle bg-primary">
                      <span>{allGroups?.length}</span>
                    </div>
                  </div>
                </a>
              </li>
              <li className="nav-item d-none d-xl-block invisible flex-xl-grow-1">
                <a className="nav-link py-0 py-lg-8" href="#" title="">
                  <div className="icon icon-xl">
                    <i className="fal fa-times fa-lg"></i>
                  </div>
                </a>
              </li>
              <li className="nav-item d-none d-xl-block invisible flex-xl-grow-1">
                <a className="nav-link py-0 py-lg-8" href="#" title="">
                  <div className="icon icon-xl">
                    <i className="fal fa-times fa-lg"></i>
                  </div>
                </a>
              </li>
              <li className="nav-item" style={{ marginBottom: 20 }}>
                <a
                  className="switcher-btn text-reset"
                  style={{ cursor: "pointer" }}
                  title="Themes"
                >
                  <i
                    style={{ fontSize: "18px" }}
                    className={`fal fa-lg ${
                      theme === "dark" ? "fa-sun" : "fa-moon"
                    }`}
                    onClick={() =>
                      handleTheme(theme === "dark" ? "light" : "dark")
                    }
                  />
                </a>
              </li>
            </React.Fragment>
          )}
          {[
            USER_ROLES.SUPER_OPERATOR,
            USER_ROLES.OPERATOR,
            USER_ROLES.JUNIOR_OPERATOR,
            USER_ROLES.EDITOR,
          ]?.includes(userData?.data?.user_role) && (
            <React.Fragment>
              {userData?.data?.user_role !== USER_ROLES?.EDITOR && (
                <React.Fragment>
                  <li
                    className="onSmallScreen nav-item"
                    onClick={() =>
                      handleNavigatorTabs(NAVIGATOR?.NOTIFICATIONS)
                    }
                  >
                    <a
                      className={`nav-link py-0 py-lg-8 ${
                        activeTab === NAVIGATOR?.NOTIFICATIONS && "active"
                      }`}
                      id="tab-dashboard"
                      href="#tab-content-dashboard"
                      title="Dashboard"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <div className="icon icon-xl icon-badged">
                        <i className="fal fa-home fa-lg"></i>
                        {allNotifications?.filter((x) => {
                          return x?.is_read === false;
                        }).length > 0 && (
                          <div className="badge badge-circle bg-danger">
                            <span>
                              {
                                allNotifications?.filter((x) => {
                                  return x?.is_read === false;
                                }).length
                              }
                            </span>
                          </div>
                        )}
                      </div>
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => handleNavigatorTabs(NAVIGATOR?.WEB_CHATS)}
                  >
                    <a
                      className={`nav-link py-0 py-lg-8 ${
                        activeTab === NAVIGATOR?.WEB_CHATS && "active"
                      }`}
                      id="tab-web-chats"
                      href="#tab-content-web-chats"
                      title="Web Chats"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <div className="icon icon-xl icon-badged">
                        <i className="fal fa-comment-alt-dots fa-lg"></i>
                        {allConversations?.filter((x) => {
                          return !x?.is_read;
                        })?.length > 0 && (
                          <div
                            className="badge badge-circle"
                            style={{ backgroundColor: "orange" }}
                          >
                            <span>
                              {
                                allConversations?.filter((x) => {
                                  return !x?.is_read;
                                })?.length
                              }
                            </span>
                          </div>
                        )}
                      </div>
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => handleNavigatorTabs(NAVIGATOR?.GROUP_CHATS)}
                  >
                    <a
                      className={`nav-link py-0 py-lg-8 ${
                        activeTab === NAVIGATOR?.GROUP_CHATS && "active"
                      }`}
                      id="tab-chats"
                      href="#tab-content-chats"
                      title="Group Chats"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <div className="icon icon-xl icon-badged">
                        <i className="fal fa-comments-alt fa-lg"></i>
                        <div className="badge badge-circle bg-primary">
                          {allGroups?.filter((group) => {
                            return group?.unread_messages?.some((msg) =>
                              msg?.users?.includes(userData?.data?._id)
                            );
                          })?.length > 0 && (
                            <span>
                              {
                                allGroups?.filter((group) => {
                                  return group?.unread_messages?.some((msg) =>
                                    msg?.users?.includes(userData?.data?._id)
                                  );
                                })?.length
                              }
                            </span>
                          )}
                        </div>
                      </div>
                    </a>
                  </li>
                  {[USER_ROLES?.SUPER_OPERATOR, USER_ROLES?.OPERATOR]?.includes(
                    userData?.data?.user_role
                  ) && (
                    <li
                      className="nav-item"
                      onClick={() =>
                        handleNavigatorTabs(NAVIGATOR?.FILTER_CHATS)
                      }
                    >
                      <a
                        className={`nav-link py-0 py-lg-8 ${
                          activeTab === NAVIGATOR?.FILTER_CHATS && "active"
                        }`}
                        id="tab-filter-chats"
                        href="#tab-filter-chats"
                        title="Filter Chats"
                        data-bs-toggle="tab"
                        role="tab"
                      >
                        <div className="icon icon-xl icon-badged">
                          <i className="fal fa-filter fa-lg"></i>
                          <div className="badge badge-circle bg-info"></div>
                        </div>
                      </a>
                    </li>
                  )}
                  <li
                    className="nav-item"
                    onClick={() => handleNavigatorTabs(NAVIGATOR?.CUSTOMERS)}
                  >
                    <a
                      className={`nav-link py-0 py-lg-8 ${
                        activeTab === NAVIGATOR?.CUSTOMERS && "active"
                      }`}
                      id="tab-customers"
                      href="#tab-content-customers"
                      title="Customers"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      <div className="icon icon-xl">
                        <i className="fal fa-users fa-lg"></i>
                      </div>
                    </a>
                  </li>
                </React.Fragment>
              )}
              <li
                className="nav-item d-none d-xl-block flex-xl-grow-1"
                onClick={() =>
                  handleNavigatorTabs(NAVIGATOR?.STANDARD_RESPONSES)
                }
              >
                <a
                  className={`nav-link py-0 py-lg-8 ${
                    activeTab === NAVIGATOR?.STANDARD_RESPONSES && "active"
                  }`}
                  id="tab-profile"
                  href="#tab-content-responses"
                  title="Standard Responses"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  <div className="icon icon-xl">
                    <i className="fal fa-sticky-note fa-lg"></i>
                  </div>
                </a>
              </li>
              <li
                className="nav-item d-none d-xl-block flex-xl-grow-1"
                onClick={() => handleNavigatorTabs(NAVIGATOR?.BOTS)}
              >
                <a
                  className={`nav-link py-0 py-lg-8 ${
                    activeTab === NAVIGATOR?.BOTS && "active"
                  }`}
                  id="tab-profile"
                  href="#tab-content-bot"
                  title="Chat Bot"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  <div className="icon icon-xl">
                    <i className="fal fa-user-robot fa-lg"></i>
                  </div>
                </a>
              </li>
              <li
                className="nav-item d-none d-xl-block flex-xl-grow-1"
                onClick={() => handleNavigatorTabs(NAVIGATOR?.FAQS)}
              >
                <a
                  className={`nav-link py-0 py-lg-8 ${
                    activeTab === NAVIGATOR?.FAQS && "active"
                  }`}
                  id="tab-profile"
                  href="#tab-content-faq"
                  title="FAQ"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  <div className="icon icon-xl">
                    <i className="fal fa-question-square fa-lg"></i>
                  </div>
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => handleNavigatorTabs(NAVIGATOR?.SETTINGS)}
              >
                <a
                  className={`nav-link py-0 py-lg-8 ${
                    activeTab === NAVIGATOR?.SETTINGS && "active"
                  }`}
                  id="tab-settings"
                  href="#tab-content-settings"
                  title="Settings"
                  data-bs-toggle="tab"
                  role="tab"
                >
                  <div className="icon icon-xl">
                    <i className="fal fa-cog fa-lg"></i>
                  </div>
                </a>
              </li>
              {[USER_ROLES?.SUPER_OPERATOR, USER_ROLES?.OPERATOR]?.includes(
                userData?.data?.user_role
              ) && (
                <li
                  className="nav-item"
                  onClick={() => handleNavigatorTabs(NAVIGATOR?.OPERATORS)}
                >
                  <a
                    className={`nav-link py-0 py-lg-8 ${
                      activeTab === NAVIGATOR?.OPERATORS && "active"
                    }`}
                    id="tab-profile"
                    href="#tab-content-profile"
                    title="Operators"
                    data-bs-toggle="tab"
                    role="tab"
                  >
                    <div className="icon icon-xl">
                      <i className="fal fa-user-headset fa-lg"></i>
                    </div>
                  </a>
                </li>
              )}
            </React.Fragment>
          )}
          <li className="nav-item d-none d-xl-block">
            <a
              href="#"
              className="nav-link p-0 mt-lg-2"
              data-bs-toggle="modal"
              data-bs-target="#modal-profile"
            >
              <div
                className={`avatar mx-auto ${
                  socket?.connected && "avatar-online"
                }`}
              >
                {userData?.data?.picture ? (
                  <img
                    className="avatar-img round_avatar"
                    src={userData?.data?.picture}
                    alt="#"
                  />
                ) : (
                  <span className="avatar-text">
                    {userData?.data?.name?.charAt(0).toUpperCase()}
                  </span>
                )}
              </div>
            </a>
          </li>
        </ul>
      </nav>
      <ProfileModal />
    </React.Fragment>
  );
};
export default memo(Navigator);
