import React from "react";
import { useSelector } from "react-redux";
import CardHeader from "../../assets/img/core/card-header.png";
import { ToastContainer as Toaster } from "../toast";

const UserProfileModalGroup = () => {
  const { userId } = useSelector((state) => state?.groupChatReducer);
  return (
    <div
      className="modal fade"
      id="modal-user-profile-group"
      tabIndex="-1"
      aria-labelledby="modal-user-profile-group"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-fullscreen-xl-down">
        <div className="modal-content">
          <div className="modal-body py-0">
            <div className="profile modal-gx-n">
              <div className="profile-img text-primary rounded-top-xl">
                <img src={CardHeader} width="100%" />

                <div className="position-absolute top-0 start-0 p-5">
                  <button
                    type="button"
                    className="btn-close btn-close-white btn-close-arrow opacity-100"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>

              <div className="profile-body">
                <div className="avatar avatar-xl">
                  {userId?.picture ? (
                    <img
                      className="avatar-img round_avatar"
                      src={userId?.picture}
                      alt="#"
                    />
                  ) : (
                    <span className="avatar-text">
                      {userId?.name?.charAt(0).toUpperCase()}
                    </span>
                  )}
                </div>

                <h4 className="mb-1">{userId?.name}</h4>
                <p>last seen 5 minutes ago</p>
                <p>
                  <i className="fal fa-star"></i>
                  <i className="fal fa-star"></i>
                  <i className="fal fa-star"></i>
                  <i className="fal fa-star"></i>
                  <i className="fal fa-star-half-alt"></i>
                </p>
              </div>
            </div>

            <hr className="hr-bold modal-gx-n my-0" />

            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>Location</h5>
                    <p>USA, Houston</p>
                  </div>

                  <div className="col-auto">
                    <div className="btn btn-sm btn-icon btn-dark">
                      <i className="fal fa-globe"></i>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>E-mail</h5>
                    <p>{userId?.email}</p>
                  </div>

                  <div className="col-auto">
                    <div className="btn btn-sm btn-icon btn-dark">
                      <i className="fal fa-envelope"></i>
                    </div>
                  </div>
                </div>
              </li>

              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>Phone</h5>
                    <p>{userId?.phone}</p>
                  </div>

                  <div className="col-auto">
                    <div className="btn btn-sm btn-icon btn-dark">
                      <i className="fal fa-headset"></i>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <hr className="hr-bold modal-gx-n my-0" />

            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <div className="row align-items-center gx-6">
                  <div className="col">
                    <h5>Notifications</h5>
                    <p>Enable sound notifications</p>
                  </div>

                  <div className="col-auto">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="user-notification-check"
                        onClick={() =>
                          Toaster({
                            success: false,
                            message: "Under Construction",
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="user-notification-check"
                      ></label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <hr className="hr-bold modal-gx-n my-0" />

            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <a href="#" className="text-reset">
                  Send Message
                </a>
              </li>

              <li className="list-group-item">
                <a
                  href="#"
                  className="text-danger"
                  onClick={() =>
                    Toaster({
                      success: false,
                      message: "Under Construction",
                    })
                  }
                >
                  Block User
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserProfileModalGroup;
