import React from "react";
import {
  ChatWidgetModal,
  GroupChatModal,
  BotModal,
  DepartmentModal,
  EngageModal,
  FaqModal,
  ProfileModal,
  ResponseModal,
  RatingModal,
  ClientModal,
  PreviewModal,
  OperatorModal,
  TransferModal,
  UserProfileModal,
  UserProfileModalGroup,
} from "../../components/modal";

const AllModals = ({ faqModalRef = null, setModalShow, modalShow }) => {
  return (
    <React.Fragment>
      <ChatWidgetModal />
      <GroupChatModal setModalShow={setModalShow} modalShow={modalShow} />
      <BotModal />
      <DepartmentModal />
      <EngageModal />
      <FaqModal faqModalRef={faqModalRef} />
      <ProfileModal />
      <ResponseModal />
      <RatingModal />
      <ClientModal />
      <PreviewModal />
      <OperatorModal />
      <TransferModal />
      <UserProfileModal />
      <UserProfileModalGroup />
    </React.Fragment>
  );
};

export default AllModals;
