import { BASE_URL } from "./axios-instance";

// END_POINTS
const urls = {
  login: `/user/login`,
  signup: `/user/userSignup`,
  auth: `/user/auth`,
  allUsers: `/user/allUsers`,
  updateUserById: `/user/updateUserById`,
  profile_upload: `/user/upload`,
};

// GETTING_FULL_URLS
export const getLoginUrl = () => {
  const basePath = urls.login;
  return `${BASE_URL}${basePath}`;
};

export const getSignupUrl = () => {
  const basePath = urls.signup;
  return `${BASE_URL}${basePath}`;
};

export const getAuthUrl = () => {
  const basePath = urls.auth;
  return `${BASE_URL}${basePath}`;
};

export const getALlUsersUrl = () => {
  const basePath = urls.allUsers;
  return `${BASE_URL}${basePath}`;
};

export const updateUserByIdUrl = (param) => {
  const basePath = urls.updateUserById;
  return `${BASE_URL}${basePath}/${param}`;
};

export const updateUserPhotoUrl = () => {
  const basePath = urls.profile_upload;
  return `${BASE_URL}${basePath}`;
};
