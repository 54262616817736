import { TYPES } from "../actionTypes";

const {
  GET_ALL_ENGAGE_ENTRY_LOADING,
  GET_ALL_ENGAGE_ENTRY_SUCCESS,
  GET_ALL_ENGAGE_ENTRY_FAIL,
  UPDATE_ENGAGE_ENTRY,
  UPDATE_ENGAGE_ENTRY_DETAILS,
  UPDATE_DELETED_ENTRY_DETAILS,
  SET_ENGAGE_ID,
  USER_LOGOUT,
} = TYPES;

const initialState = {
  loading: false,
  allEngageEntries: [],
  engageObj: {},
  error: false,
};

export default function engageEntryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ENGAGE_ENTRY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ENGAGE_ENTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        allEngageEntries: action.payload,
      };
    case GET_ALL_ENGAGE_ENTRY_FAIL:
      return {
        ...state,
        loading: false,
        allEngageEntries: action?.payload,
        error: true,
      };
    case UPDATE_ENGAGE_ENTRY:
      state?.allEngageEntries.unshift(action.payload);
      return {
        ...state,
        loading: false,
        allEngageEntries: state.allEngageEntries,
      };
    case UPDATE_ENGAGE_ENTRY_DETAILS:
      let foundIndex = state?.allEngageEntries?.findIndex((x) => {
        return x?._id === action.payload?._id;
      });
      state.allEngageEntries[foundIndex] = action.payload;
      return {
        ...state,
        loading: false,
        allEngageEntries: state.allEngageEntries,
      };
    case UPDATE_DELETED_ENTRY_DETAILS:
      let newArray = state?.allEngageEntries.filter((x) => {
        return x?._id !== action.payload?._id;
      });
      return {
        ...state,
        loading: false,
        allEngageEntries: newArray,
      };
    case SET_ENGAGE_ID:
      return {
        ...state,
        loading: false,
        engageObj: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        allEngageEntries: [],
        engageObj: {},
        error: false,
      };
    default:
      return state;
  }
}
