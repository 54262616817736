import { TYPES } from "../actionTypes";

const {
  GET_ALL_RESPONSE_LOADING,
  GET_ALL_RESPONSE_SUCCESS,
  GET_ALL_RESPONSE_FAIL,
  UPDATE_ALL_RESPONSE,
  UPDATE_RESPONSE_STATUS,
  DELETE_RESPONSE,
  SET_RESPONSE_ID,
  USER_LOGOUT,
} = TYPES;

const initialState = {
  loading: false,
  allResponse: [],
  responseObj: {},
  error: false,
};

export default function responseReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_RESPONSE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        allResponse: action.payload,
      };
    case UPDATE_ALL_RESPONSE:
      state.allResponse.unshift(action.payload);
      return {
        ...state,
        loading: false,
        allResponse: state.allResponse,
      };
    case UPDATE_RESPONSE_STATUS:
      let foundIndex = state?.allResponse?.findIndex((x) => {
        return x?._id === action.payload._id;
      });
      state.allResponse[foundIndex] = action.payload;
      return {
        ...state,
        loading: false,
        allResponse: state.allResponse,
      };
    case DELETE_RESPONSE:
      let newArray = [];
      newArray = state?.allResponse?.filter((x) => {
        return x?._id !== action?.payload?._id;
      });
      return {
        ...state,
        allResponse: newArray,
      };
    case GET_ALL_RESPONSE_FAIL:
      return {
        ...state,
        loading: false,
        allResponse: [],
        error: true,
      };
    case SET_RESPONSE_ID:
      return {
        ...state,
        responseObj: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        allResponse: [],
        responseObj: {},
        error: false,
      };
    default:
      return state;
  }
}
