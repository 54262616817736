/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { useSelector } from "react-redux";

const Filter = ({ handleChatFilters }) => {
  const { allDepartments } = useSelector((state) => state.departmentReducer);
  const { allOperators } = useSelector((state) => state.operatorReducer);

  const sliderValue = (range) => {
    switch (range) {
      case "1":
        handleChatFilters("date", 1);
        break;
      case "2":
        handleChatFilters("date", 3);
        break;
      case "3":
        handleChatFilters("date", 7);
        break;
      case "4":
        handleChatFilters("date", 30);
        break;
      default:
        break;
    }
  };
  return (
    <div className="mt-8 mb-6">
      <div className="d-flex align-items-center my-4 px-6">
        <small className="text-muted me-auto">Filter</small>

        <div
          style={{ cursor: "pointer" }}
          className="flex align-items-center text-muted"
          onClick={() => handleChatFilters("clear")}
        >
          <a href="#" className="text-muted small">
            Clear filter
          </a>{" "}
          <div className="icon icon-xs">
            <i className="fal fa-filter"></i>
          </div>
        </div>
      </div>

      <div className="card border-0">
        <div className="card-body py-2">
          <div className="accordion accordion-flush" id="chat-filter">
            <div className="accordion-item">
              <div className="accordion-header" id="chat-filter-1">
                <a
                  href="#"
                  className="accordion-button text-reset collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#chat-filter-body-1"
                  aria-expanded="false"
                  aria-controls="chat-filter-body-1"
                >
                  <div>
                    <h5>Filter Chats</h5>
                    <p>Set filters for web chats.</p>
                  </div>
                </a>
              </div>

              <div
                id="chat-filter-body-1"
                className="accordion-collapse collapse"
                aria-labelledby="chat-filter-1"
                data-parent="#chat-filter-1"
              >
                <div className="accordion-body">
                  {/* Days Range Filter */}
                  <div className="row justify-content-between mb-6">
                    <div className="col-auto">
                      <small>Today</small>
                    </div>
                    <div className="col-auto">
                      <small>3 Days</small>
                    </div>
                    <div className="col-auto">
                      <small>7 Days</small>
                    </div>
                    <div className="col-auto">
                      <small>30 Days</small>
                    </div>
                  </div>
                  <input
                    type="range"
                    className="form-range"
                    min="1"
                    max="4"
                    step="1"
                    defaultValue={1}
                    id="custom-range-1"
                    onChange={(e) => sliderValue(e.target.value)}
                  />

                  {/* Operator Filter */}
                  <div className="form-floating mb-6">
                    <select
                      className="form-select"
                      id="operator"
                      onChange={(e) =>
                        handleChatFilters("receiverId", e.target.value)
                      }
                    >
                      {allOperators?.map((operator) => (
                        <option value={operator?._id}>{operator?.name}</option>
                      ))}
                    </select>
                    <label htmlFor="department">Operator</label>
                  </div>

                  {/* Departments Filter */}
                  <div className="form-floating mb-6">
                    <select
                      className="form-select"
                      id="department"
                      onChange={(e) =>
                        handleChatFilters("department", e.target.value)
                      }
                    >
                      {allDepartments?.map((department) => (
                        <option value={department?._id}>
                          {department?.title}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="department">Department</label>
                  </div>

                  {/* Language Filter */}
                  <div className="form-floating mb-6">
                    <select
                      className="form-select"
                      id="language"
                      // onChange={(e) =>
                      //   handleChatFilters("language", e.target.value)
                      // }
                    >
                      <option value="en" defaultValue>
                        EN
                      </option>
                      <option value="de">DE</option>
                      <option value="es">ES</option>
                    </select>
                    <label htmlFor="language">Language</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Filter);
