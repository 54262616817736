import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { TYPES } from "../../redux/actionTypes";
import { ToastContainer as Toaster } from "../";
import { deleteMessage } from "../../redux/actions/chatAction";
import { deleteMsgForEveryone } from "../../redux/actions/groupChatAction";
import { FILE_TYPES } from "../../constant";

const MediaPreviewModal = () => {
  const [deleteMedia, setDeletaMedia] = useState(false);

  const dispatch = useDispatch();

  const { imagePreview } = useSelector((state) => state.chatReducer);

  const handleImageActions = async (purpose) => {
    switch (purpose) {
      case "download":
        DownloadFile(imagePreview?.imageData?.imgUrl);
        break;
      case "share":
        dispatch({
          type: TYPES.SHARE_FILE_DATA,
          payload: {
            type: FILE_TYPES?.IMAGES,
            data: imagePreview?.imageData,
          },
        });
        dispatch({
          type: TYPES.IS_SHARE_FILE_MODAL,
          payload: true,
        });
        break;
      case "delete":
        let res = {};
        if (imagePreview?.purpose === "conversation") {
          res = await dispatch(
            deleteMessage(imagePreview?.imageData?._id, imagePreview?.purpose)
          );
        } else if (imagePreview?.purpose === "group_conversation") {
          res = await dispatch(
            deleteMsgForEveryone(
              imagePreview?.imageData?._id,
              imagePreview?.purpose
            )
          );
        }
        if (res?.success) {
          setDeletaMedia(res?.data);
          Toaster(res);
        }
        break;
    }
  };
  const DownloadFile = (link) => {
    fetch(link)
      .then((res) => res.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = link.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName?.replaceAll("%20", " "));
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };
  return (
    <div
      className="modal fade"
      id="modal-media-preview"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-centered modal-fullscreen-xl-down">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close btn-close-arrow"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>

            <div>
              <div className="dropdown">
                <a
                  className="icon text-muted"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fal fa-ellipsis-v"></i>
                </a>

                <ul className="dropdown-menu">
                  <li>
                    <button
                      onClick={() => handleImageActions("download")}
                      className="dropdown-item d-flex align-items-center"
                    >
                      Download
                      <div className="icon ms-auto">
                        <i className="fal fa-cloud-download"></i>
                      </div>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleImageActions("share")}
                      className="dropdown-item d-flex align-items-center"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Share
                      <div className="icon ms-auto">
                        <i className="fal fa-share-alt"></i>
                      </div>
                    </button>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <button
                      onClick={() => handleImageActions("delete")}
                      className="dropdown-item d-flex align-items-center text-danger"
                    >
                      <span className="me-auto">Delete</span>
                      <div className="icon">
                        <i className="fal fa-trash-alt"></i>
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="modal-body p-0">
            <div className="d-flex align-items-center justify-content-center h-100">
              {deleteMedia?._id === imagePreview?.imageData?._id &&
              deleteMedia?.is_delete ? (
                <p style={{ margin: "50px 0px" }}>
                  This file is no longer exists
                </p>
              ) : (
                <img
                  className="img-fluid modal-preview-url"
                  src={imagePreview?.imageData?.imgUrl}
                  alt="#"
                  data-bs-dismiss="modal"
                />
              )}
            </div>
          </div>
          <div className="modal-footer">
            <div className="w-100 text-center">
              <h6>
                <a href="#">{imagePreview?.imageData?.sender}</a>
              </h6>
              <p className="small">
                {moment(imagePreview?.imageData?.date).calendar()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(MediaPreviewModal);
