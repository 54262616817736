import React from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";

const FileModal = ({
  setFileModal,
  fileModal,
  document,
  setDocument,
  handleRemoveFile,
  handleSendFiles,
  fileShareLoading,
}) => {
  const { percentage } = useSelector((state) => state?.groupChatReducer);

  const handleCloseModal = () => {
    setFileModal(false);
    setDocument([]);
  };

  return (
    <React.Fragment>
      <Modal
        show={fileModal}
        onHide={fileModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          className="hide-scrollbar"
          style={{
            padding: "30px",
            borderRadius: "0.6rem",
            maxHeight: "400px",
            overflow: "scroll",
          }}
        >
          {document?.map((x, index) => (
            <React.Fragment>
              <div
                className="d-grid"
                style={{
                  margin: "10px 0px",
                  borderRadius: "0.6rem",
                  padding: "10px",
                  backgroundColor: "#2787F5",
                  color: "white",
                }}
              >
                <div className="col overflow-hidden d-flex align-items-center justify-content-between">
                  <div className="d-grid">
                    <h6 className="text-truncate text-reset">
                      <a href="#" className="text-reset">
                        {x?.name}
                      </a>
                    </h6>
                    <small>{(x?.size / (1024 * 1024))?.toFixed(1)} MB</small>
                  </div>
                  <i
                    className="fal fa-trash"
                    onClick={
                      !fileShareLoading ? () => handleRemoveFile(index) : ""
                    }
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      color: "white",
                    }}
                  />
                </div>
                <ProgressBar
                  animated
                  now={percentage}
                  variant="success"
                  style={{
                    backgroundColor: "rgba(255,255,255,0.5)",
                  }}
                />
              </div>
            </React.Fragment>
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <button
              onClick={!fileShareLoading ? handleCloseModal : ""}
              className="btn btn-lg btn-outline-danger d-flex align-items-center"
              style={{
                width: "150px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Cancel
            </button>
            <button
              onClick={handleSendFiles}
              className="btn btn-lg btn-primary d-flex align-items-center"
              style={{
                width: "150px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {fileShareLoading ? (
                <div
                  className="spinner-grow text-light"
                  role="status"
                  style={{
                    backgroundColor: "white",
                    width: "15px",
                    height: "15px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Send"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default FileModal;
