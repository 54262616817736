import { axiosClient } from "../../utils";
import { TYPES } from "../actionTypes";

const {
  GET_ALL_EMAIL_TEMPLATES_LOADING,
  GET_ALL_EMAIL_TEMPLATES_SUCCESS,
  GET_ALL_EMAIL_TEMPLATES_FAIL,
  CREATE_NEW_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_SUCCESS,
} = TYPES;

export const getAllEmailTemplates = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_EMAIL_TEMPLATES_LOADING,
  });
  return await axiosClient()
    .get("/emailTemplate")
    .then((res) => {
      dispatch({
        type: GET_ALL_EMAIL_TEMPLATES_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_EMAIL_TEMPLATES_FAIL,
      });
      return error?.response?.data;
    });
};

export const createEmailTemplate = (payload) => async (dispatch) => {
  return await axiosClient()
    .post("/emailTemplate", payload)
    .then((res) => {
      dispatch({
        type: CREATE_NEW_TEMPLATE_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((error) => {
      return error?.response?.data;
    });
};

export const editEmailTemplate = (templateId, payload) => async (dispatch) => {
  return await axiosClient()
    .patch(`/emailTemplate/${templateId}`, payload)
    .then((res) => {
      dispatch({
        type: UPDATE_TEMPLATE_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((error) => {
      return error?.response?.data;
    });
};

export const deleteEmailTemplate = (templateId) => async (dispatch) => {
  return await axiosClient()
    .delete(`/emailTemplate/${templateId}`)
    .then((res) => {
      dispatch({
        type: DELETE_TEMPLATE_SUCCESS,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((error) => {
      return error?.response?.data;
    });
};
