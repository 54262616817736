import { TYPES } from "../actionTypes";

const { IS_SHARE_FILE_MODAL, USER_LOGOUT, SHARE_FILE_DATA } = TYPES;

const initialState = {
  is_share_file_modal: false,
  shareFileData: {},
};

export default function shareFileReducer(state = initialState, action) {
  switch (action.type) {
    case IS_SHARE_FILE_MODAL:
      return {
        ...state,
        is_share_file_modal: action.payload,
      };
    case SHARE_FILE_DATA:
      return {
        ...state,
        shareFileData: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        is_share_file_modal: false,
        shareFileData: {},
      };
    default:
      return state;
  }
}
