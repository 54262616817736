import { TYPES } from '../actionTypes';
import { axiosClient } from '../../utils';
///////////////////////////////////////////////////////////////////////
const {
  GET_ALL_FAQ_LOADING,
  GET_ALL_FAQ_SUCCESS,
  GET_ALL_FAQ_FAIL,
  SET_FAQ_ID,
  UPDATE_ALL_FAQ,
  DELETE_FAQ_BY_ID,
  UPDATE_FAQ_STATUS,
} = TYPES;
///////////////////////////////////////////////////////////////////////
export const GetAllFaqLoading = () => ({
  type: GET_ALL_FAQ_LOADING,
});
export const GetAllFaqSuccess = (data) => ({
  type: GET_ALL_FAQ_SUCCESS,
  payload: data,
});
export const GetAllFaqFail = (err) => ({
  type: GET_ALL_FAQ_FAIL,
  payload: err,
});
////////////////////////
export const setFaqIdAction = (data) => ({
  type: SET_FAQ_ID,
  payload: data,
});
///////////////////////////////////////////////////////////////////////
export const addFaq = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`/faq/createFaq`, payload)
    .then((res) => {
      dispatch(UpdateAllFaqs(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////
export const getAllFaq = () => async (dispatch) => {
  dispatch(GetAllFaqLoading());
  return axiosClient()
    .get(`/faq/allFaq`)
    .then((res) => {
      dispatch(GetAllFaqSuccess(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      dispatch(GetAllFaqFail(err?.response?.data?.data));
      return err?.response;
    });
};
////////////////////////
export const deleteFaqById = (FaqId) => async (dispatch) => {
  return axiosClient()
    .delete(`/faq/deleteFaq/${FaqId}`)
    .then((res) => {
      dispatch(DeleteFaqById(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////
export const setFaqId = (FaqId, purpose) => async (dispatch) => {
  let data = {};
  if (purpose === 'addNew') {
    data = {
      purpose: purpose,
    };
  } else if (purpose === 'edit') {
    data = {
      faqId: FaqId,
      purpose: purpose,
    };
  }
  dispatch(setFaqIdAction(data));
};
////////////////////////
export const editFaq = (FaqId, payload) => async (dispatch) => {
  return axiosClient()
    .put(`/faq/editArticle/${FaqId}`, payload)
    .then((res) => {
      dispatch(UpdateFaqStatus(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
///////////////////////////////////////////////////////////////////////
export const UpdateAllFaqs = (data) => ({
  type: UPDATE_ALL_FAQ,
  payload: data,
});
export const DeleteFaqById = (data) => ({
  type: DELETE_FAQ_BY_ID,
  payload: data,
});
export const UpdateFaqStatus = (data) => ({
  type: UPDATE_FAQ_STATUS,
  payload: data,
});
