import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { images } from "../utils/fileTypes";

const ImageSelector = ({ setDocument, setFileType, selectedFileTypes }) => {
  let commonFiles =
    selectedFileTypes?.filter((file) => {
      return images?.includes(file);
    }) ?? [];
  const getFileTypes = () => {
    let filesObj = {};

    for (let index = 0; index < commonFiles?.length; index++) {
      filesObj[`image/${commonFiles[index]}`] = [`.${commonFiles[index]}`];
    }

    return filesObj;
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: getFileTypes(),
  });

  useEffect(() => {
    if (acceptedFiles?.length > 0) setDocument(acceptedFiles);
  }, [acceptedFiles]);
  return (
    <React.Fragment>
      <div className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} hidden />
        </div>
      </div>
      {commonFiles?.length ? (
        <li onClick={() => setFileType("images")}>
          <label
            style={{ cursor: "pointer" }}
            className="dropdown-item d-flex align-items-center"
            onClick={open}
          >
            Images
            <div className="icon ms-auto">
              <i className="fal fa-image"></i>
            </div>
          </label>
        </li>
      ) : null}
    </React.Fragment>
  );
};
export default ImageSelector;
