import { TYPES } from "../actionTypes";
//////////////////////////////////////////////
const { CURRENT_SESSION, USER_LOGOUT } = TYPES;
//////////////////////////////////////////////
const initialState = {
  session: {},
};
//////////////////////////////////////////////
export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case CURRENT_SESSION:
      return {
        ...state,
        session: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        session: {},
      };
    default:
      return state;
  }
}
