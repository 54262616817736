import React, { memo } from "react";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../constant";
import { Spinner } from "react-bootstrap";

const MessageDropdown = ({
  senderId,
  handleMessage,
  messageId,
  messageObj,
  deleteMessageLoading,
}) => {
  const { userData } = useSelector((state) => state?.userReducer);

  return (
    <div className="dropdown" style={{ marginLeft: 10 }}>
      {deleteMessageLoading?.loading &&
      deleteMessageLoading?._id === messageId ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <a
          className="icon text-muted"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fal fa-ellipsis-v" />
        </a>
      )}

      <ul className="dropdown-menu">
        {senderId === userData?.data?._id &&
          (messageObj?.type === "message" || messageObj?.type === "reply") && (
            <li>
              <button
                className="dropdown-item d-flex align-items-center"
                onClick={() => handleMessage("edit", messageId)}
              >
                <span className="me-auto">Edit</span>
                <div className="icon">
                  <i className="fal fa-edit"></i>
                </div>
              </button>
            </li>
          )}
        <li>
          <button
            className="dropdown-item d-flex align-items-center"
            onClick={() => handleMessage("reply", messageObj)}
          >
            <span className="me-auto">Reply</span>
            <div className="icon">
              <i className="fal fa-reply"></i>
            </div>
          </button>
        </li>
        {userData?.data?.user_role === USER_ROLES?.SUPER_OPERATOR ||
        userData?.data?.user_role === USER_ROLES?.OPERATOR ||
        userData?.data?.user_role === USER_ROLES?.JUNIOR_OPERATOR ||
        userData?.data?._id === senderId ? (
          <React.Fragment>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button
                className="dropdown-item d-flex align-items-center text-danger"
                onClick={() => handleMessage("delete", messageId)}
              >
                <span className="me-auto">Delete</span>
                <div className="icon" style={{ marginLeft: "10px" }}>
                  <i className="fal fa-trash-alt"></i>
                </div>
              </button>
            </li>
          </React.Fragment>
        ) : (
          ""
        )}
      </ul>
    </div>
  );
};
export default memo(MessageDropdown);
