import axios from "axios";
import Cookies from "js-cookie";

export const BASE_URL = window?.location?.host?.includes("localhost")
  ? "http://localhost:9009"
  : "https://zipp-chat-cug3c.ondigitalocean.app";

// export const BASE_URL = "https://zippchat-app-be-n76hk.ondigitalocean.app";

export function axiosClient() {
  let defaultOptions = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      accept: "application/json",
    },
  };
  let instance = axios.create(defaultOptions);
  instance.interceptors.request.use((config) => {
    config.headers.common = { "x-auth-token": `${Cookies.get("accessToken")}` };
    return config;
  });
  return instance;
}
export default axiosClient;
