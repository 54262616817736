import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FILE_TYPES } from "../../constant";
import { TYPES } from "../../redux/actionTypes";
import { useSelector, useDispatch } from "react-redux";
import { forwardMessage } from "../../redux/actions/chatAction";
import { Search, ToastContainer as Toaster } from "../../components";

const ShareFile = () => {
  const [search, setSearch] = useState("");
  const [allChats, setAllChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedChats, setSelectedChats] = useState([]);

  const dispatch = useDispatch();

  const { socket } = useSelector((state) => state.socketReducer);
  const { userData } = useSelector((state) => state.userReducer);
  const { allGroups } = useSelector((state) => state.groupChatReducer);
  const { allConversations } = useSelector((state) => state.chatReducer);
  const { is_share_file_modal, shareFileData } = useSelector(
    (state) => state.shareFileReducer
  );

  useEffect(() => {
    // Initializing arrays
    let allGroupChats = [];
    let allSimpleChats = [];

    // Getting All Groups
    if (allGroups?.length > 0) {
      allGroups?.forEach((group) => {
        allGroupChats?.push({
          type: "group_conversation",
          data: {
            conversation: {
              name: group?.group_title,
              picture: group?.group_image ? group?.group_image : null,
              _id: group?._id,
            },
            receiver: group?.group_members,
            messageType: shareFileData?.type,
            sharedContent: shareFileData?.data,
          },
        });
      });
    }

    // Getting All Conversations
    if (allConversations?.length > 0) {
      allConversations?.forEach((chat) => {
        let user = chat?.recipients?.find((user) => {
          return user?._id !== userData?.data?._id;
        });
        allSimpleChats?.push({
          type: "conversation",
          data: {
            conversation: {
              name: user?.name,
              picture: user?.picture ? user?.picture : null,
              _id: chat?._id,
            },
            receiver: [user],
            messageType: shareFileData?.type,
            sharedContent: shareFileData?.data,
          },
        });
      });
    }
    // Setting all chats in state
    setAllChats(allGroupChats?.concat(allSimpleChats));
  }, [allConversations, allGroups, userData, is_share_file_modal]);

  const closeModal = () => {
    dispatch({ type: TYPES.IS_SHARE_FILE_MODAL, payload: false });
    setSearch("");
    setLoading(false);
    setSelectedChats([]);
  };
  const sendFile = async () => {
    if (!loading) {
      setLoading(true);
      selectedChats?.map((item) => {
        if (item?.data?.sharedContent?.imgUrl)
          item.data.sharedContent.location = item?.data?.sharedContent?.imgUrl;
      });
      let res = await dispatch(forwardMessage(selectedChats));
      Toaster(res);
      if (res?.success) {
        socket.emit("forward_message", res?.data);
        dispatch({ type: TYPES.IS_SHARE_FILE_MODAL, payload: false });
        setSelectedChats([]);
      }
      setLoading(false);
    }
  };
  const handleShareChat = (is_added, conversationId) => {
    switch (is_added) {
      case true:
        setSelectedChats([...selectedChats, conversationId]);
        break;
      case false:
        setSelectedChats(
          selectedChats?.filter((chat) => {
            return (
              chat?.data?.conversation?._id !==
              conversationId?.data?.conversation?._id
            );
          })
        );
        break;
    }
  };

  const Image = () => {
    return (
      <React.Fragment>
        {shareFileData?.type === FILE_TYPES?.IMAGES && (
          <div
            style={{
              height: 200,
              width: 200,
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src={shareFileData?.data?.imgUrl}
              style={{ maxHeight: "98%", maxWidth: "98%" }}
            />
          </div>
        )}
      </React.Fragment>
    );
  };
  const Document = () => {
    return (
      <React.Fragment>
        {shareFileData?.type === FILE_TYPES?.FILES && (
          <div
            className="d-grid"
            style={{
              margin: "10px 0px",
              borderRadius: "0.6rem",
              padding: "10px 20px",
              backgroundColor: "#2787F5",
              color: "white",
            }}
          >
            <div className="col overflow-hidden d-flex align-items-center justify-content-between">
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ height: 32 }}
              >
                <h6 className="text-truncate text-reset">
                  <a href="#" className="text-reset">
                    {shareFileData?.data?.originalname}
                  </a>
                </h6>
                <p>
                  {(shareFileData?.data?.size / (1024 * 1024))?.toFixed(1)} MB
                </p>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };
  const Chat = ({ chat, handleShareChat }) => {
    return (
      <React.Fragment>
        <div
          className={`card border-0 mb-2 ${
            selectedChats?.find((item) => {
              return (
                item?.data?.conversation?._id === chat?.data?.conversation?._id
              );
            }) && "bg-secondary"
          }`}
        >
          <div className="card-body">
            <div className="row align-items-center gx-5">
              <div className="col-auto">
                <div className="avatar">
                  {chat?.data?.conversation?.picture ? (
                    <img
                      className="avatar-img round_avatar"
                      src={chat?.data?.conversation?.picture}
                      alt=""
                    />
                  ) : (
                    <span className="avatar-text">
                      {chat?.data?.conversation?.name?.charAt(0)?.toUpperCase()}
                    </span>
                  )}
                </div>
              </div>
              <div className="col">
                <h5 style={{ textTransform: "capitalize" }}>
                  {chat?.data?.conversation?.name}
                </h5>
                {chat?.type === "group_conversation" && (
                  <small className="text-warning">
                    Group Chat <i className="fal fa-comments-alt fa-md" />
                  </small>
                )}
              </div>
              <div className="col-auto">
                <div className="form-check">
                  <input
                    style={{
                      zIndex: 999999,
                      position: "relative",
                      backgroundColor: "#97acca",
                    }}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id={`id-member-${chat?.data?.conversation?._id}`}
                    onChange={(e) => handleShareChat(e.target.checked, chat)}
                    checked={selectedChats?.find((item) => {
                      return (
                        item?.data?.conversation?._id ===
                        chat?.data?.conversation?._id
                      );
                    })}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`id-member-${chat?.data?.conversation?._id}`}
                  ></label>
                </div>
              </div>
            </div>
            <label
              className="stretched-label"
              htmlFor={`id-member-${chat?.data?.conversation?._id}`}
            ></label>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const handleSearch = (value) => {
    setSearch(value);
  };

  let filteredChats = allChats?.filter((chat) => {
    return chat?.data?.conversation?.name
      ?.toLowerCase()
      ?.includes(search?.toLowerCase());
  });

  return (
    <React.Fragment>
      <Modal
        show={is_share_file_modal}
        onHide={closeModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          className="hide-scrollbar"
          style={{
            padding: "30px",
            borderRadius: "0.6rem",
          }}
        >
          {shareFileData?.type === FILE_TYPES?.IMAGES && <Image />}
          {shareFileData?.type === FILE_TYPES?.FILES && <Document />}
          <br />
          <Search placeholder="Search" handleSearch={handleSearch} />
          <br />
          <div
            className="hide-scrollbar"
            style={{
              maxHeight: 400,
              overflow: "scroll",
            }}
          >
            {filteredChats?.length > 0 ? (
              filteredChats?.map((chat, index) => (
                <Chat
                  chat={chat}
                  key={index}
                  handleShareChat={handleShareChat}
                />
              ))
            ) : (
              <p style={{ textAlign: "center", textTransform: "capitalize" }}>
                No chats found
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <button
              onClick={!loading && closeModal}
              className="btn btn-lg btn-outline-danger d-flex align-items-center"
              style={{
                width: "150px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Cancel
            </button>
            <button
              onClick={sendFile}
              disabled={!selectedChats?.length}
              className="btn btn-lg btn-primary d-flex align-items-center"
              style={{
                width: "150px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <div
                  className="spinner-grow text-light"
                  role="status"
                  style={{
                    backgroundColor: "white",
                    width: "15px",
                    height: "15px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Send"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default ShareFile;
