import React, { useState, useRef, useEffect } from "react";
import useSound from "use-sound";
import WaveSurfer from "wavesurfer";
import { ReactMic } from "react-mic";
import Modal from "react-bootstrap/Modal";
import { MESSAGE_SOUND } from "../constant";
import { TYPES } from "../redux/actionTypes";
import { useStopwatch } from "react-timer-hook";
import { useDispatch, useSelector } from "react-redux";
import { sendVoiceInChat } from "../redux/actions/chatAction";
import { UpdateSpecificGroupMessage } from "../redux/actions/groupChatAction";

export default function Microphone({
  conversationId,
  openVoiceModal,
  setVoiceMessage,
  setOpenVoiceModal,
  conversationPurpose,
}) {
  const [record, setRecord] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tempFile, setTempFile] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);

  const dispatch = useDispatch();
  const wavesurfer = useRef(null);
  const formData = new FormData();
  const [send_message_sound] = useSound(MESSAGE_SOUND.SENDING);

  const { socket } = useSelector((state) => state.socketReducer);

  useEffect(() => {
    if (!openVoiceModal || (openVoiceModal && !tempFile)) return;
    wavesurfer.current = WaveSurfer.create({
      container: "#wavesurfer-id",
      waveColor: "black",
      progressColor: "tomato",
      height: 100,
      cursorWidth: 1,
      cursorColor: "lightgrey",
      barWidth: 1,
      normalize: true,
      responsive: true,
      fillParent: true,
    });
    wavesurfer.current.on("ready", () => {
      setPlayerReady(true);
    });

    const handleResize = wavesurfer.current.util.debounce(() => {
      wavesurfer.current.empty();
      wavesurfer.current.drawBuffer();
    }, 150);

    wavesurfer.current.on("play", () => setIsPlaying(true));
    wavesurfer.current.on("pause", () => setIsPlaying(false));
    window.addEventListener("resize", handleResize, false);
  }, [openVoiceModal, tempFile]);
  useEffect(() => {
    if (tempFile) {
      wavesurfer.current.load(tempFile.blobURL);
    }
  }, [tempFile]);

  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false });
  const formattedSeconds = seconds.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedHours = hours.toString().padStart(2, "0");

  useEffect(() => {
    reset();
    pause();
  }, [openVoiceModal]);

  const MyStopwatch = () => {
    return (
      <div>
        <span>{formattedHours}</span>:<span>{formattedMinutes}</span>:
        <span>{formattedSeconds}</span>
      </div>
    );
  };
  const togglePlayback = () => {
    if (!isPlaying) {
      wavesurfer.current.play();
    } else {
      wavesurfer.current.pause();
    }
  };
  const stopPlayback = () => wavesurfer.current.stop();
  const handleDone = async () => {
    if (tempFile) {
      formData.append(`document`, tempFile?.blob, tempFile?.options);
      formData.append(`purpose`, conversationPurpose);
      formData.append(`type`, "voice");
      setLoading(true);
      let res = await dispatch(sendVoiceInChat(formData, conversationId));
      setLoading(false);
      if (res?.success) {
        // handleSendSound();
        setVoiceMessage(tempFile);
        setTempFile(null);
        setRecord(false);
        setOpenVoiceModal(false);
        if (conversationPurpose === "conversation") {
          dispatch({
            type: TYPES.PUSH_MESSAGE,
            payload: res?.data,
          });
          if (Object.keys(socket).length !== 0) {
            socket.emit(`addMessage`, res?.data);
            socket.emit(`messageNotification`, res?.data?.notification);
          }
        } else {
          dispatch(UpdateSpecificGroupMessage(res?.data, conversationId));
          if (Object.keys(socket).length !== 0) {
            socket.emit(`addGroupMessage`, {
              data: res?.data,
              type: "voice",
              groupId: res?.data?.conversationId,
            });
          }
        }
        dispatch({
          type: TYPES.SHOW_PROGRESS,
          payload: 0,
        });
        reset();
        pause();
      }
    }
  };
  const handleCancel = async () => {
    stopRecording();
    setTimeout(() => {
      reset();
      pause();
      setRecord(false);
      setTempFile(null);
      setOpenVoiceModal(false);
    }, 10);
  };
  const startRecording = () => {
    reset();
    setTimeout(() => {
      start();
      setRecord(false);
      setTempFile(null);
      setRecord(true);
    }, 100);
  };
  const stopRecording = () => {
    pause();
    setRecord(false);
  };
  const resetRecording = () => {
    setTimeout(() => {
      reset();
      pause();
    }, 1000);
    reset();
    pause();
    setRecord(false);
    setTempFile(null);
  };
  const onData = (recordedBlob) => {};
  const onStop = (recordedBlob) => {
    setTempFile(recordedBlob);
  };

  return (
    <React.Fragment>
      <Modal
        show={openVoiceModal}
        onHide={openVoiceModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div style={{ border: "2px solid gray", borderRadius: "0.6rem" }}>
          {tempFile && !record && (
            <div
              // onClick={!loading ? handleDone : ""}
              onClick={!loading ? resetRecording : ""}
              style={{
                right: 0,
                width: 40,
                height: 40,
                display: "flex",
                borderRadius: 50,
                cursor: "pointer",
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#dc3545",
                transform: "translate(14px, -14px)",
              }}
              data-toggle="tooltip"
              data-placement="top"
              title="Send voice message"
            >
              {loading ? (
                <div
                  className="spinner-grow text-light"
                  role="status"
                  style={{
                    backgroundColor: "white",
                    width: "15px",
                    height: "15px",
                  }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <i className="fal fa-trash" style={{ color: "white" }}></i>
              )}
            </div>
          )}
          <h2 style={{ margin: 10 }}>Record</h2>
          <div
            style={{
              width: "90%",
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <MyStopwatch />
            {tempFile && (
              <p>
                <i
                  className={isPlaying ? "fa fa-pause" : "fa fa-play"}
                  style={{
                    color: isPlaying ? "red" : "green",
                    fontSize: 15,
                    cursor: "pointer",
                  }}
                  onClick={togglePlayback}
                />
                <i
                  className="fal fa-repeat"
                  style={{
                    color: "white",
                    fontSize: 15,
                    cursor: "pointer",
                    marginLeft: 10,
                  }}
                  onClick={stopPlayback}
                />
              </p>
            )}
          </div>
          <div
            className="mic_recording"
            style={{
              width: "90%",
              height: "100px",
              backgroundColor: "red",
              margin: "auto",
              display: "flex",
              justifyContent: "stretch",
            }}
          >
            {tempFile ? (
              <div style={{ backgroundColor: "lightgray", width: "100%" }}>
                <div
                  id="wavesurfer-id"
                  style={{ width: "100%", height: 100 }}
                />
              </div>
            ) : (
              <ReactMic
                record={record}
                onStop={onStop}
                onData={onData}
                strokeColor="grey"
                backgroundColor="lightgray"
              />
            )}
          </div>
          <div
            style={{
              margin: 20,
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <button
              onClick={!loading ? handleCancel : ""}
              className="btn btn-lg btn-outline-danger d-flex align-items-center"
              style={{
                width: "100px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Cancel
            </button>
            {record ? (
              <button
                className="btn btn-lg btn-danger d-flex align-items-center"
                style={{
                  width: "100px",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={!loading ? stopRecording : ""}
              >
                Stop
              </button>
            ) : tempFile ? (
              <button
                onClick={!loading ? handleDone : ""}
                className="btn btn-lg btn-success d-flex align-items-center"
                style={{
                  width: "100px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <div
                    className="spinner-grow text-light"
                    role="status"
                    style={{
                      backgroundColor: "white",
                      width: "15px",
                      height: "15px",
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Send"
                )}
              </button>
            ) : (
              <button
                className="btn btn-lg btn-success d-flex align-items-center"
                style={{
                  width: "100px",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={!loading ? startRecording : ""}
              >
                Start
              </button>
            )}
            {/* <button
              className="btn btn-lg btn-outline-primary d-flex align-items-center"
              onClick={handleDownload}
            >
              Download
            </button> */}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
