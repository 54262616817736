import React, { memo, useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, ReactQuill } from "../../components";
import CardHeader from "../../assets/img/core/card-header.png";
import { addResponse, editResponse } from "../../redux/actions/responseAction";

let REQUIRED_FILED = `Required field`;

const Response = ({ setModalShow, modalShow }) => {
  const [responseLoading, setResponseLoading] = useState(false);
  const [quillValue, setQuillValue] = useState("");

  const dispatch = useDispatch();

  const { appSettings } = useSelector((state) => state.applicationReducer);
  const { allDepartments } = useSelector((state) => state.departmentReducer);
  const { responseObj, allResponse } = useSelector(
    (state) => state.responseReducer
  );

  useEffect(() => {
    if (quillValue === "<p><br></p>") setQuillValue("");
  }, [quillValue]);

  let selectedObj = allResponse?.find((x) => {
    return x?._id === responseObj?.responseId;
  });

  useEffect(() => {
    if (responseObj?.purpose === "edit") {
      setQuillValue(selectedObj?.standard_response);
    }
  }, [selectedObj, responseObj]);
  useEffect(() => {
    if (responseObj?.purpose !== "edit") {
      setQuillValue("");
    }
  }, [responseObj]);

  let INITIAL_VALUES = {
    short_code: "",
    title: "",
    standard_response: "",
    department: allDepartments && allDepartments[0]?._id,
    language: "en",
    is_response_active: false,
  };

  let editedValued = {
    short_code: selectedObj?.short_code,
    title: selectedObj?.title,
    standard_response: selectedObj?.standard_response,
    department: selectedObj?.department,
    language: selectedObj?.language,
    is_response_active: selectedObj?.is_response_active,
  };

  const validationSchema = yup.object({
    short_code: yup
      .string(REQUIRED_FILED)
      .min(3, "Short code must be 3 characters long")
      .required(REQUIRED_FILED),
    title: yup
      .string(REQUIRED_FILED)
      .min(3, "Title must be 3 characters long")
      .required(REQUIRED_FILED),
    standard_response: yup
      .string(REQUIRED_FILED)
      .min(5, "Response must be 5 characters long")
      .required(REQUIRED_FILED),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      responseObj?.purpose === "edit" ? editedValued : INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!responseLoading) {
        setResponseLoading(true);
        let res = "";
        if (responseObj?.purpose === "edit") {
          res = await dispatch(editResponse(responseObj?.responseId, values));
        } else if (responseObj?.purpose === "addNew") {
          values.application_id = appSettings?._id;
          res = await dispatch(addResponse(values));
        }
        ToastContainer(res);
        if (res?.success) resetForm();
        setResponseLoading(false);
        setModalShow(false);
      }
    },
  });
  const handleEditorChange = (html) => {
    if (html === "<p><br></p>") {
      setQuillValue("");
      formik.setFieldValue("standard_response", "");
      return;
    } else {
      setQuillValue(html);
      formik.setFieldValue("standard_response", html);
    }
  };
  const handleEditorBlur = () => {
    formik.setFieldTouched("standard_response", true);
  };
  return (
    <React.Fragment>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={formik.handleSubmit}>
          <div
            // className="modal fade"
            id="modal-response"
            tabIndex="-1"
            aria-labelledby="modal-response"
            aria-hidden="true"
          >
            <div className="modal-content">
              <div className="modal-body py-0">
                <div className="profile modal-gx-n">
                  <div className="profile-img text-primary rounded-top-xl">
                    <img src={CardHeader} width="100%" />
                    <div className="position-absolute top-0 start-0 p-5">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setModalShow(false)}
                        className="btn-close btn-close-white btn-close-arrow opacity-100"
                      />
                    </div>
                  </div>
                  <div className="profile-body">
                    <div className="avatar avatar-lg">
                      <span className="avatar-text bg-primary">
                        <i className="fal fa-clipboard"></i>
                      </span>
                    </div>
                    <h4 className="fw-bold mb-1">
                      {responseObj?.purpose === "edit" ? "Edit" : "New"}{" "}
                      Standard Response
                    </h4>
                    {responseObj?.purpose === "addNew" && (
                      <p style={{ fontSize: "16px" }}>
                        Create a new standard response to be used in the chat.
                      </p>
                    )}
                  </div>
                </div>
                <div className="modal-py">
                  <div className="card-body">
                    <form autoComplete="off">
                      <div className="row gy-6">
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="short_code"
                              placeholder="Enter a short code"
                              name="short_code"
                              value={formik.values.short_code}
                              onChange={formik.handleChange}
                            />
                            <label htmlFor="short_code">Short Code</label>
                            <div id="keyword-help" className="form-text">
                              For example: %hello%
                            </div>
                            <small style={{ color: "red", marginLeft: "10px" }}>
                              {formik.touched.short_code &&
                                formik.errors.short_code}
                            </small>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="response-title"
                              placeholder="Enter title"
                              name="title"
                              value={formik.values.title}
                              onChange={formik.handleChange}
                            />
                            <label htmlFor="response-title">Enter Title</label>
                            <small style={{ color: "red", marginLeft: "10px" }}>
                              {formik.touched.title && formik.errors.title}
                            </small>
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className={
                              localStorage.getItem(`theme`) === "light"
                                ? "form-floating light"
                                : "form-floating dark"
                            }
                          >
                            <textarea
                              className="form-control"
                              placeholder="Description"
                              id="response-message"
                              rows="8"
                              data-autosize="true"
                              style={{ minHeight: "100px" }}
                              name="standard_response"
                              value={formik.values.standard_response}
                              onChange={formik.handleChange}
                            />
                            {/* <ReactQuill
                              placeholder="Standard Response"
                              quillValue={quillValue}
                              handleChange={handleEditorChange}
                              handleEditorBlur={handleEditorBlur}
                            /> */}
                            <label htmlFor="response-message">
                              Standard Response
                            </label>
                            <small style={{ color: "red", marginLeft: "10px" }}>
                              {formik.touched.standard_response &&
                                formik.errors.standard_response}
                            </small>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <select
                              className="form-select"
                              id="response-department"
                              name="department"
                              value={formik.values.department}
                              onChange={formik.handleChange}
                            >
                              {allDepartments?.map((x, index) => (
                                <option key={index} value={x?._id}>
                                  {x?.title}
                                </option>
                              ))}
                            </select>
                            <label htmlFor="response-department">
                              Department
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <select
                              className="form-select"
                              id="response-language"
                              name="language"
                              value={formik.values.language}
                              onChange={formik.handleChange}
                            >
                              <option value="en" defaultValue>
                                EN
                              </option>
                              <option value="de">DE</option>
                              <option value="es">ES</option>
                            </select>
                            <label htmlFor="response-language">Language</label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="mt-8">
                  <div className="d-flex align-items-center mb-4 px-6">
                    <small className="text-muted me-auto">Options</small>
                  </div>
                  <div className="card border-0">
                    <div className="card-body">
                      <div className="row gx-5">
                        <div className="col-auto">
                          <div className="btn btn-sm btn-icon btn-dark">
                            <i className="fal fa-lock"></i>
                          </div>
                        </div>
                        <div className="col">
                          <h5>Active</h5>
                          <p>Is this response active?</p>
                        </div>
                        <div className="col-auto align-self-center">
                          <div className="form-check form-switch ps-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="engage-options-1"
                              name="is_response_active"
                              value={formik.values.is_response_active}
                              onChange={formik.handleChange}
                              checked={formik.values.is_response_active}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="engage-options-1"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="hr-bold modal-gx-n my-0" />
                <div className="modal-py">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                    style={{ height: "56px" }}
                  >
                    {responseLoading ? (
                      <div
                        className="spinner-grow text-light"
                        role="status"
                        style={{
                          backgroundColor: "white",
                          width: "15px",
                          height: "15px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <React.Fragment>
                        {responseObj?.purpose === "edit" ? "Update" : "Save"}
                        <span className="icon ms-auto">
                          <i className="fal fa-chevron-right"></i>
                        </span>
                      </React.Fragment>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </React.Fragment>
  );
};
export default memo(Response);
