import { TYPES } from "../actionTypes";

const { CONNECT_SOCKET_SUCCESS, USER_LOGOUT } = TYPES;

const initialState = {
  socket: {},
};

export default function socketReducer(state = initialState, action) {
  switch (action.type) {
    case CONNECT_SOCKET_SUCCESS:
      return {
        ...state,
        socket: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        socket: {},
      };
    default:
      return state;
  }
}
