import { TYPES } from '../actionTypes';

const { SHOW_MODAL } = TYPES;

export const handleConfirmationModal = (data) => ({
  type: SHOW_MODAL,
  payload: data,
});

export const handleModal = (payload) => async (dispatch) => {
  dispatch(handleConfirmationModal(payload));
};
