/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "./toast";
import {
  deleteEngageEntry,
  setEngageId,
  editEngageEntry,
} from "../redux/actions/engageEntryAction";
import { handleModal } from "../redux/actions/corfirmationModalAction";

const Engage = ({ item, setEngageModalShow }) => {
  const dispatch = useDispatch();

  const deleteSingleEngageEntry = async (EngageEntryId) => {
    let res = await dispatch(deleteEngageEntry(EngageEntryId));
    ToastContainer(res);
  };
  const handleEditStatus = async (EngageEntryId, status) => {
    let res = await dispatch(editEngageEntry(EngageEntryId, status));
    ToastContainer(res);
  };
  const handleEditEngageEntry = (EngageEntryId) => {
    dispatch(setEngageId(EngageEntryId, "edit"));
    setEngageModalShow(true);
  };
  const confirmationModal = (engageId) => {
    dispatch(
      handleModal({
        is_show: true,
        function: deleteSingleEngageEntry,
        param: engageId,
      })
    );
  };
  return (
    <div className="card border-0">
      <div className="card-body">
        <div className="row align-items-center gx-5">
          <div className="col-auto">
            <a href="#" className="avatar @@active">
              <span className="avatar-text">
                {item?.title?.charAt(0).toUpperCase()}
              </span>
            </a>
          </div>

          <div className="col">
            <h5
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            >
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modal-engage"
                onClick={() => handleEditEngageEntry(item?._id)}
              >
                {item?.url}
              </a>
            </h5>
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            >
              Department: {item?.department?.title}
            </p>
          </div>

          <div className="col-auto">
            <div className="dropdown">
              {!item?.is_engage_active && (
                <small>
                  <i
                    className="fal fa-ban"
                    style={{ color: "red", marginRight: "10px" }}
                  />
                </small>
              )}
              <a
                className="icon text-muted"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditEngageEntry(item?._id)}
                    data-bs-toggle="modal"
                    data-bs-target="#modal-engage"
                  >
                    Edit Engage Entry
                  </a>
                </li>

                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleEditStatus(item?._id, {
                        is_engage_active: !item?.is_engage_active,
                      })
                    }
                  >
                    {item?.is_engage_active ? "Pause" : "Active"}
                  </a>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => confirmationModal(item?._id)}
                  >
                    Delete Engage Entry
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Engage);
