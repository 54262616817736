import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { TIME_FORMATES } from "../constant";
import { EmojiCode, VoiceMessage, UploadFile } from ".";

const MessageReview = ({
  msg,
  number,
  member1,
  messagesArray,
  handleAutoScroll,
}) => {
  const { appSettings } = useSelector((state) => state.applicationReducer);

  const DeletedMessage = () => {
    return (
      <div className="message message-out">
        <a
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#modal-user-profile"
          className="avatar avatar-responsive"
        >
          <span className="avatar-text">F</span>
        </a>
        <div className="message-inner">
          <div className="message-body">
            <div className="message-content">
              <div className="message-text">
                <i
                  className="fal fa-ban"
                  style={{
                    color: "rgba(255,0,0,0.9)",
                    marginRight: "10px",
                  }}
                />
                This message was deleted
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  if (msg?.is_delete) {
    return <DeletedMessage />;
  } else {
    switch (msg?.type) {
      case "message":
        return (
          <React.Fragment>
            <div
              className={`message mt-5 ${
                msg?.senderId?._id === member1?._id && "message-out"
              }`}
            >
              {msg?.senderId?._id !==
              messagesArray[number + 1]?.senderId?._id ? (
                <a
                  href="#"
                  data-bs-toggle="modal"
                  // data-bs-target={
                  //   msg?.senderId?._id === userData?.data?._id
                  //     ? "#modal-profile"
                  //     : "#modal-user-profile"
                  // }
                  className="avatar avatar-responsive"
                >
                  {msg?.senderId?.picture ? (
                    <img
                      className="avatar-img round_avatar"
                      src={msg?.senderId?.picture}
                      alt=""
                    />
                  ) : (
                    <span className="avatar-text">
                      {msg?.senderId?.name?.charAt(0)?.toUpperCase()}
                    </span>
                  )}
                </a>
              ) : (
                <span className="avatar avatar-responsive" />
              )}

              <div className="message-inner">
                <div className="message-body">
                  <div className="message-content">
                    <div className="message-text">
                      <p
                        style={{
                          wordWrap: "break-word",
                        }}
                      >
                        <EmojiCode text={msg?.text} />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="message-footer">
                  <span className="extra-small text-muted">
                    {moment(msg?.createdAt).format(
                      appSettings?.application_time_format ===
                        TIME_FORMATES[0]?.format
                        ? "LT"
                        : "HH:mm"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case "reply":
        return (
          <React.Fragment>
            <div
              className={`message mt-5 ${
                msg?.senderId?._id === member1?._id && "message-out"
              }`}
            >
              {msg?.senderId?._id !==
              messagesArray[number + 1]?.senderId?._id ? (
                <a
                  href="#"
                  data-bs-toggle="modal"
                  // data-bs-target={
                  //   msg?.senderId?._id === userData?.data?._id
                  //     ? "#modal-profile"
                  //     : "#modal-user-profile"
                  // }
                  className="avatar avatar-responsive"
                >
                  {msg?.senderId?.picture ? (
                    <img
                      className="avatar-img round_avatar"
                      src={msg?.senderId?.picture}
                      alt=""
                    />
                  ) : (
                    <span className="avatar-text">
                      {msg?.senderId?.name?.charAt(0)?.toUpperCase()}
                    </span>
                  )}
                </a>
              ) : (
                <span className="avatar avatar-responsive" />
              )}
              <div className="message-inner">
                <div className="message-body">
                  <div className="message-content">
                    <div className="message-text">
                      <blockquote className="blockquote overflow-hidden">
                        <h6 className="text-reset text-truncate">
                          {
                            messagesArray?.find((x) => {
                              return x._id === msg?.reply_of_message;
                            })?.senderId?.name
                          }
                        </h6>
                        <p className="small text-truncate">
                          {messagesArray?.find((x) => {
                            return x._id === msg?.reply_of_message;
                          })?.type === "message" ||
                          messagesArray?.find((x) => {
                            return x._id === msg?.reply_of_message;
                          })?.type === "reply" ? (
                            <EmojiCode
                              text={
                                messagesArray?.find((x) => {
                                  return x._id === msg?.reply_of_message;
                                })?.text
                              }
                            />
                          ) : messagesArray?.find((x) => {
                              return x._id === msg?.reply_of_message;
                            })?.type === "images" ? (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "100px",
                                height: "100px",
                                backgroundColor: "transparent",
                              }}
                            >
                              <img
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                src={
                                  messagesArray?.find((x) => {
                                    return x._id === msg?.reply_of_message;
                                  })?.document[0]?.location
                                }
                              />
                            </div>
                          ) : messagesArray?.find((x) => {
                              return x._id === msg?.reply_of_message;
                            })?.type === "files" ? (
                            <>
                              <i className="fal fa-file" />{" "}
                              {
                                messagesArray?.find((x) => {
                                  return x._id === msg?.reply_of_message;
                                })?.document[0]?.originalname
                              }
                            </>
                          ) : messagesArray?.find((x) => {
                              return x._id === msg?.reply_of_message;
                            })?.type === "voice" ? (
                            <React.Fragment>
                              Audio{" "}
                              <i
                                className="fal fa-music"
                                style={{ marginLeft: 5, fontSize: 10 }}
                              />
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </p>
                      </blockquote>
                      <p
                        style={{
                          wordWrap: "break-word",
                        }}
                      >
                        <EmojiCode text={msg?.text} />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="message-footer">
                  <span className="extra-small text-muted">
                    {moment(msg?.createdAt).format(
                      appSettings?.application_time_format ===
                        TIME_FORMATES[0]?.format
                        ? "LT"
                        : "HH:mm"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      case "files":
      case "images":
        return (
          <React.Fragment>
            <UploadFile
              review
              member1={member1}
              msg={msg}
              number={number}
              // handleMessage={handleMessage}
              messagesArray={messagesArray}
              handleAutoScroll={handleAutoScroll}
            />
          </React.Fragment>
        );
      case "notification":
        return (
          <p>
            notification
            <br />
          </p>
        );
      case "voice":
        return (
          <React.Fragment>
            <div
              className={`message mt-5 ${
                msg?.senderId?._id === member1?._id && "message-out"
              }`}
            >
              {msg?.senderId?._id !==
              messagesArray[number + 1]?.senderId?._id ? (
                <a
                  href="#"
                  data-bs-toggle="modal"
                  // data-bs-target={
                  //   msg?.senderId?._id === userData?.data?._id
                  //     ? "#modal-profile"
                  //     : "#modal-user-profile"
                  // }
                  className="avatar avatar-responsive"
                >
                  {msg?.senderId?.picture ? (
                    <img
                      className="avatar-img round_avatar"
                      src={msg?.senderId?.picture}
                      alt=""
                    />
                  ) : (
                    <span className="avatar-text">
                      {msg?.senderId?.name?.charAt(0)?.toUpperCase()}
                    </span>
                  )}
                </a>
              ) : (
                <span className="avatar avatar-responsive" />
              )}
              <div className="message-inner">
                <div className="message-body">
                  <div className="message-content">
                    <VoiceMessage voice={msg?.document[0]?.location} />
                  </div>
                </div>
                <div className="message-footer">
                  <span className="extra-small text-muted">
                    {moment(msg?.createdAt).format(
                      appSettings?.application_time_format ===
                        TIME_FORMATES[0]?.format
                        ? "LT"
                        : "HH:mm"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return <p />;
    }
  }
};

export default MessageReview;
