import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Call = ({
  senderData,
  answerCall,
  declineCall,
  showCallModal,
  setShowCallModal,
}) => {
  return (
    <React.Fragment>
      <Modal
        show={showCallModal}
        onHide={declineCall}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textTransform: "capitalize" }}>
            incoming call{" "}
            <i
              className="fal fa-arrow-down"
              style={{ rotate: "45deg", marginLeft: 5 }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <div className="avatar m-auto" style={{ marginRight: 10 }}>
            {senderData?.picture !== "" ? (
              <img
                className="avatar-img round_avatar"
                src={senderData?.picture}
                alt="#"
              />
            ) : (
              <span className="avatar-text">
                {senderData?.name?.charAt(0)?.toUpperCase()}
              </span>
            )}
          </div>
          <br />
          {senderData?.name} is calling
          <span className="typing-dots">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </span>
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button variant="danger" onClick={declineCall}>
            Decline <i className="fal fa-phone-slash" />
          </Button>
          <Button variant="success" onClick={answerCall}>
            Answer <i className="fal fa-phone" />
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default Call;
