import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Timer = ({ isGroup }) => {
  const { videoCallStatus, groupVideoCallStatus } = useSelector(
    (state) => state.callReducer
  );

  const getStoredStartTime = () => {
    return isGroup
      ? sessionStorage.getItem("groupCallStartTime")
      : sessionStorage.getItem("videoCallStartTime");
  };

  const [startTime, setStartTime] = useState(getStoredStartTime() || null);
  const [elapsedTime, setElapsedTime] = useState(0);

  const updateElapsedTime = () => {
    if (startTime) {
      const currentTime = new Date().getTime();
      const elapsedMilliseconds = currentTime - startTime;
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
      setElapsedTime(elapsedSeconds);
    }
  };

  useEffect(() => {
    if (isGroup) {
      if (
        (groupVideoCallStatus === "connected" ||
          groupVideoCallStatus === "connecting") &&
        !sessionStorage.getItem("groupCallStartTime")
      ) {
        const currentTime = new Date().getTime();
        setStartTime(currentTime);
        sessionStorage.setItem("groupCallStartTime", currentTime);
      }
    }
  }, [groupVideoCallStatus, startTime]);

  useEffect(() => {
    if (!isGroup) {
      if (
        (videoCallStatus === "connected" || videoCallStatus === "connecting") &&
        !sessionStorage.getItem("videoCallStartTime")
      ) {
        const currentTime = new Date().getTime();
        setStartTime(currentTime);
        sessionStorage.setItem("videoCallStartTime", currentTime);
      }
    }
  }, [videoCallStatus, startTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateElapsedTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  const formattedSeconds = (elapsedTime % 60).toString().padStart(2, "0");
  const formattedMinutes = Math.floor(elapsedTime / 60)
    .toString()
    .padStart(2, "0");
  const formattedHours = Math.floor(elapsedTime / 3600)
    .toString()
    .padStart(2, "0");

  return (
    <React.Fragment>
      <div className="m-none">
        <span>{formattedHours}</span>:<span>{formattedMinutes}</span>:
        <span>{formattedSeconds}</span>
      </div>
    </React.Fragment>
  );
};

export default Timer;
