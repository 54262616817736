import { TYPES } from '../actionTypes';
import { axiosClient } from '../../utils';
///////////////////////////////////////////////////////////////////////
const {
  GET_ALL_RESPONSE_LOADING,
  GET_ALL_RESPONSE_SUCCESS,
  GET_ALL_RESPONSE_FAIL,
  SET_RESPONSE_ID,
  UPDATE_ALL_RESPONSE,
  UPDATE_RESPONSE_STATUS,
  DELETE_RESPONSE,
} = TYPES;
///////////////////////////////////////////////////////////////////////
export const GetAllResponseLoading = () => ({
  type: GET_ALL_RESPONSE_LOADING,
});
export const GetAllResponseSuccess = (data) => ({
  type: GET_ALL_RESPONSE_SUCCESS,
  payload: data,
});
export const GetAllResponseFail = (err) => ({
  type: GET_ALL_RESPONSE_FAIL,
  payload: err,
});
/////////////////////
export const SetResponseIdAction = (data) => ({
  type: SET_RESPONSE_ID,
  payload: data,
});
///////////////////////////////////////////////////////////////////////
export const getAllResponse = () => async (dispatch) => {
  dispatch(GetAllResponseLoading());
  return axiosClient()
    .get(`/quickResponse/allResponse`)
    .then((res) => {
      dispatch(GetAllResponseSuccess(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      dispatch(GetAllResponseFail(err?.response?.data?.data));
      return err?.response;
    });
};
/////////////////////
export const addResponse = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`/quickResponse/createResponse`, payload)
    .then((res) => {
      dispatch(UpdateAllResponse(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
/////////////////////
export const deleteResponse = (responseId) => async (dispatch) => {
  return axiosClient()
    .delete(`/quickResponse/deleteResponse/${responseId}`)
    .then((res) => {
      dispatch(DeleteResponse(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
/////////////////////
export const setResponseId = (responseId, purpose) => async (dispatch) => {
  let data = {};
  if (purpose === 'addNew') {
    data = {
      purpose: purpose,
    };
  } else if (purpose === 'edit') {
    data = {
      responseId: responseId,
      purpose: purpose,
    };
  }
  dispatch(SetResponseIdAction(data));
};
/////////////////////
export const editResponse = (responseId, payload) => async (dispatch) => {
  return axiosClient()
    .put(`/quickResponse/editResponseMessage/${responseId}`, payload)
    .then((res) => {
      dispatch(UpdateResponseStatus(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
///////////////////////////////////////////////////////////////////////
export const UpdateAllResponse = (data) => ({
  type: UPDATE_ALL_RESPONSE,
  payload: data,
});
export const UpdateResponseStatus = (data) => ({
  type: UPDATE_RESPONSE_STATUS,
  payload: data,
});
export const DeleteResponse = (data) => ({
  type: DELETE_RESPONSE,
  payload: data,
});
