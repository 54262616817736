import { TYPES } from "../actionTypes";

const {
  GET_ALL_BOTS_LOADING,
  GET_ALL_BOTS_SUCCESS,
  UPDATE_ALL_BOTS,
  UPDATE_DELETED_BOT,
  UPDATE_BOT_STATUS,
  GET_ALL_BOTS_FAIL,
  SET_BOT_ID,
  USER_LOGOUT,
} = TYPES;

const initialState = {
  loading: false,
  allBots: [],
  botObj: {},
  error: false,
};

export default function botReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BOTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_BOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allBots: action.payload,
      };
    case UPDATE_ALL_BOTS:
      state?.allBots?.unshift(action.payload);
      return {
        ...state,
        loading: false,
        allBots: state.allBots,
      };
    case UPDATE_DELETED_BOT:
      let newData = state?.allBots?.filter((x) => {
        return x?._id !== action.payload._id;
      });
      return {
        ...state,
        loading: false,
        allBots: newData,
      };
    case UPDATE_BOT_STATUS:
      let foundIndex = state?.allBots?.findIndex((x) => {
        return x?._id === action?.payload?._id;
      });
      state.allBots[foundIndex] = action.payload;
      return {
        ...state,
        loading: false,
        allBots: state.allBots,
      };
    case GET_ALL_BOTS_FAIL:
      return {
        ...state,
        loading: false,
        allBots: action?.payload,
        error: true,
      };
    case SET_BOT_ID:
      return {
        ...state,
        botObj: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        allBots: [],
        botObj: {},
        error: false,
      };
    default:
      return state;
  }
}
