import React, { memo, useState, useEffect } from "react";
import { Rating } from "../../components";
import { useSelector } from "react-redux";
import CardHeader from "../../assets/img/core/card-header.png";

const RatingModal = () => {
  const [averageRating, setAverageRating] = useState(0);

  const { userData } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (userData?.data?.ratings?.length > 0) {
      const ratingCount = calculateAverageRating(userData?.data?.ratings);
      setAverageRating(ratingCount);
    }
  }, [userData]);

  const calculateAverageRating = (ratings) => {
    if (ratings.length === 0) {
      return 0;
    }

    let sum = 0;
    for (let i = 0; i < ratings.length; i++) {
      sum += ratings[i].ratings;
    }

    const averageRating = sum / ratings.length;
    return averageRating;
  };
  return (
    <div
      className="modal fade"
      id="modal-rating"
      tabIndex="-1"
      aria-labelledby="modal-rating"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-fullscreen-xl-down">
        <div className="modal-content">
          <div className="modal-body py-0">
            <div className="profile modal-gx-n">
              <div className="profile-img text-primary rounded-top-xl">
                <img src={CardHeader} width="100%" />

                <div className="position-absolute top-0 start-0 p-5">
                  <button
                    type="button"
                    className="btn-close btn-close-white btn-close-arrow opacity-100"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>

              <div className="profile-body">
                <div className="avatar avatar-lg">
                  {userData?.data?.picture ? (
                    <img
                      src={userData?.data?.picture}
                      alt="#"
                      className="avatar-img round_avatar"
                    />
                  ) : (
                    <span className="avatar-text bg-primary">
                      {userData?.data?.name?.charAt(0).toUpperCase()}
                    </span>
                  )}
                </div>

                <h4 className="fw-bold mb-1">{userData?.data?.name}</h4>
                <p style={{ fontSize: "16px" }}>
                  All ratings for the selected operator.
                </p>
                <p>{averageRating}/5 Stars</p>
                {/* <p>Total Support Time: 3 hours and 21 minutes</p> */}
              </div>
            </div>

            <div className="modal-py">
              <div className="card-list">
                {userData?.data?.ratings?.map((rating, index) => (
                  <Rating key={index} rating={rating} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(RatingModal);
