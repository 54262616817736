import axios from 'axios';
import Cookies from 'js-cookie';

axios.interceptors.request.use((config) => {
  config.headers.common = {
    'x-auth-token': Cookies.get(`accessToken`),
  };
  return config;
});

export const post = (url, body, params) => {
  return axios({
    method: 'post',
    url: url,
    params: params ? params : {},
    data: body,
  });
};

export const get = (url, params) => {
  return axios({
    method: 'get',
    url: url,
    params: params ? params : {},
  });
};

export const put = (url, body, params) => {
  return axios({
    method: 'put',
    url: url,
    params: params ? params : {},
    data: body,
  });
};
