import { TYPES } from "../actionTypes";

const {
  GET_ALL_DEPARTMENTS_LOADING,
  GET_ALL_DEPARTMENTS_SUCCESS,
  GET_ALL_DEPARTMENTS_FAIL,
  UPDATE_DEPARTMENTS,
  UPDATE_DEPARTMENTS_DETAILS,
  UPDATE_DELETED_DEPARTMENT,
  SET_DEPARTMENT_ID,
  USER_LOGOUT,
} = TYPES;

const initialState = {
  loading: false,
  allDepartments: [],
  appDepartments: [],
  departmentObj: {},
  error: false,
};

export default function departmentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DEPARTMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_DEPARTMENTS_SUCCESS:
      let activeDepartment = action.payload?.filter((x) => {
        return x?.is_department_active;
      });
      return {
        ...state,
        loading: false,
        allDepartments: activeDepartment,
        appDepartments: action.payload,
      };

    case GET_ALL_DEPARTMENTS_FAIL:
      if (action.payload?.message === "User Not Found") {
        action.payload = [];
      }
      return {
        ...state,
        loading: false,
        allDepartments: action?.payload,
        appDepartments: [],
        error: true,
      };

    case UPDATE_DEPARTMENTS:
      state.allDepartments = [action.payload, ...state.allDepartments];
      state.appDepartments = [action.payload, ...state.appDepartments];
      let activeDep = state?.appDepartments?.filter((x) => {
        return x?.is_department_active;
      });
      return {
        ...state,
        loading: false,
        allDepartments: activeDep,
        appDepartments: state.appDepartments,
      };

    case UPDATE_DEPARTMENTS_DETAILS:
      let foundIndex = state?.appDepartments?.findIndex((x) => {
        return x?._id === action.payload?._id;
      });
      state.allDepartments[foundIndex] = action.payload;
      state.appDepartments[foundIndex] = action.payload;
      let filteredDep = state?.appDepartments?.filter((x) => {
        return x?.is_department_active;
      });
      return {
        ...state,
        loading: false,
        allDepartments: filteredDep,
        appDepartments: state.appDepartments,
      };

    case UPDATE_DELETED_DEPARTMENT:
      let newArray = state?.appDepartments.filter((x) => {
        return x?._id !== action.payload?._id;
      });
      let newArray1 = state?.allDepartments.filter((x) => {
        return x?._id !== action.payload?._id;
      });
      return {
        ...state,
        loading: false,
        appDepartments: newArray,
        allDepartments: newArray1,
      };

    case SET_DEPARTMENT_ID:
      return {
        ...state,
        departmentObj: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        allDepartments: [],
        appDepartments: [],
        departmentObj: {},
        error: false,
      };
    default:
      return state;
  }
}
