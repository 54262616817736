import { TYPES } from "../actionTypes";

const {
  GET_ALL_EMAIL_TEMPLATES_LOADING,
  GET_ALL_EMAIL_TEMPLATES_SUCCESS,
  GET_ALL_EMAIL_TEMPLATES_FAIL,
  CREATE_NEW_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_SUCCESS,
} = TYPES;

const initialState = {
  loading: false,
  allEmailTemplates: [],
  error: false,
};

export default function emailTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EMAIL_TEMPLATES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        allEmailTemplates: action.payload,
      };
    case GET_ALL_EMAIL_TEMPLATES_FAIL:
      state.allFaqs?.unshift(action.payload);
      return {
        ...state,
        loading: false,
        allEmailTemplates: [],
        error: true,
      };
    case CREATE_NEW_TEMPLATE_SUCCESS:
      return {
        ...state,
        allEmailTemplates: [...state.allEmailTemplates, action.payload],
      };
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        allEmailTemplates: state.allEmailTemplates?.filter((template) => {
          return template?._id !== action.payload?._id;
        }),
      };
    case UPDATE_TEMPLATE_SUCCESS:
      const templateIndex = state.allEmailTemplates?.findIndex((template) => {
        return template?._id === action.payload?._id;
      });
      state.allEmailTemplates[templateIndex] = action.payload;
      return {
        ...state,
        allEmailTemplates: state.allEmailTemplates,
      };
    default:
      return state;
  }
}
