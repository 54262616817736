import { TYPES } from "../actionTypes";

const {
  USER_LOGOUT,
  SET_CALL_DATA,
  VIDEO_CALL_STATUS,
  VIDEO_CALL_SIGNALS,
  GROUP_VIDEO_CALL_STATUS,
  GROUP_VIDEO_CALL_SIGNALS,
  SET_GROUP_VIDEO_CALL_DATA,
  VIDEO_SIDEBAR,
} = TYPES;

const initialState = {
  videoCallStatus: sessionStorage.getItem("VIDEO_CALL_STATUS")
    ? sessionStorage.getItem("VIDEO_CALL_STATUS")
    : null, // null | connecting | ringing | connected
  videoCallSignal: null,
  videoSidebar: false,
  groupVideoCallStatus: sessionStorage.getItem("GROUP_VIDEO_CALL_STATUS")
    ? sessionStorage.getItem("GROUP_VIDEO_CALL_STATUS")
    : null, // null | connecting | ringing | connected
  groupVideoCallSignal: [],
  callData: sessionStorage.getItem("SET_CALL_DATA")
    ? JSON.parse(sessionStorage.getItem("SET_CALL_DATA"))
    : {},
  groupVideoCallData: sessionStorage.getItem("SET_GROUP_VIDEO_CALL_DATA")
    ? JSON.parse(sessionStorage.getItem("SET_GROUP_VIDEO_CALL_DATA"))
    : {},
};

export default function callReducer(state = initialState, action) {
  switch (action.type) {
    case VIDEO_CALL_STATUS:
      sessionStorage.setItem("VIDEO_CALL_STATUS", action.payload);
      return {
        ...state,
        videoCallStatus: action.payload,
      };
    case VIDEO_CALL_SIGNALS:
      return {
        ...state,
        videoCallSignal: action.payload,
      };
    case VIDEO_SIDEBAR:
      return {
        ...state,
        videoSidebar: action.payload,
      };
    case GROUP_VIDEO_CALL_STATUS:
      sessionStorage.setItem("GROUP_VIDEO_CALL_STATUS", action.payload);
      return {
        ...state,
        groupVideoCallStatus: action.payload,
      };
    case GROUP_VIDEO_CALL_SIGNALS:
      state.groupVideoCallSignal = [
        ...state.groupVideoCallSignal,
        action.payload,
      ];
      return {
        ...state,
        groupVideoCallSignal: state.groupVideoCallSignal,
      };
    case SET_CALL_DATA:
      sessionStorage.setItem("SET_CALL_DATA", JSON.stringify(action.payload));
      return {
        ...state,
        callData: action.payload,
      };

    case SET_GROUP_VIDEO_CALL_DATA:
      sessionStorage.setItem(
        "SET_GROUP_VIDEO_CALL_DATA",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        groupVideoCallData: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        videoCallStatus: null,
        videoCallSignal: null,
      };
    default:
      return state;
  }
}
