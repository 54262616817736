import React, { useState } from "react";

const VoiceMessage = ({ voice }) => {
  return (
    <React.Fragment>
      {voice ? (
        <React.Fragment>
          <audio src={voice} controls />
        </React.Fragment>
      ) : (
        "Voice is not clear"
      )}
    </React.Fragment>
  );
};
export default VoiceMessage;
