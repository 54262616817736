import { TYPES } from '../actionTypes';
import { axiosClient } from '../../utils';
///////////////////////////////////////////////////////////////////////
const {
  GET_ALL_BOTS_LOADING,
  GET_ALL_BOTS_SUCCESS,
  GET_ALL_BOTS_FAIL,
  SET_BOT_ID,
  UPDATE_ALL_BOTS,
  UPDATE_DELETED_BOT,
  UPDATE_BOT_STATUS,
} = TYPES;
///////////////////////////////////////////////////////////////////////
export const GetAllBotsLoading = () => ({
  type: GET_ALL_BOTS_LOADING,
});
export const GetAllBotsSuccess = (data) => ({
  type: GET_ALL_BOTS_SUCCESS,
  payload: data,
});
export const GetAllBotsFail = (err) => ({
  type: GET_ALL_BOTS_FAIL,
  payload: err,
});
////////////////////////
export const SetBotIdAction = (data) => ({
  type: SET_BOT_ID,
  payload: data,
});
///////////////////////////////////////////////////////////////////////
export const getAllBots = () => async (dispatch) => {
  dispatch(GetAllBotsLoading());
  return axiosClient()
    .get(`/bot/allBot`)
    .then((res) => {
      dispatch(GetAllBotsSuccess(res?.data.data));
      return res?.data;
    })
    .catch((err) => {
      dispatch(GetAllBotsFail(err?.response?.data?.data));
      return err?.response?.data;
    });
};
////////////////////////
export const addBot = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`/bot/createBot`, payload)
    .then((res) => {
      dispatch(UpdateAllBots(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////
export const deleteBotById = (botId) => async (dispatch) => {
  return axiosClient()
    .delete(`/bot/deleteBot/${botId}`)
    .then((res) => {
      dispatch(UpdateDeletedBot(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////
export const setBotId = (botId, purpose) => async (dispatch) => {
  let data = {};
  if (purpose === 'addNew') {
    data = {
      purpose: purpose,
    };
  } else if (purpose === 'edit') {
    data = {
      botId: botId,
      purpose: purpose,
    };
  }
  dispatch(SetBotIdAction(data));
};
////////////////////////
export const editBot = (botId, payload) => async (dispatch) => {
  return axiosClient()
    .put(`/bot/editBotMessage/${botId}`, payload)
    .then((res) => {
      dispatch(UpdateBotStatus(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
///////////////////////////////////////////////////////////////////////
export const UpdateAllBots = (data) => ({
  type: UPDATE_ALL_BOTS,
  payload: data,
});
export const UpdateDeletedBot = (data) => ({
  type: UPDATE_DELETED_BOT,
  payload: data,
});
export const UpdateBotStatus = (data) => ({
  type: UPDATE_BOT_STATUS,
  payload: data,
});
