import { TYPES } from "../actionTypes";
import { axiosClient } from "../../utils";
///////////////////////////////////////////////////////////////////////
const { UPDATE_CLIENT_BY_ID, UPDATE_ALL_USERS_ARRAY } = TYPES;
///////////////////////////////////////////////////////////////////////
export const updateClientProfile = (payload, userId) => async (dispatch) => {
  for (const key in payload) {
    if (payload[key] === "remove") delete payload[key];
  }
  return axiosClient()
    .put(`/user/updateClient/${userId}`, payload)
    .then((res) => {
      dispatch(UpdateClientById(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const createClientHandler = (userId, payload) => async (dispatch) => {
  return axiosClient()
    .put(`/user/editUserById/${userId}`, payload)
    .then((res) => {
      dispatch({
        type: UPDATE_ALL_USERS_ARRAY,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const blockClient = (userId, payload) => async (dispatch) => {
  return axiosClient()
    .put(`/user/blockUser/${userId}`, payload)
    .then((res) => {
      dispatch(UpdateClientById(res?.data?.data?.user));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
///////////////////////////////////////////////////////////////////////
export const UpdateClientById = (payload) => ({
  type: UPDATE_CLIENT_BY_ID,
  payload: payload,
});
