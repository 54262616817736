import SendMessageSound from "./sounds/sound1/short-new-IM-message-notification-v1_1.wav"; // Message sending sound
import ReceiveMessageSound from "./sounds/sound1/short-new-IM-message-notification-v1_2.wav"; // Message receiving sound

export const USER_ROLES = {
  SUPER_OPERATOR: "super_operator",
  OPERATOR: "operator",
  JUNIOR_OPERATOR: "junior_operator",
  EDITOR: "editor",
  CLIENT: `client`,
  GUEST: `guest`,
};
export const GROUP_NOTIFICATIONS = {
  group_created: `"%admin" created group "%groupname"`, //Done
  joining_group: `"%username" added by "%admin"`, //Done with Socket
  removing_someone_from_group: `"%username" removed by "%admin"`, //Done with Socket
  leaving_from_group: `"%username" left the group`, //Done with Socket
  edit_group_info: `"%admin" update group details`, //Done with Socket
};
export const NAVIGATOR = {
  NOTIFICATIONS: "NOTIFICATIONS",
  WEB_CHATS: "WEB_CHATS",
  GROUP_CHATS: "GROUP_CHATS",
  FILTER_CHATS: "FILTER_CHATS",
  CUSTOMERS: "CUSTOMERS",
  STANDARD_RESPONSES: "STANDARD_RESPONSES",
  BOTS: "BOTS",
  FAQS: "FAQS",
  SETTINGS: "SETTINGS",
  OPERATORS: "OPERATORS",
};
export const FILE_TYPES = {
  NOTIFICATION: `notification`,
  MESSAGE: `message`,
  REPLY: `reply`,
  FILES: `files`,
  IMAGES: `images`,
  VOICE: `voice`,
  DOCUMENT: `document`,
};
export const MESSAGE_SOUND = {
  SENDING: SendMessageSound,
  RECEIVING: ReceiveMessageSound,
};
export const NOTIFICATION_TYPES = {
  FROM_CLIENT: "notification",
  PRIVATE_MESSAGE: "private_message",
  ACCOUNT_VERIFICATION: "account_activation",
  BLOCKED_GUEST_OR_CLIENT: "blocked_guest_or_client",
  TRANSFER_CHAT_NOTIFICATION: "transfer_chat_notification",
  DENY_CHAT_TRANSFER_REQUEST: "deny_chat_transfer_request",
};
export const TIME_FORMATES = [
  {
    format: "12 hours",
  },
  {
    format: "24 hours",
  },
];

export const emailTempleteEnum = [
  "welcome_email",
  "verification_code",
  "transcript_pdf",
  "new_message",
  "new_conversation",
];
