import { TYPES } from "../actionTypes";
import { axiosClient } from "../../utils";
import Cookies from "js-cookie";
///////////////////////////////////////////////////////////////////////
const {
  GET_APPLICATION_LOADING,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_ERROR,
  UPDATE_APP_LOGO,
  APP_STATICS,
} = TYPES;
///////////////////////////////////////////////////////////////////////
export const GetApplicationLoading = () => ({
  type: GET_APPLICATION_LOADING,
});
export const GetApplicationSuccess = (data) => ({
  type: GET_APPLICATION_SUCCESS,
  payload: data,
});
export const GetApplicationFail = (err) => ({
  type: GET_APPLICATION_ERROR,
  payload: err,
});
///////////////////////////////////////////////////////////////////////
export const getApplication = () => async (dispatch) => {
  dispatch(GetApplicationLoading());
  return axiosClient()
    .get(`application/applicationById`)
    .then((res) => {
      dispatch(GetApplicationSuccess(res?.data.data));
      return res?.data;
    })
    .catch((err) => {
      if (
        !err?.response?.data?.success &&
        err?.response?.data?.message === "Token Is Not Valid"
      ) {
        Cookies.remove("isAuthenticated");
        Cookies.remove("accessToken");
        window.location.reload();
      }
      dispatch(GetApplicationFail(err?.response?.data));
      return err?.response;
    });
};
////////////////////////
export const editApplication = (payload, appId) => async (dispatch) => {
  return axiosClient()
    .put(`/application/editApplicationSettings/${appId}`, payload)
    .then((res) => {
      dispatch(GetApplicationSuccess(res?.data.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const getApplicationStatics = () => async (dispatch) => {
  axiosClient()
    .get(`/user/stats`)
    .then((res) => {
      dispatch({
        type: APP_STATICS,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {});
};
export const changeAppLogo = (payload) => async (dispatch) => {
  return await axiosClient()
    .post(`/application/uploadLogo`, payload)
    .then((res) => {
      dispatch({
        type: UPDATE_APP_LOGO,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
