const PushNotification = (payload, navigate) => {
  // D Structing props
  const { notificationTitle, bodyMessage, icon, notificationId, link } =
    payload;

  // options for notification
  const options = {
    icon: icon, // The path to the notification icon.
    dir: "auto", // The text direction for the body, can be 'auto', 'ltr', or 'rtl'.
    renotify: true, // If true, will renotify the user when a new notification with the same tag is shown.
    body: bodyMessage, // The text content of the notification body.
    tag: notificationId, // A string to identify and group related notifications.
    vibrate: [200, 100, 200], // An array to define a vibration pattern.
    // badge: appSettings?.logo, // An additional icon to display in the notification.
    // image: appSettings?.logo, // An image to display within the notification.
    // lang: "en-US", // The language of the notification text.
    // timestamp: Date.now(), // A timestamp for the notification.
    // requireInteraction: true, // If true, the notification will remain onscreen until the user dismisses it.
    // silent: false, // If true, the notification will not play a sound.
    data: {
      url: link,
    },
  };

  // Initializing notification
  const notification = new Notification(notificationTitle, options);

  if (link) {
    // Accessing onClick event of notification
    notification.onclick = (event) => {
      event?.preventDefault();

      window?.focus();

      // Access the URL from the notification's data

      const url = event.target.data.url;

      navigate(url);

      // // // Open the URL in a new tab or window
      // window.open(url, "_self");
    };
  }
};
export default PushNotification;
