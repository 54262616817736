import React, { memo, useState, useEffect } from "react";
import CardHeader from "../../assets/img/core/card-header.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, ReactQuill } from "../../components";
import Modal from "react-bootstrap/Modal";
import {
  addNewEngageEntry,
  editEngageEntry,
} from "../../redux/actions/engageEntryAction";

let REQUIRED_FILED = `Required field`;

const EngageModal = ({ setEngageModalShow, engageModalShow }) => {
  const [loading, setLoading] = useState(false);
  const [quillValue, setQuillValue] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (quillValue === "<p><br></p>") setQuillValue("");
  }, [quillValue]);

  const { appSettings } = useSelector((state) => state.applicationReducer);
  const { allDepartments } = useSelector((state) => state.departmentReducer);
  const { engageObj, allEngageEntries } = useSelector(
    (state) => state.engageEntryReducer
  );

  let selectedObj = allEngageEntries?.find((x) => {
    return x?._id === engageObj?.engageId;
  });

  useEffect(() => {
    if (engageObj?.purpose === "edit") {
      setQuillValue(selectedObj?.invitation_message);
    }
  }, [selectedObj, engageObj]);
  useEffect(() => {
    if (engageObj?.purpose !== "edit") {
      setQuillValue("");
    }
  }, [engageObj]);

  let INITIAL_VALUES = {
    url: "",
    title: "",
    invitation_message: "",
    language: "en",
    is_engage_active: false,
    is_alert_open: false,
    department: allDepartments && allDepartments[0]?._id,
  };
  let selectedValues = {
    url: selectedObj?.url,
    title: selectedObj?.title,
    invitation_message: selectedObj?.invitation_message,
    language: selectedObj?.language,
    is_engage_active: selectedObj?.is_engage_active,
    is_alert_open: selectedObj?.is_alert_open,
    department: selectedObj?.department,
  };
  ////////////////////////////
  const validationSchema = yup.object({
    url: yup
      .string(REQUIRED_FILED)
      .min(2, "Url must be 2 characters long")
      .required(REQUIRED_FILED),
    title: yup
      .string(REQUIRED_FILED)
      .min(3, "Title must be 3 characters long")
      .required(REQUIRED_FILED),
    invitation_message: yup
      .string(REQUIRED_FILED)
      .min(12, "Message must be 5 characters long")
      .required(REQUIRED_FILED),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues:
      engageObj?.purpose === "edit" ? selectedValues : INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!loading) {
        setLoading(true);
        let res = "";
        if (engageObj?.purpose === "edit") {
          res = await dispatch(editEngageEntry(engageObj?.engageId, values));
        } else if (engageObj?.purpose === "addNew") {
          values.application_id = appSettings?._id;
          res = await dispatch(addNewEngageEntry(values));
        }
        ToastContainer(res);
        if (res?.success) {
          resetForm();
          setEngageModalShow(false);
        }
        setLoading(false);
      }
    },
  });
  const handleEditorChange = (html) => {
    if (html === "<p><br></p>") {
      setQuillValue("");
      formik.setFieldValue("invitation_message", "");
      return;
    } else {
      setQuillValue(html);
      formik.setFieldValue("invitation_message", html);
    }
  };
  const handleEditorBlur = () => {
    formik.setFieldTouched("invitation_message", true);
  };
  return (
    <Modal
      show={engageModalShow}
      onHide={() => setEngageModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          id="modal-engage"
          tabIndex="-1"
          aria-labelledby="modal-engage"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="modal-body py-0">
              <div className="profile modal-gx-n">
                <div className="profile-img text-primary rounded-top-xl">
                  <img src={CardHeader} width="100%" />

                  <div className="position-absolute top-0 start-0 p-5">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setEngageModalShow(false)}
                      className="btn-close btn-close-white btn-close-arrow opacity-100"
                    />
                  </div>
                </div>

                <div className="profile-body">
                  <div className="avatar avatar-lg">
                    <span className="avatar-text bg-primary">
                      <i className="fal fa-phone-alt"></i>
                    </span>
                  </div>

                  <h4 className="fw-bold mb-1">
                    {engageObj?.purpose === "edit" ? "Edit" : "Create New"}{" "}
                    Engage Entry
                  </h4>
                  {engageObj?.purpose === "addNew" && (
                    <p style={{ fontSize: "16px" }}>
                      Automatically invite your website visitors.
                    </p>
                  )}
                </div>
              </div>

              <div className="modal-py">
                <div className="card-body">
                  <form autoComplete="off">
                    <div className="row gy-6">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="engage-url"
                            placeholder="Enter URL for firing engage message"
                            name="url"
                            value={formik.values.url}
                            onChange={formik.handleChange}
                          />

                          <label htmlFor="engage-url">URL</label>

                          <small style={{ color: "red", marginLeft: "10px" }}>
                            {formik.touched.url && formik.errors.url}
                          </small>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="engage-title"
                            placeholder="Enter title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                          />

                          <label htmlFor="engage-title">Enter Title</label>

                          <small
                            style={{
                              color: "red",
                              marginLeft: "10px",
                            }}
                          >
                            {formik.touched.title && formik.errors.title}
                          </small>
                        </div>
                      </div>

                      <div className="col-12">
                        <div
                          className={
                            localStorage.getItem(`theme`) === "light"
                              ? "form-floating light"
                              : "form-floating dark"
                          }
                        >
                          {/* <textarea
                            className="form-control"
                            placeholder="Description"
                            id="engage-message"
                            rows="8"
                            data-autosize="true"
                            style={{ minHeight: '100px' }}
                            name="invitation_message"
                            value={formik.values.invitation_message}
                            onChange={formik.handleChange}
                          ></textarea>

                          <label htmlFor="engage-message">
                            Invitiation Message
                          </label> */}

                          <ReactQuill
                            placeholder="Invitiation Message"
                            quillValue={quillValue}
                            handleChange={handleEditorChange}
                            handleEditorBlur={handleEditorBlur}
                          />

                          <small
                            style={{
                              color: "red",
                              marginLeft: "10px",
                            }}
                          >
                            {formik.touched.invitation_message &&
                              formik.errors.invitation_message}
                          </small>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="engage-department"
                            name="department"
                            value={formik.values.department}
                            onChange={formik.handleChange}
                          >
                            {allDepartments?.map((x, index) => (
                              <option key={index} value={x?._id}>
                                {x?.title}
                              </option>
                            ))}
                          </select>

                          <label htmlFor="engage-department">Department</label>

                          <small
                            style={{
                              color: "red",
                              marginLeft: "10px",
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="engage-language"
                            name="language"
                            value={formik.values.language}
                            onChange={formik.handleChange}
                          >
                            <option value="en" defaultValue>
                              EN
                            </option>

                            <option value="de">DE</option>

                            <option value="es">ES</option>
                          </select>

                          <label htmlFor="engage-language">Language</label>

                          <small
                            style={{
                              color: "red",
                              marginLeft: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="mt-8">
                <div className="d-flex align-items-center mb-4 px-6">
                  <small className="text-muted me-auto">Options</small>
                </div>

                <div className="card border-0">
                  <div className="card-body">
                    <div className="row gx-5">
                      <div className="col-auto">
                        <div className="btn btn-sm btn-icon btn-dark">
                          <i className="fal fa-lock"></i>
                        </div>
                      </div>

                      <div className="col">
                        <h5>Active</h5>

                        <p>Is this engage active?</p>
                      </div>
                      <div className="col-auto align-self-center">
                        <div className="form-check form-switch ps-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="engage-options-1"
                            name="is_engage_active"
                            value={formik.values.is_engage_active}
                            onChange={formik.handleChange}
                            checked={formik.values.is_engage_active}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="engage-options-1"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card border-0">
                  <div className="card-body">
                    <div className="row gx-5">
                      <div className="col-auto">
                        <div className="btn btn-sm btn-icon btn-dark">
                          <i className="fal fa-user"></i>
                        </div>
                      </div>

                      <div className="col">
                        <h5>Show Alert</h5>

                        <p>Show alert or open the chat directly?</p>
                      </div>

                      <div className="col-auto align-self-center">
                        <div className="form-check form-switch ps-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="engage-options-2"
                            name="is_alert_open"
                            value={formik.values.is_alert_open}
                            onChange={formik.handleChange}
                            checked={formik.values.is_alert_open}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="engage-options-2"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="hr-bold modal-gx-n my-0" />

              <div className="modal-py">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary w-100 d-flex align-items-center"
                  style={{ height: "56px" }}
                >
                  {loading ? (
                    <div
                      className="spinner-grow text-light"
                      role="status"
                      style={{
                        backgroundColor: "white",
                        width: "15px",
                        height: "15px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {engageObj?.purpose === "edit" ? "Update" : "Save"}
                      <span className="icon ms-auto">
                        <i className="fal fa-chevron-right"></i>
                      </span>
                    </React.Fragment>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default memo(EngageModal);
