/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InputEmoji from "react-input-emoji";
import { useNavigate } from "react-router-dom";
import { getFileTypes } from "../utils/fileTypes";
import {
  ImageSelector,
  DocumentSelector,
  ToastContainer as Toaster,
  ResponseMessages,
} from ".";

const MessageBar = ({
  inputRef,
  userById,
  fileType,
  startCall,
  setDocument,
  setFileType,
  replyMessage,
  callPageLink,
  screenSharing,
  showVideoCall,
  showVoiceCall,
  currentMessage,
  setMessageType,
  handleNavigate,
  conversationId,
  setReplyMessage,
  setCurrentMessage,
  handleMessageType,
  setOpenVoiceModal,
}) => {
  const [showResponses, setShowResponses] = useState(false);
  const [responseCode, setResponseCode] = useState([]);
  const { appSettings } = useSelector((state) => state.applicationReducer);

  const navigate = useNavigate();

  useEffect(() => {
    setShowResponses(currentMessage?.includes("%"));
    setResponseCode(
      currentMessage?.split(" ")?.filter((item) => {
        return item?.includes("%");
      })
    );
  }, [currentMessage]);

  const handleMessageBar = () => {
    setReplyMessage(null);
    setMessageType("message");
  };

  const handleInputValue = (inputValue) => {
    setCurrentMessage(inputValue);
  };

  return (
    <React.Fragment>
      <div className="chat-footer mt-3 pb-3 pb-lg-7 bottom-0 start-0">
        <div
          className="dz-preview bg-dark"
          id="dz-preview-row"
          data-horizontal-scroll=""
        ></div>
        <div
          className="chat-form rounded-pill bg-dark"
          style={{ position: "relative" }}
        >
          <ResponseMessages
            show={showResponses}
            responseCode={responseCode}
            setCurrentMessage={setCurrentMessage}
            currentMessage={currentMessage}
            inputRef={inputRef}
          />
          <div className="row align-items-center gx-0">
            <div className="col-auto">
              <div
                className="dropdown"
                style={{
                  transform: "translate(5px, 0px)",
                }}
              >
                <a
                  className="icon icon-lg text-muted ms-5"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fal fa-ellipsis-v-alt fa-lg"></i>
                </a>
                <ul className="dropdown-menu">
                  <ImageSelector
                    setDocument={setDocument}
                    setFileType={setFileType}
                    selectedFileTypes={appSettings?.application_file_types}
                  />
                  <DocumentSelector
                    setDocument={setDocument}
                    setFileType={setFileType}
                    selectedFileTypes={appSettings?.application_file_types}
                  />
                  <li>
                    <button
                      className="dropdown-item d-flex align-items-center"
                      onClick={() => setOpenVoiceModal(true)}
                    >
                      Voice Message&nbsp;
                      <div className="icon ms-auto">
                        <i className="fal fa-microphone-alt"></i>
                      </div>
                    </button>
                  </li>
                  {showVoiceCall && (
                    <li>
                      <button
                        className="dropdown-item d-flex align-items-center"
                        disabled={sessionStorage.getItem("streamCheck")}
                        onClick={() => {
                          if (
                            appSettings?.call_api_key &&
                            appSettings?.call_secret_key
                          ) {
                            sessionStorage.setItem("streamCheck", "v-o-i-c-e");
                            startCall("v-o-i-c-e");
                          } else {
                            Toaster({
                              success: false,
                              message:
                                "Please add call API key in App Settings",
                            });
                          }
                          // handleNavigate(`/${callPageLink}/${conversationId}`);
                        }}
                      >
                        Voice Call
                        <div className="icon ms-auto">
                          <i className="fal fa-headset"></i>
                        </div>
                      </button>
                    </li>
                  )}
                  {showVideoCall && (
                    <li>
                      <button
                        className="dropdown-item d-flex align-items-center"
                        disabled={sessionStorage.getItem("streamCheck")}
                        onClick={() => {
                          if (
                            appSettings?.call_api_key &&
                            appSettings?.call_secret_key
                          ) {
                            sessionStorage.setItem(
                              "streamCheck",
                              "s-t-r-e-a-m"
                            );
                            startCall("s-t-r-e-a-m");
                          } else {
                            Toaster({
                              success: false,
                              message:
                                "Please add call API key in App Settings",
                            });
                          }
                          // handleNavigate(`/${callPageLink}/${conversationId}`);
                        }}
                      >
                        Video Call
                        <div className="icon ms-auto">
                          <i className="fal fa-video"></i>
                        </div>
                      </button>
                    </li>
                  )}
                  {screenSharing && (
                    <li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <button className="dropdown-item d-flex align-items-center">
                        Screen Sharing
                        <div className="icon ms-auto">
                          <i className="fal fa-desktop-alt"></i>
                        </div>
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="col">
              {replyMessage !== null && replyMessage && (
                <div
                  className="message-text"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <blockquote className="blockquote overflow-hidden">
                    <h6 className="text-reset text-truncate">
                      {replyMessage?.senderId?.name}
                    </h6>
                    <p className="small text-truncate">
                      {replyMessage?.type === "message" ||
                      replyMessage?.type === "reply" ? (
                        replyMessage?.text
                      ) : replyMessage?.type === "images" ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            width: "100px",
                            height: "100px",
                            backgroundColor: "transparent",
                          }}
                        >
                          <img
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                            src={replyMessage?.document[0]?.location}
                          />
                        </div>
                      ) : replyMessage?.type === "files" ? (
                        replyMessage?.document[0]?.originalname
                      ) : (
                        <React.Fragment>
                          Audio{" "}
                          <i
                            className="fal fa-music"
                            style={{ marginLeft: 5, fontSize: 10 }}
                          />
                        </React.Fragment>
                      )}
                    </p>
                  </blockquote>
                  <i
                    className="fal fa-times"
                    style={{ cursor: "pointer" }}
                    onClick={handleMessageBar}
                  />
                </div>
              )}
              <div
                className="input-group"
                style={{
                  marginLeft: 5,
                }}
              >
                <InputEmoji
                  cleanOnEnter
                  ref={inputRef}
                  value={currentMessage}
                  onEnter={handleMessageType}
                  onChange={handleInputValue}
                  placeholder="Type your message..."
                  theme={localStorage.getItem("theme")}
                  buttonElement={<button>0</button>}
                />
              </div>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-icon btn-primary rounded-circle ms-5"
                onClick={handleMessageType}
              >
                <i className="fal fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default memo(MessageBar);
