import { TYPES } from "../actionTypes";

const {
  GROUP_BY_ID,
  SET_USER_ID,
  USER_LOGOUT,
  DELETE_GROUP,
  ADD_NEW_GROUP,
  SHOW_PROGRESS,
  EDIT_GROUP_BY_ID,
  SET_GROUP_PURPOSE,
  IS_TYPING_IN_GROUP,
  UPDATE_SHARED_FILE,
  ADD_UNREAD_MESSAGE,
  GET_ALL_GROUPS_FAIL,
  UPDATE_ONLINE_STATUS,
  DELETE_GROUP_MESSAGE,
  GET_ALL_GROUPS_LOADING,
  GET_ALL_GROUPS_SUCCESS,
  UPDATE_MESSAGE_BY_INDEX,
  UPDATE_MESSAGE_WITH_SOCKET,
  UPDATE_SPECIFIC_GROUPS_MESSAGES,
} = TYPES;

const initialState = {
  allGroups: [],
  loading: false,
  groupById: {},
  purpose: "",
  percentage: 0,
  userId: "",
};

export default function groupChatReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_GROUPS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        allGroups: action.payload,
        loading: false,
      };
    case GET_ALL_GROUPS_FAIL:
      return {
        ...state,
        allGroups: action?.payload,
        loading: false,
      };
    case ADD_NEW_GROUP:
      return {
        ...state,
        allGroups: [...state.allGroups, action.payload],
      };
    case UPDATE_SPECIFIC_GROUPS_MESSAGES:
      let selectedGroupIndex = state?.allGroups?.findIndex((x) => {
        return x?._id === action?.payload?.groupId;
      });
      state.allGroups[selectedGroupIndex].messages = [
        ...state?.allGroups[selectedGroupIndex]?.messages,
        action.payload.data,
      ];
      state.allGroups[selectedGroupIndex].unread_messages =
        action.payload?.data?.unread_messages;
      let removedGroup = state.allGroups?.find((a) => {
        return a?._id === action.payload.groupId;
      });
      let filteredArray = state.allGroups?.filter((x) => {
        return x?._id !== action?.payload?.groupId;
      });
      removedGroup.updatedAt = Date.now();
      state.allGroups = [removedGroup, ...filteredArray];
      return {
        ...state,
        allGroups: state.allGroups,
      };
    case GROUP_BY_ID:
      let selectedGroup = state?.allGroups?.find((group) => {
        return group?._id === action.payload;
      });
      return {
        ...state,
        groupById: selectedGroup,
      };
    case SET_GROUP_PURPOSE:
      return {
        ...state,
        purpose: action.payload,
      };
    case EDIT_GROUP_BY_ID:
      let index = state.allGroups?.findIndex((x) => {
        return x?._id === action.payload?.data._id;
      });
      state.allGroups[index] = action.payload?.data;
      return {
        ...state,
        allGroups: state.allGroups,
        groupById: action.payload?.data,
      };
    case DELETE_GROUP:
      let deletedGroup = state.allGroups.filter((x) => {
        return x?._id !== action.payload?._id;
      });
      return {
        ...state,
        allGroups: deletedGroup,
      };
    case DELETE_GROUP_MESSAGE:
      let messsageIndex = state.groupById.messages.findIndex((x) => {
        return x._id === action.payload._id;
      });
      state.groupById.messages[messsageIndex] = action.payload;
      return {
        ...state,
        groupById: state.groupById,
      };
    case UPDATE_MESSAGE_BY_INDEX:
      let msgIndex = state?.groupById?.messages?.findIndex((x) => {
        return x?.indexOf === action?.payload?.indexOf;
      });

      state.groupById.messages[msgIndex] = action.payload;
      return {
        ...state,
        groupById: state.groupById,
      };
    case UPDATE_MESSAGE_WITH_SOCKET:
      let currentGroup = state.allGroups.findIndex((x) => {
        return x?._id === action.payload?.groupId;
      });
      let currentMessage = state.allGroups[currentGroup].messages.findIndex(
        (y) => {
          return y?._id === action.payload.data._id;
        }
      );
      state.allGroups[currentGroup].messages[currentMessage] =
        action.payload.data;
      return {
        ...state,
        allGroups: state.allGroups,
      };
    case SHOW_PROGRESS:
      return {
        ...state,
        percentage: action.payload,
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case ADD_UNREAD_MESSAGE:
      let groupIndex = state.allGroups?.findIndex((group) => {
        return group?._id === action.payload?.groupId;
      });
      state.allGroups[groupIndex].unread_messages =
        action.payload.unread_messages;
      return {
        ...state,
        allGroups: state.allGroups,
      };
    case USER_LOGOUT:
      return {
        ...state,
        allGroups: [],
        loading: false,
        groupById: {},
        purpose: "",
        percentage: 0,
        userId: "",
      };
    case UPDATE_ONLINE_STATUS:
      state?.allGroups?.forEach((group) => {
        let memberIndex = group?.group_members?.findIndex((member) => {
          return member?._id === action.payload?.userId;
        });
        group.group_members[memberIndex].is_user_online =
          action.payload?.onlineStatus;
      });
      return {
        ...state,
        // allGroups: state.allGroups
      };
    case IS_TYPING_IN_GROUP:
      let typingGroupIndex = state.allGroups?.findIndex((state) => {
        return state?._id === action.payload?.groupId;
      });
      state.allGroups[typingGroupIndex].is_typing = action.payload?.is_typing;
      return {
        ...state,
      };
    case UPDATE_SHARED_FILE:
      if (action.payload?.length > 0) {
        for (let index = 0; index < action.payload?.length; index++) {
          let selectedGroup = state.allGroups?.find((group) => {
            return group?._id === action.payload[index]?.conversationId;
          });

          if (selectedGroup) {
            selectedGroup.messages = [
              ...selectedGroup?.messages,
              action.payload[index],
            ];
          }
        }
      }
      return {
        ...state,
        allGroups: state.allGroups,
      };
    default:
      return state;
  }
}
