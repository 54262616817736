import { TYPES } from "../actionTypes";

const {
  SET_WIDGET_ID,
  CREATE_WIDGET,
  READ_WIDGET,
  UPDATE_WIDGET,
  DELETE_WIDGET,
} = TYPES;

const initialState = {
  allWidgets: [],
  appWidgets: [],
  widgetObj: {},
};

export default function widgetReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WIDGET_ID:
      return {
        ...state,
        widgetObj: action.payload,
      };
    case READ_WIDGET:
      let availableWidgets = action.payload?.filter((x) => {
        return x?.is_widget_active;
      });
      return {
        ...state,
        appWidgets: action.payload,
        allWidgets: availableWidgets,
      };
    case CREATE_WIDGET:
      state.appWidgets = [action.payload, ...state.appWidgets];
      let availableWidgets2 = state.appWidgets?.filter((x) => {
        return x?.is_widget_active;
      });
      return {
        ...state,
        allWidgets: availableWidgets2,
        appWidgets: state.appWidgets,
      };
    case UPDATE_WIDGET:
      let widgetIndex = state.appWidgets?.findIndex((x) => {
        return x?._id === action.payload?._id;
      });
      state.appWidgets[widgetIndex] = action.payload;
      let availableWidgets3 = state?.appWidgets?.filter((x) => {
        return x?.is_widget_active;
      });
      return {
        ...state,
        allWidgets: availableWidgets3,
        appWidgets: state.appWidgets,
      };
    case DELETE_WIDGET:
      return {
        ...state,
        allWidgets: state.allWidgets.filter((widget) => {
          return widget?._id !== action.payload?._id;
        }),
        appWidgets: state.allWidgets.filter((widget) => {
          return widget?._id !== action.payload?._id;
        }),
      };
    default:
      return state;
  }
}
