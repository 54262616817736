import { TYPES } from "../actionTypes";

const {
  IS_TYPING,
  USER_LOGOUT,
  PUSH_MESSAGE,
  IMAGE_PREVIEW,
  ADD_ID_TO_MESSAGE,
  UPDATE_SHARED_FILE,
  UPDATE_CLIENT_BY_ID,
  DELETE_CONVERSATION,
  UPDATE_DELETE_STATUS,
  UPDATE_ONLINE_STATUS,
  GET_ALL_CONVERSATIONS,
  GET_CONVERSATION_BY_ID,
  UPDATE_SINGLE_OPERATOR,
  CREATE_NEW_CONVERSATION,
  UPDATE_CONVERSATION_BY_ID,
  APPLICATION_CONVERSATIONS,
  UPDATE_CONVERSATION_STATUS,
  GET_ALL_CONVERSATIONS_LOADING,
  APPLICATION_CONVERSATIONS_LOADING,
} = TYPES;

const initialState = {
  appConversationLoading: false,
  conversationLoading: false,
  allConversations: [],
  appConversations: [],
  conversationById: {},
  imagePreview: {},
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CONVERSATIONS_LOADING:
      return {
        ...state,
        conversationLoading: true,
      };
    case GET_ALL_CONVERSATIONS:
      return {
        ...state,
        conversationLoading: false,
        allConversations: action.payload,
      };
    case CREATE_NEW_CONVERSATION:
      return {
        ...state,
        allConversations: [action.payload, ...state.allConversations],
      };
    case GET_CONVERSATION_BY_ID:
      let conversationById = state.allConversations?.find((x) => {
        return x?.recipients?.find((y) => {
          return y?._id === action.payload;
        });
      });

      return {
        ...state,
        conversationById: conversationById,
      };
    case PUSH_MESSAGE:
      let conversationIndex = state.allConversations.findIndex((x) => {
        return x?._id === action.payload?.conversationId;
      });
      if (action.payload._id) {
        if (
          state.allConversations[conversationIndex].messages[
            state.allConversations[conversationIndex].messages?.length - 1
          ]?._id !== action.payload?._id
        ) {
          state.allConversations[conversationIndex].messages = [
            ...state.allConversations[conversationIndex].messages,
            action.payload,
          ];
        }
      } else {
        state.allConversations[conversationIndex].messages = [
          ...state.allConversations[conversationIndex].messages,
          action.payload,
        ];
      }
      state.allConversations[conversationIndex].updatedAt = Date.now();
      return {
        ...state,
        allConversations: state.allConversations,
      };
    case ADD_ID_TO_MESSAGE:
      state.conversationById.messages[action.payload?.indexOf] = action.payload;
      return {
        ...state,
        conversationById: state.conversationById,
      };
    case UPDATE_DELETE_STATUS:
      let deletedMsgIndex = state?.conversationById?.messages?.findIndex(
        (x) => {
          return x?._id === action.payload?._id;
        }
      );
      state.conversationById.messages[deletedMsgIndex] = action.payload;
      return {
        ...state,
        conversationById: state.conversationById,
      };
    case UPDATE_CONVERSATION_STATUS:
      let selectedConversation = state.allConversations?.findIndex((x) => {
        return x?._id === action.payload?._id;
      });
      state.allConversations[selectedConversation].is_read =
        action.payload.is_read;
      return {
        ...state,
        allConversations: state.allConversations,
      };
    case IMAGE_PREVIEW:
      return {
        ...state,
        imagePreview: action.payload,
      };
    case UPDATE_CONVERSATION_BY_ID:
      state.conversationById.is_close = action.payload.is_close;
      state.conversationById.is_mute = action.payload.is_mute;
      state.conversationById.is_delete = action.payload.is_delete;
      return {
        ...state,
        conversationById: state.conversationById,
      };
    case UPDATE_SINGLE_OPERATOR:
    case UPDATE_CLIENT_BY_ID:
      if (
        state.allConversations?.find((x) => {
          return x?.recipients?.find((y) => {
            return y?._id === action.payload?._id;
          });
        })
      ) {
        let conversation = state.allConversations.findIndex((x) => {
          return x?.recipients?.find((y) => {
            return y?._id === action.payload._id;
          });
        });
        let user = state.allConversations[conversation].recipients.findIndex(
          (z) => {
            return z?._id === action.payload._id;
          }
        );
        state.allConversations[conversation].recipients[user] = action.payload;
      }
      return {
        ...state,
        allConversations: state.allConversations,
      };
    case DELETE_CONVERSATION:
      let deletedConversation = state.allConversations?.filter((x) => {
        return x?._id !== action?.payload?._id;
      });
      return {
        ...state,
        allConversations: deletedConversation,
      };
    case USER_LOGOUT:
      return {
        ...state,
        conversationLoading: false,
        allConversations: [],
        conversationById: {},
        imagePreview: {},
      };
    case UPDATE_ONLINE_STATUS:
      let conIndex = state?.allConversations?.findIndex((conversation) => {
        return conversation?.recipients?.find((user) => {
          return user?._id === action.payload?.userId;
        });
      });
      let userIndex = state?.allConversations[conIndex]?.recipients?.findIndex(
        (user) => {
          return user?._id === action.payload?.userId;
        }
      );
      state.allConversations[conIndex].recipients[userIndex].is_user_online =
        action?.payload?.onlineStatus;
      return {
        ...state,
        allConversations: state.allConversations,
      };
    case IS_TYPING:
      let typingChatIndex = state.allConversations?.findIndex(
        (conversation) => {
          return conversation?._id === action.payload?.conversationId;
        }
      );
      state.allConversations[typingChatIndex].is_typing =
        action.payload?.is_typing;
      return {
        ...state,
      };
    case APPLICATION_CONVERSATIONS_LOADING:
      return {
        ...state,
        appConversationLoading: true,
      };
    case APPLICATION_CONVERSATIONS:
      return {
        ...state,
        appConversationLoading: false,
        appConversations: action.payload,
      };
    case UPDATE_SHARED_FILE:
      if (action.payload?.length > 0) {
        for (let index = 0; index < action.payload.length; index++) {
          let selectedChat = state.allConversations?.find((chat) => {
            return chat?._id === action.payload[index]?.conversationId;
          });
          if (selectedChat) {
            selectedChat.messages = [
              ...selectedChat?.messages,
              action.payload[index],
            ];
          }
        }
      }
      return {
        ...state,
        allConversations: state.allConversations,
      };
    default:
      return state;
  }
}
