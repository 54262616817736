import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Avatar from '../../assets/img/avatars/1.jpg';

const Lockscreen = () => {
  const validationSchema = yup.object({
    password: yup
      .string('Enter your password')
      .min(8, 'Password must be of min 8 characters')
      .required('Password is required'),
  });
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });
  const navigate = useNavigate();
  const handleNavigate = (link) => {
    navigate(link);
  };
  return (
    <div className="container">
      <div className="row align-items-center justify-content-center min-vh-100 gx-0">
        <div className="col-12 col-md-5 col-lg-4">
          <div className="card card-shadow border-0">
            <div className="card-body position-relative pt-0 mb-6">
              <div className="position-absolute top-0 start-50 translate-middle">
                <a className="avatar avatar-xl mx-auto border border-light border-5">
                  <img src={Avatar} alt="" className="avatar-img" />
                </a>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row g-6">
                  <div className="col-12">
                    <div className="text-center">
                      <h4 className="fw-bold">William Pearson</h4>
                      <p>
                        Enter your password to <br />
                        unlock the screen
                      </p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="password"
                        className="form-control"
                        id="lockscreen-password"
                        placeholder="Password"
                        name="password"
                        label="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="lockscreen-password">Password</label>
                      <small style={{ color: 'red', marginLeft: '10px' }}>
                        {formik.touched.password && formik.errors.password}
                      </small>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-block btn-lg btn-primary w-100"
                      type="submit"
                    >
                      Unlock
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="text-center mt-8">
            Not you? Return{' '}
            <a
              style={{ cursor: 'pointer', color: '#2787F5' }}
              onClick={() => handleNavigate('/')}
            >
              Sign in.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Lockscreen);
