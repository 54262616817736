import { TYPES } from "../actionTypes";

const {
  GET_ALL_FAQ_LOADING,
  GET_ALL_FAQ_SUCCESS,
  UPDATE_ALL_FAQ,
  DELETE_FAQ_BY_ID,
  UPDATE_FAQ_STATUS,
  GET_ALL_FAQ_FAIL,
  SET_FAQ_ID,
  USER_LOGOUT,
} = TYPES;

const initialState = {
  loading: false,
  allFaqs: [],
  faqObj: {},
  error: false,
};

export default function faqReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FAQ_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        allFaqs: action.payload,
      };
    case UPDATE_ALL_FAQ:
      state.allFaqs?.unshift(action.payload);
      return {
        ...state,
        loading: false,
        allFaqs: state.allFaqs,
      };
    case DELETE_FAQ_BY_ID:
      let newData = state?.allFaqs?.filter((x) => {
        return x?._id !== action.payload._id;
      });
      return {
        ...state,
        loading: false,
        allFaqs: newData,
      };
    case UPDATE_FAQ_STATUS:
      let foundIndex = state?.allFaqs?.findIndex((x) => {
        return x?._id === action?.payload?._id;
      });
      state.allFaqs[foundIndex] = action.payload;
      return {
        ...state,
        loading: false,
        allFaqs: state.allFaqs,
      };
    case GET_ALL_FAQ_FAIL:
      return {
        ...state,
        loading: false,
        allFaqs: action?.payload,
        error: true,
      };
    case SET_FAQ_ID:
      return {
        ...state,
        faqObj: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        allFaqs: [],
        faqObj: {},
        error: false,
      };
    default:
      return state;
  }
}
