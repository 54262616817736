import { Suspense, lazy } from "react";
import { AuthGuard, GuestGuard, LoadingScreen } from "./components";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

//   * PAGES

const TwoStepVerification = Loadable(
  lazy(() => import("./pages/user/2-step-verification"))
);
const Signin = Loadable(lazy(() => import("./pages/user/signin")));
const Signup = Loadable(lazy(() => import("./pages/user/signup")));
const ResetPassword = Loadable(
  lazy(() => import("./pages/user/reset-password"))
);
const Authentification = Loadable(
  lazy(() => import("./pages/user/authentification"))
);
const LockScreen = Loadable(lazy(() => import("./pages/user/lockscreen")));

const Chat = Loadable(lazy(() => import("./pages/chat/main")));
const ChatWidget = Loadable(lazy(() => import("./pages/chat/chat-widget")));
const ChatWidget2 = Loadable(lazy(() => import("./pages/chat/chat-widget-2")));
const ChatWidget3 = Loadable(lazy(() => import("./pages/chat/chat-widget-3")));
const ChatWebWidget = Loadable(
  lazy(() => import("./pages/chat/chat-webwidget"))
);
const ChatFilter = Loadable(lazy(() => import("./pages/chat/chat-filter")));
const ChatVideo = Loadable(lazy(() => import("./pages/chat/chat-call")));
const GroupCall = Loadable(lazy(() => import("./pages/chat/group-call")));
const Meeting = Loadable(lazy(() => import("./pages/chat/meeting")));
const ChatGroup = Loadable(lazy(() => import("./pages/chat/chat-group")));
const ChatEmpty = Loadable(lazy(() => import("./pages/chat/chat-empty")));
const ChatDirect = Loadable(lazy(() => import("./pages/chat/chat-direct")));
const OfflineMessage = Loadable(
  lazy(() => import("./pages/chat/offline-message"))
);
const NewCall = Loadable(lazy(() => import("./pages/chat/new-call")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "signin",
        element: (
          <GuestGuard>
            <Signin />
          </GuestGuard>
        ),
      },
      // {
      //   path: "signup",
      //   element: (
      //     <GuestGuard>
      //       <Signup />
      //     </GuestGuard>
      //   ),
      // },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "otp/:userEmail",
        element: <TwoStepVerification />,
      },
    ],
  },
  {
    path: "*",
    children: [
      {
        index: true,
        element: (
          <AuthGuard>
            <Chat />
          </AuthGuard>
        ),
      },
      {
        path: "user-authentification/:param",
        element: <Authentification />,
      },
      {
        path: "unlock-profile",
        element: <LockScreen />,
      },

      {
        path: "chatwidget",
        element: <ChatWidget />,
      },
      {
        path: "chatwidget2",
        element: <ChatWidget2 />,
      },
      {
        path: "chatwidget3",
        element: <ChatWidget3 />,
      },
      {
        path: "chat-webwidget",
        element: <ChatWebWidget />,
      },
      {
        path: "chat-call-old/:receiverId",
        element: <ChatVideo />,
      },
      {
        path: "chat-group/:id",
        element: <ChatGroup />,
      },
      {
        path: "chat-empty",
        element: <ChatEmpty />,
      },
      {
        path: "group-call/:receiverId",
        element: <GroupCall />,
      },
      {
        path: "meeting/:meetingId",
        element: <Meeting />,
      },
      {
        path: "chat-direct/:id",
        element: <ChatDirect />,
      },
      {
        path: "chat-review/:conversationId",
        element: <ChatFilter />,
      },
      {
        path: "offline-messages",
        element: <OfflineMessage />,
      },
      {
        path: "call/:receiverId",
        element: <NewCall />,
      },
    ],
  },
];

export default routes;
