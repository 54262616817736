import { TYPES } from "../actionTypes";
import { axiosClient } from "../../utils";
///////////////////////////////////////////////////////////////////////
const {
  GET_ALL_GROUPS_LOADING,
  GET_ALL_GROUPS_SUCCESS,
  GET_ALL_GROUPS_FAIL,
  ADD_NEW_GROUP,
  UPDATE_SPECIFIC_GROUPS_MESSAGES,
  GROUP_BY_ID,
  SET_GROUP_PURPOSE,
  EDIT_GROUP_BY_ID,
  DELETE_GROUP,
  DELETE_GROUP_MESSAGE,
  UPDATE_MESSAGE_BY_INDEX,
  UPDATE_MESSAGE_WITH_SOCKET,
  SHOW_PROGRESS,
  ADD_NEW_MESSAGE,
  ADD_UNREAD_MESSAGE,
} = TYPES;
///////////////////////////////////////////////////////////////////////
export const GetAllGroupsLoading = () => ({
  type: GET_ALL_GROUPS_LOADING,
});
export const GetAllGroupsSuccess = (data) => ({
  type: GET_ALL_GROUPS_SUCCESS,
  payload: data,
});
export const GetAllGroupsFail = (err) => ({
  type: GET_ALL_GROUPS_FAIL,
  payload: err,
});
export const AddNewGroup = (data) => ({
  type: ADD_NEW_GROUP,
  payload: data,
});
export const UpdateSpecificGroupMessage = (data, groupId) => ({
  type: UPDATE_SPECIFIC_GROUPS_MESSAGES,
  payload: {
    data: data,
    groupId: groupId,
  },
});
export const GetGroupById = (data) => ({
  type: GROUP_BY_ID,
  payload: data,
});
export const EditGroupById = (data) => ({
  type: EDIT_GROUP_BY_ID,
  payload: data,
});
export const DeleteGroupById = (data) => ({
  type: DELETE_GROUP,
  payload: data,
});
export const DeleteGroupMessage = (data) => ({
  type: DELETE_GROUP_MESSAGE,
  payload: data,
});
export const UpdateGroupMessageByIndex = (data) => ({
  type: UPDATE_MESSAGE_BY_INDEX,
  payload: data,
});
export const UpdateDeletedMsgWithSocket = (data) => ({
  type: UPDATE_MESSAGE_WITH_SOCKET,
  payload: data,
});
export const AddNewMessage = (data) => ({
  type: ADD_NEW_MESSAGE,
  payload: data,
});
export const AddUnreadMessage = (data) => ({
  type: ADD_UNREAD_MESSAGE,
  payload: data,
});
///////////////////////////////////////////////////////////////////////
export const createGroup = (groupData) => async (dispatch) => {
  return axiosClient()
    .post(`/group/createGroup`, groupData)
    .then((res) => {
      dispatch(AddNewGroup(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const getAllGroups = () => async (dispatch) => {
  dispatch(GetAllGroupsLoading());
  return axiosClient()
    .get(`/group/allGroups`)
    .then((res) => {
      dispatch(GetAllGroupsSuccess(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      dispatch(GetAllGroupsFail(err?.response?.data?.data));
      return err?.response?.data;
    });
};
export const getGroupById = (groupId) => async (dispatch) => {
  dispatch(GetGroupById(groupId));
};
export const sendMessageInGroup = (message, groupId) => async (dispatch) => {
  return await axiosClient()
    .post(`/group/createMessageInGroup/${groupId}`, message)
    .then((res) => {
      dispatch(UpdateGroupMessageByIndex(res?.data?.data));
      dispatch(
        AddUnreadMessage({
          groupId: res?.data?.data?.conversationId,
          unread_messages: res?.data?.data?.unread_messages,
        })
      );
      return res?.data?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const setGroupPurpose = (purpose) => async (dispatch) => {
  dispatch({
    type: SET_GROUP_PURPOSE,
    payload: purpose,
  });
};
export const editGroup = (groupData, groupId) => async (dispatch) => {
  return axiosClient()
    .put(`/group/updateGroup/${groupId}`, groupData)
    .then((res) => {
      dispatch(
        EditGroupById({
          data: res?.data?.data,
          groupId: res?.data?.data?._id,
        })
      );
      // dispatch(GetGroupById(groupId));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const deleteGroup = (groupId) => async (dispatch) => {
  return axiosClient()
    .delete(`/group/deleteGroup/${groupId}`)
    .then((res) => {
      dispatch(DeleteGroupById(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const removeMemberById = (payload) => async (dispatch) => {
  return axiosClient()
    .put(`/group/leaveGroup/${payload?.groupId}`, {
      leaveMembers: payload?.memberId,
    })
    .then((res) => {
      dispatch(
        EditGroupById({
          data: res?.data?.data,
          groupId: res?.data?.data?._id,
        })
      );
      dispatch(GetGroupById(payload?.groupId));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const editGroupMessage = (messageId, text) => async (dispatch) => {
  return axiosClient()
    .put(`/message/updateMessage/${messageId}`, { text: text })
    .then((res) => {
      dispatch(DeleteGroupMessage(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const deleteMsgForEveryone =
  (messageId, purpose) => async (dispatch) => {
    return axiosClient()
      .delete(`/message/deleteMessage/${messageId}`, {
        purpose: purpose,
      })
      .then((res) => {
        dispatch(DeleteGroupMessage(res?.data?.data));
        return res?.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  };
export const sendMessageReply = (payload, messageId) => async (dispatch) => {
  return axiosClient()
    .post(`/message/specificReply/${messageId}`, payload)
    .then((res) => {
      dispatch(UpdateGroupMessageByIndex(res?.data?.data));
      dispatch(
        AddUnreadMessage({
          groupId: res?.data?.data?.conversationId,
          unread_messages: res?.data?.data?.unread_messages,
        })
      );
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const sendFilesInGroup =
  (payload, conversationId) => async (dispatch) => {
    let config = {
      onUploadProgress: (progressEvent) => {
        const percent = (progressEvent.loaded / progressEvent.total) * 100;
        dispatch({
          type: SHOW_PROGRESS,
          payload: Math.round(percent),
        });
      },
    };
    return await axiosClient()
      .post(`/message/uploadDocument/${conversationId}`, payload, config)
      .then((res) => {
        dispatch(UpdateSpecificGroupMessage(res?.data?.data, conversationId));
        return res?.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  };
export const readGroupMessages = (groupId) => async (dispatch) => {
  return axiosClient()
    .put(`/group/readConversation/${groupId}`)
    .then((res) => {
      dispatch(
        AddUnreadMessage({
          groupId: res?.data?.data?._id,
          unread_messages: res?.data?.data?.unread_messages,
        })
      );
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
