import React, { memo } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  animation: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      // display: true,
      // text: 'Chart.js Bar Chart',
    },
  },
};

const Chart = ({ data }) => {
  return <Bar options={options} data={data} />;
};

export default memo(Chart);
