/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { handleModal } from "../redux/actions/corfirmationModalAction";
import {
  deleteEmailTemplate,
  editEmailTemplate,
} from "../redux/actions/emailTemplateAction";
import { useDispatch } from "react-redux";
import { ToastContainer } from ".";

const EmailTemplate = ({ item, setAddEmailModalShow }) => {
  const dispatch = useDispatch();

  const deleteTemplate = async (templateId) => {
    let res = await dispatch(deleteEmailTemplate(templateId));
    ToastContainer(res);
  };

  const confirmationModal = (templateId) => {
    dispatch(
      handleModal({
        is_show: true,
        function: deleteTemplate,
        param: templateId,
      })
    );
  };

  const editTemplate = (template) => {
    setAddEmailModalShow({
      isShow: true,
      template,
    });
  };

  const handleTemplateStatus = async (template) => {
    let res = await dispatch(
      editEmailTemplate(template?._id, { is_active: !template?.is_active })
    );
    ToastContainer(res);
  };
  return (
    <div className="card border-0">
      <div className="card-body">
        <div className="row align-items-center gx-5">
          <div className="col-auto">
            <a className="avatar @@active">
              <span className="avatar-text">
                {item?.title?.charAt(0)?.toUpperCase()}
              </span>
            </a>
          </div>

          <div className="col">
            <h5
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            >
              <a href="#" onClick={() => editTemplate(item)}>
                {item?.title ?? ""}
              </a>
            </h5>
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            >
              {item?.template_slug
                ?.replaceAll("_", " ")
                .replace(/\b\w/g, (char) => char.toUpperCase()) ?? ""}
            </p>
          </div>

          <div className="col-auto">
            <div className="dropdown">
              {!item?.is_active && (
                <small style={{ color: "red", marginRight: "10px" }}>
                  <i className="fal fa-ban" />
                </small>
              )}
              <a
                className="icon text-muted"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => editTemplate(item)}
                  >
                    Edit Template
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleTemplateStatus(item)}
                  >
                    {item?.is_active ? "Inactive" : "Active"} Template
                  </a>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => confirmationModal(item?._id)}
                  >
                    Delete Template
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(EmailTemplate);
