import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const ResponseMessages = ({
  show,
  responseCode,
  setCurrentMessage,
  currentMessage,
  inputRef,
}) => {
  const { allResponse } = useSelector((state) => state.responseReducer);

  useEffect(() => {
    if (
      currentMessage?.split(" ")?.find((item) => {
        return item?.includes("%");
      }) !== undefined &&
      allResponse?.find((item) => {
        return (
          item?.short_code ===
          currentMessage?.split(" ")?.find((item) => {
            return item?.includes("%");
          })
        );
      })?.standard_response !== undefined
    ) {
      setCurrentMessage(
        currentMessage?.replace(
          currentMessage?.split(" ")?.find((item) => {
            return item?.includes("%");
          }),
          allResponse?.find((item) => {
            return (
              item?.short_code ===
              currentMessage?.split(" ")?.find((item) => {
                return item?.includes("%");
              })
            );
          })?.standard_response
        )
      );
      moveCursorToEnd();
    }
  }, [currentMessage, responseCode]);

  let filteredResponses = allResponse?.filter((response) =>
    responseCode?.some((filter) =>
      response?.short_code?.toLowerCase()?.startsWith(filter?.toLowerCase())
    )
  );

  const handleFireShortCode = (standardResponse) => {
    setCurrentMessage(
      currentMessage?.replace(responseCode[0], standardResponse)
    );
    moveCursorToEnd();
  };

  const moveCursorToEnd = () => {
    setTimeout(() => {
      var element = document.querySelector(
        'div[contenteditable="true"][tabindex="0"]'
      );
      if (element) {
        element.focus(); // Focus the element
        var range = document.createRange();
        range.selectNodeContents(element);
        range.collapse(false); // Move cursor to the end
        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }, 1);
  };

  return (
    <React.Fragment>
      {show && allResponse?.length && (
        <div className="quick-responses">
          {filteredResponses
            ?.filter((res) => {
              return res?.is_response_active;
            })
            ?.map((item, index) => (
              <p
                key={index}
                style={{ display: "flex", alignItems: "center", gap: 10 }}
                onClick={() => handleFireShortCode(item?.standard_response)}
              >
                <b>{item?.short_code}</b>
                <i>{item?.standard_response}</i>
              </p>
            ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default ResponseMessages;
