import { TYPES } from "../actionTypes";
import { axiosClient } from "../../utils";
import { updateUserById } from "../../services/user-services";
///////////////////////////////////////////////////////////////////////
const {
  GET_ALL_OPERATORS_LOADING,
  GET_ALL_OPERATORS_SUCCESS,
  GET_ALL_OPERATORS_FAIL,
  UPDATE_ALL_OPERATORS,
  DELETE_OPERATOR,
  SET_OPERATOR_ID,
  UPDATE_SINGLE_OPERATOR,
} = TYPES;
///////////////////////////////////////////////////////////////////////
export const GetAllOperatorsLoading = () => ({
  type: GET_ALL_OPERATORS_LOADING,
});
export const GetAllOperatorsSuccess = (data) => ({
  type: GET_ALL_OPERATORS_SUCCESS,
  payload: data,
});
export const GetAllOperatorsFail = (err) => ({
  type: GET_ALL_OPERATORS_FAIL,
  payload: err,
});
////////////////////////
export const SetOperatorIdAction = (data) => ({
  type: SET_OPERATOR_ID,
  payload: data,
});
///////////////////////////////////////////////////////////////////////
export const createOperator = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`/user/signup`, payload)
    .then((res) => {
      dispatch(UpdateAllOperatorsArray(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////
export const getAllOperators = () => async (dispatch) => {
  dispatch(GetAllOperatorsLoading());
  return axiosClient()
    .get(`/user/allOperators`)
    .then((res) => {
      dispatch(GetAllOperatorsSuccess(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      dispatch(GetAllOperatorsFail(err?.response?.data?.data));
      return err?.response?.data;
    });
};
////////////////////////
export const deleteOperator = (operatorId) => async (dispatch) => {
  return axiosClient()
    .delete(`/user/removeUserById/${operatorId}`)
    .then((res) => {
      dispatch(DeleteOperator(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////
export const setOperatorId = (userId, purpose) => async (dispatch) => {
  let data = {};
  if (purpose === "addNew") {
    data = {
      purpose: purpose,
    };
  } else if (purpose === "edit") {
    data = {
      operatorId: userId,
      purpose: purpose,
    };
  }
  dispatch(SetOperatorIdAction(data));
};
////////////////////////
export const updateOperatorProfile = (text, userId) => async (dispatch) => {
  return updateUserById(userId, text)
    .then((res) => {
      dispatch(UpdateOperatorData(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};

export const updateOperator = (payload, userId) => async (dispatch) => {
  return axiosClient()
    .put(`/user/updateOperator/${userId}`, payload)
    .then((res) => {
      dispatch(UpdateOperatorData(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
///////////////////////////////////////////////////////////////////////
export const UpdateAllOperatorsArray = (data) => ({
  type: UPDATE_ALL_OPERATORS,
  payload: data,
});
export const DeleteOperator = (data) => ({
  type: DELETE_OPERATOR,
  payload: data,
});
export const UpdateOperatorData = (payload) => ({
  type: UPDATE_SINGLE_OPERATOR,
  payload: payload,
});
