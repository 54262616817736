import { axiosClient } from "../../utils";
import { TYPES } from "../actionTypes";
////////////////////////////////////////////////////////
const {
  SHOW_PROGRESS,
  UPDATE_SHARED_FILE,
  UPDATE_CONVERSATION,
  DELETE_CONVERSATION,
  UPDATE_DELETE_STATUS,
  GET_ALL_CONVERSATIONS,
  GET_CONVERSATION_BY_ID,
  CREATE_NEW_CONVERSATION,
  UPDATE_ALL_NOTIFICATIONS,
  UPDATE_CONVERSATION_BY_ID,
  APPLICATION_CONVERSATIONS,
  UPDATE_CONVERSATION_STATUS,
  UPDATE_CONVERSATION_BY_INDEX,
  GET_ALL_CONVERSATIONS_LOADING,
  UPDATE_CONVERSATION_WITH_SOCKET,
  APPLICATION_CONVERSATIONS_LOADING,
} = TYPES;
////////////////////////////////////////////////////////
export const getAllConversations = () => async (dispatch) => {
  dispatch({
    type: GET_ALL_CONVERSATIONS_LOADING,
  });
  axiosClient()
    .get(`/conversation/getAllConversations`)
    .then((res) => {
      dispatch({
        type: GET_ALL_CONVERSATIONS,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ALL_CONVERSATIONS,
        payload: [],
      });
    });
};
////////////////////////////////
export const newConversation = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`/conversation/createEmptyConversation`, payload)
    .then((res) => {
      dispatch({
        type: CREATE_NEW_CONVERSATION,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////////////
export const createConversation = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`/conversation/createConversation`, payload)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////////////
export const getConversationById = (recepientId) => async (dispatch) => {
  dispatch({
    type: GET_CONVERSATION_BY_ID,
    payload: recepientId,
  });
};
////////////////////////////////
export const sendMessageReply = (payload, messageId) => async (dispatch) => {
  return await axiosClient()
    .post(`/message/specificReply/${messageId}`, payload)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////////////
export const deleteMessage = (messageId, purpose) => async (dispatch) => {
  return await axiosClient()
    .delete(`/message/deleteMessage/${messageId}`, {
      purpose: purpose,
    })
    .then((res) => {
      dispatch(updateDeleteStatus(res?.data?.data));
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////////////
export const editMessageInChat =
  (messageId, messageText) => async (dispatch) => {
    return await axiosClient()
      .put(`/message/updateMessage/${messageId}`, { text: messageText })
      .then((res) => {
        dispatch(updateDeleteStatus(res?.data?.data));
        return res?.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  };
////////////////////////////////
export const sendFileInChat = (payload, conversationId) => async (dispatch) => {
  let config = {
    onUploadProgress: (progressEvent) => {
      const percent = (progressEvent.loaded / progressEvent.total) * 100;
      dispatch({
        type: SHOW_PROGRESS,
        payload: Math.round(percent),
      });
    },
  };
  return await axiosClient()
    .post(`/message/uploadDocument/${conversationId}`, payload, config)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////////////
export const sendVoiceInChat =
  (payload, conversationId) => async (dispatch) => {
    let config = {
      onUploadProgress: (progressEvent) => {
        const percent = (progressEvent.loaded / progressEvent.total) * 100;
        dispatch({
          type: SHOW_PROGRESS,
          payload: Math.round(percent),
        });
      },
    };
    return await axiosClient()
      .post(`/message/uploadVoice/${conversationId}`, payload, config)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  };
////////////////////////////////
export const updateConversationStatus =
  (conversationId) => async (dispatch) => {
    return await axiosClient()
      .put(`/conversation/updateConversationStatus/${conversationId}`)
      .then((res) => {
        dispatch({
          type: UPDATE_CONVERSATION_STATUS,
          payload: res?.data?.data,
        });
        return res?.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  };
export const updateConversationById =
  (conversationId, payload) => async (dispatch) => {
    return await axiosClient()
      .put(`/conversation/updateConversation/${conversationId}`, payload)
      .then((res) => {
        dispatch({
          type: UPDATE_CONVERSATION_BY_ID,
          payload: res?.data?.data,
        });
        return res?.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  };

export const deleteConversation = (conversationId) => async (dispatch) => {
  return await axiosClient()
    .put(`/conversation/deleteConversation/${conversationId}`)
    .then((res) => {
      dispatch({
        type: DELETE_CONVERSATION,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};

export const filterConversation = (payload) => async (dispatch) => {
  return await axiosClient()
    .post("/conversation/filteredConversation", payload)
    .then((res) => {
      dispatch({
        type: APPLICATION_CONVERSATIONS,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      dispatch({
        type: APPLICATION_CONVERSATIONS,
        payload: [],
      });
      return err?.response?.data;
    });
};

export const applicationConversations = () => async (dispatch) => {
  dispatch({
    type: APPLICATION_CONVERSATIONS_LOADING,
  });
  return await axiosClient()
    .get("/conversation/applicationConversations")
    .then((res) => {
      dispatch({
        type: APPLICATION_CONVERSATIONS,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      dispatch({
        type: APPLICATION_CONVERSATIONS,
        payload: [],
      });
      return err?.response?.data;
    });
};
export const acceptChatTransfer = (data) => async (dispatch) => {
  return await axiosClient()
    .put(
      `/conversation/transferConversation/${data?.conversationId}`,
      data?.payload
    )
    .then((res) => {
      dispatch({
        type: UPDATE_ALL_NOTIFICATIONS,
        payload: {
          data: {
            is_read: true,
            _id: data?.payload?.notificationId,
          },
        },
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const forwardMessage = (payload) => async (dispatch) => {
  return await axiosClient()
    .post(`/message/shareMessage`, { selectedChats: payload })
    .then((res) => {
      dispatch({
        type: UPDATE_SHARED_FILE,
        payload: res?.data?.data,
      });
      return res?.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
////////////////////////////////////////////////////////
export const UpdateConversation = (payload) => ({
  type: UPDATE_CONVERSATION,
  payload: payload,
});
export const UpdateConversationByIndex = (payload) => ({
  type: UPDATE_CONVERSATION_BY_INDEX,
  payload: payload,
});
export const updateDeleteStatus = (payload) => ({
  type: UPDATE_DELETE_STATUS,
  payload: payload,
});
