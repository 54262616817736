import { TYPES } from "../actionTypes";
import { axiosClient } from "../../utils";
import axios from "axios";

export const getCallToken =
  (call_api_key, call_secret_key) => async (dispatch) => {
    const payload = {
      apikey: call_api_key,
      permissions: [`allow_join`],
      call_secret_key,
    };

    return await axiosClient()
      .post("/call/token", payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };

export const createCallRoom = (payload) => async (dispatch) => {
  return await axiosClient()
    .post("/call/createRoom", payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
