import React, { memo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ReactQuillButtons = ({
  placeholder,
  quillValue,
  handleEditorBlur,
  handleChange,
}) => {
  return (
    <div>
      <ReactQuill
        value={quillValue}
        onChange={handleChange}
        onBlur={handleEditorBlur}
        placeholder={placeholder}
        className="form-floating"
        defaultValue={''}
      />
    </div>
  );
};

export default memo(ReactQuillButtons);
