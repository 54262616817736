import { TYPES } from '../actionTypes';
import { axiosClient } from '../../utils';

///////////////////////////////////////////////////////////////////////

const {
  GET_ALL_DEPARTMENTS_LOADING,
  GET_ALL_DEPARTMENTS_SUCCESS,
  GET_ALL_DEPARTMENTS_FAIL,
  UPDATE_DEPARTMENTS,
  UPDATE_DEPARTMENTS_DETAILS,
  UPDATE_DELETED_DEPARTMENT,
  SET_DEPARTMENT_ID,
} = TYPES;

///////////////////////////////////////////////////////////////////////
export const GetAllDepartmentLoading = () => ({
  type: GET_ALL_DEPARTMENTS_LOADING,
});
export const GetAllDepartmentSuccess = (data) => ({
  type: GET_ALL_DEPARTMENTS_SUCCESS,
  payload: data,
});
export const GetAllDepartmentFail = (err) => ({
  type: GET_ALL_DEPARTMENTS_FAIL,
  payload: err,
});
export const SetDepartmentIdAction = (data) => ({
  type: SET_DEPARTMENT_ID,
  payload: data,
});
///////////////////////////////////////////////////////////////////////
export const getAllDepartments = () => async (dispatch) => {
  dispatch(GetAllDepartmentLoading());
  return axiosClient()
    .get(`/department/allDepartment`)
    .then((res) => {
      dispatch(GetAllDepartmentSuccess(res?.data.data));
      return res?.data;
    })
    .catch((err) => {
      dispatch(GetAllDepartmentFail(err?.response?.data?.data));
      return err?.response?.data;
    });
};
export const addNewDepartment = (payload) => async (dispatch) => {
  return axiosClient()
    .post(`/department/createDepartment`, payload)

    .then((res) => {
      dispatch(UpdateDepartments(res?.data?.data));
      return res?.data;
    })

    .catch((err) => {
      return err?.response?.data;
    });
};
export const editDepartment = (departmentId, payload) => async (dispatch) => {
  return axiosClient()
    .put(`/department/editDepartment/${departmentId}`, payload)

    .then((res) => {
      dispatch(UpdateDepartmentDetails(res?.data?.data));
      return res?.data;
    })

    .catch((err) => {
      return err?.response?.data;
    });
};
export const deleteDepartment = (departmentId) => async (dispatch) => {
  return axiosClient()
    .post(`/department/deleteDepartment/${departmentId}`)

    .then((res) => {
      dispatch(updateDeletedDepartment(res?.data?.data));
      return res?.data;
    })

    .catch((err) => {
      return err?.response?.data;
    });
};
export const setDepartmentId = (departmentId, purpose) => async (dispatch) => {
  let data = {};
  if (purpose === 'addNew') {
    data = {
      purpose: purpose,
    };
  } else if (purpose === 'edit') {
    data = {
      departmentId: departmentId,
      purpose: purpose,
    };
  }

  dispatch(SetDepartmentIdAction(data));
};
///////////////////////////////////////////////////////////////////////
export const UpdateDepartments = (data) => ({
  type: UPDATE_DEPARTMENTS,
  payload: data,
});
export const UpdateDepartmentDetails = (data) => ({
  type: UPDATE_DEPARTMENTS_DETAILS,
  payload: data,
});
export const updateDeletedDepartment = (data) => ({
  type: UPDATE_DELETED_DEPARTMENT,
  payload: data,
});
