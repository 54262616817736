import React, { memo, useState } from "react";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { useDispatch } from "react-redux";
import { deleteRating } from "../redux/actions/userAction";

const Rating = ({ rating }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const secondExample = {
    size: 15,
    count: 5,
    color: "#A7A6A8",
    activeColor: "#A7A6A8",
    value: rating?.ratings,
    a11y: true,
    isHalf: true,
    emptyIcon: <i className="fal fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    edit: false,
  };
  const handleRating = (ratingId) => {
    if (!loading) {
      setLoading(true);
      dispatch(deleteRating(ratingId));
      setLoading(false);
    }
  };
  return (
    <div className="card border-0">
      <div className="card-body">
        <div className="row align-items-center gx-5">
          <div className="col-auto">
            <a href="#" className="avatar @@online">
              {rating?.rated_by?.picture ? (
                <img
                  className="avatar-img round_avatar"
                  src={rating?.rated_by?.picture}
                  alt=""
                />
              ) : (
                <span className="avatar-text">
                  {rating?.rated_by?.name?.charAt(0).toUpperCase()}
                </span>
              )}
            </a>
          </div>
          <div className="col">
            <div className="d-flex align-items-center mb-3">
              <h5 className="me-auto mb-0">{rating?.rated_by?.name}</h5>
              {rating?.createdAt && (
                <span className="text-muted extra-small ms-2">
                  {moment(rating?.createdAt)?.format("DD.MM.YYYY h.m")}
                </span>
              )}
            </div>
            {rating?.comment && <p>{rating?.comment}</p>}
            <ReactStars {...secondExample} />
            {/* <p>Time in Chat: 24 Minutes</p> */}
          </div>
          <div className="col-auto">
            <div className="dropdown">
              <a
                className="icon text-muted"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="dropdown-item text-danger"
                    onClick={() => handleRating(rating?._id)}
                  >
                    Delete Rating
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Rating);
