/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useState } from "react";
import { ToastContainer, ToastContainer as Toaster } from ".";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  deleteWidgetById,
  editWidget,
  setWidgetId,
} from "../redux/actions/widgetAction";
import Modal from "react-bootstrap/Modal";

const Widget = ({ item, setModalShow }) => {
  const [widgetCodeModal, setWidgetCodeModal] = useState(false);
  const dispatch = useDispatch();

  const { appSettings } = useSelector((state) => state.applicationReducer);

  const handleEditWidget = () => {
    setModalShow(false);
    setTimeout(() => {
      setModalShow(true);
      dispatch(setWidgetId(item?._id, "edit"));
    }, 1000);
  };
  const handleWidgetStatus = async (isWidget) => {
    let res = await dispatch(editWidget(item?._id, isWidget));
    Toaster(res);
  };
  const copyCode = () => {
    if (appSettings?._id && item?._id) {
      Toaster({
        success: true,
        message: "Code Coppied",
      });
    } else {
      Toaster({
        success: false,
        message: "Something went wrong, Try again",
      });
    }
  };

  let codeForOther = `
          <script>
          window.addEventListener("message", (event) => {
            if (
              event.origin ===
              "https://zipp-chat-script-iframe.vercel.app"
            ) {
              const { iframeHeight, iframeWidth } = event.data;
              const iframe = document.getElementById("myIframe");
              if (iframe) {
                iframe.style.height = iframeHeight;
                iframe.style.width = iframeWidth;
              }
            }
          });
        </script>
        <iframe
          id="myIframe"
          src="https://zipp-chat-script-iframe.vercel.app/?widget_id=${item?._id}&&applicationId=${appSettings?._id}"
          title="Your React App"
          style="border: none; position: fixed; bottom: 0; right: 0"
          allow="camera; microphone"
        ></iframe>
          `;
  let codeForVue = `<Head>
          <script
            dangerouslySetInnerHTML={{
              __html: ${"`"}
        function resizeIframe(event) {
          if (event.origin === 'https://zipp-chat-script-iframe.vercel.app') {
            const { iframeHeight, iframeWidth } = event.data;
            const iframe = document.getElementById('myIframe');
            if (iframe) {
              iframe.style.height = iframeHeight;
              iframe.style.width = iframeWidth;
            }
          }
        }
        window.addEventListener('message', resizeIframe);
        const iframe = document.createElement('iframe');
        iframe.id = 'myIframe';
        iframe.src = 'https://zipp-chat-script-iframe.vercel.app/?widget_id=${
          item?._id
        }&&applicationId=${appSettings?._id}';
        iframe.title = 'Your React App';
        iframe.style.cssText = 'border: none; position: fixed; bottom: 0; right: 0; z-index: 10000;';
        iframe.allow = 'camera; microphone';
        document.body.appendChild(iframe);${"`"}
        ,
            }}
          />
        </Head>`;

  const CopyCodeModal = ({ widgetCodeModal }) => {
    return (
      <React.Fragment>
        <Modal
          show={widgetCodeModal}
          onHide={widgetCodeModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div
            id="modal-bot"
            tabIndex="-1"
            aria-labelledby="modal-bot"
            aria-hidden="true"
            style={
              {
                // border: "3px solid rgba(255,255,255,0.5)",
                // borderRadius: 10,
              }
            }
          >
            <div className="modal-content">
              <div className="modal-body py-0">
                <div className="profile modal-gx-n p-10">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button
                      type="button"
                      className="btn-close btn-close-arrow"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setWidgetCodeModal(false)}
                    />
                    <h2 style={{ textAlign: "center" }}>Get Widget Code</h2>
                    <p></p>
                  </div>
                  <div className="accordion-item mt-10">
                    <div className="accordion-header" id="accordion-widget-1">
                      <a
                        href="#"
                        className="accordion-button text-reset collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-widget-body-1"
                        aria-expanded="false"
                        aria-controls="accordion-widget-body-1"
                      >
                        <div>
                          <h5>Get Widget code</h5>
                          <p>HTML, React, Angular, Vue and wordpress etc</p>
                        </div>
                      </a>
                    </div>
                    <div
                      id="accordion-widget-body-1"
                      className="accordion-collapse collapse"
                      aria-labelledby="accordion-widget-1"
                      data-parent="#accordion-profile"
                    >
                      <div className="accordion-body">
                        <div className="d-flex justify-content-end align-items-center">
                          <CopyToClipboard
                            text={codeForOther}
                            onCopy={copyCode}
                          >
                            <i
                              className="fal fa-copy"
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                marginBottom: "27px",
                              }}
                            />
                          </CopyToClipboard>
                        </div>
                        <div className="accordion-item">
                          <div className="accordion-header widgetCode_scrollbar">
                            {`
                            <script>
                            window.addEventListener("message", (event) => {
                              if (
                                event.origin ===
                                "https://zipp-chat-script-iframe.vercel.app"
                              ) {
                                const { iframeHeight, iframeWidth } = event.data;
                                const iframe = document.getElementById("myIframe");
                                if (iframe) {
                                  iframe.style.height = iframeHeight;
                                  iframe.style.width = iframeWidth;
                                }
                              }
                            });
                          </script>
                      <iframe
                            id="myIframe"
                            src="https://zipp-chat-script-iframe.vercel.app/?widget_id=${item?._id}&&applicationId=${appSettings?._id}"
                            title="Your React App"
                            style="border: none; position: fixed; bottom: 0; right: 0"
                            allow="camera; microphone"
                          ></iframe>
                            `}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mt-10">
                    <div className="accordion-header" id="accordion-widget-1">
                      <a
                        href="#"
                        className="accordion-button text-reset collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#accordion-widget-body-2"
                        aria-expanded="false"
                        aria-controls="accordion-widget-body-2"
                      >
                        <div>
                          <h5>Get Widget code:</h5>
                          <p>Next JS</p>
                        </div>
                      </a>
                    </div>
                    <div
                      id="accordion-widget-body-2"
                      className="accordion-collapse collapse"
                      aria-labelledby="accordion-widget-2"
                      data-parent="#accordion-profile"
                    >
                      <div className="accordion-body">
                        <div className="d-flex justify-content-end align-items-center">
                          <CopyToClipboard text={codeForVue} onCopy={copyCode}>
                            <i
                              className="fal fa-copy"
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                marginBottom: "27px",
                              }}
                            />
                          </CopyToClipboard>
                        </div>
                        <div className="accordion-item">
                          <div className="accordion-header widgetCode_scrollbar">
                            {`<Head>
                              <script
                                dangerouslySetInnerHTML={{
                                  __html: ${"`"}
                            function resizeIframe(event) {
                              if (event.origin === 'https://zipp-chat-script-iframe.vercel.app') {
                                const { iframeHeight, iframeWidth } = event.data;
                                const iframe = document.getElementById('myIframe');
                                if (iframe) {
                                  iframe.style.height = iframeHeight;
                                  iframe.style.width = iframeWidth;
                                }
                              }
                            }
                            window.addEventListener('message', resizeIframe);
                            const iframe = document.createElement('iframe');
                            iframe.id = 'myIframe';
                            iframe.src = 'https://zipp-chat-script-iframe.vercel.app/?widget_id=${
                              item?._id
                            }&&applicationId=${appSettings?._id}';
                            iframe.title = 'Your React App';
                            iframe.style.cssText = 'border: none; position: fixed; bottom: 0; right: 0; z-index: 10000;';
                            iframe.allow = 'camera; microphone';
                            document.body.appendChild(iframe);${"`"}
                          ,
                                }}
                              />
                            </Head>`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  };

  const deleteWidget = async (widgetId) => {
    const deletedWidget = await dispatch(deleteWidgetById(widgetId));
    ToastContainer(deletedWidget);
  };

  return (
    <div className="card border-0">
      <div className="card-body">
        <div className="row align-items-center gx-5">
          <div className="col-auto">
            <a href="#" className="avatar @@active">
              {item?.widget_logo && item?.widget_logo !== "" ? (
                <img src={item?.widget_logo} className="avatar" />
              ) : (
                <span className="avatar-text">
                  {item?.title?.charAt(0)?.toUpperCase()}
                </span>
              )}
            </a>
          </div>
          <div className="col">
            <h5>
              <a href="#">{item?.title}</a>
            </h5>
            <p>{item?.description}</p>
          </div>
          <div className="col-auto">
            <div className="dropdown">
              {!item?.is_widget_active && (
                <small>
                  <i
                    className="fal fa-ban"
                    style={{ color: "red", marginRight: "10px" }}
                  />
                </small>
              )}
              <a
                className="icon text-muted"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => setWidgetCodeModal(true)}
                  >
                    Copy Widget Code
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={handleEditWidget}
                  >
                    Edit Widget
                  </a>
                </li>
                <li
                  onClick={() =>
                    handleWidgetStatus({
                      is_widget_active: !item?.is_widget_active,
                    })
                  }
                >
                  <a className="dropdown-item" href="#">
                    {item?.is_widget_active ? "Pause" : "Active"}
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li onClick={() => deleteWidget(item?._id)}>
                  <a className="dropdown-item text-danger" href="#">
                    Delete Widget
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <CopyCodeModal widgetCodeModal={widgetCodeModal} />
    </div>
  );
};
export default memo(Widget);
