/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "./toast";
import {
  editDepartment,
  deleteDepartment,
  setDepartmentId,
} from "../redux/actions/departmentAction";
import { handleModal } from "../redux/actions/corfirmationModalAction";

const Departments = ({ item, setDepartmentModalShow }) => {
  const dispatch = useDispatch();

  const handleStatus = async (departmentId, status) => {
    let res = await dispatch(editDepartment(departmentId, status));
    ToastContainer(res);
  };
  const deleteSingleDepartment = async (departmentId) => {
    let res = await dispatch(deleteDepartment(departmentId));
    ToastContainer(res);
  };
  const handleEditDepartment = (departmentId, purpose) => {
    dispatch(setDepartmentId(departmentId, purpose));
    setDepartmentModalShow(true);
  };
  const confirmationModal = (departmentId) => {
    dispatch(
      handleModal({
        is_show: true,
        function: deleteSingleDepartment,
        param: departmentId,
      })
    );
  };
  return (
    <div className="card border-0">
      <div className="card-body">
        <div className="row align-items-center gx-5">
          <div className="col-auto">
            <a className="avatar">
              <span className="avatar-text">
                {item?.title?.charAt(0).toUpperCase()}
              </span>
            </a>
          </div>
          <div className="col">
            <h5
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            >
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modal-department"
                onClick={() => handleEditDepartment(item?._id, "edit")}
              >
                {item?.title}
              </a>
            </h5>
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            </p>
          </div>
          <div className="col-auto">
            <div className="dropdown">
              {!item?.is_department_active && (
                <small>
                  <i
                    className="fal fa-ban"
                    style={{ color: "red", marginRight: "10px" }}
                  />
                </small>
              )}
              <a
                className="icon text-muted"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditDepartment(item?._id, "edit")}
                    data-bs-toggle="modal"
                    data-bs-target="#modal-department"
                  >
                    Edit Department
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleStatus(item?._id, {
                        is_department_active: !item?.is_department_active,
                      })
                    }
                  >
                    {item?.is_department_active ? `Pause` : `Active`}
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a
                    className="dropdown-item text-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => confirmationModal(item?._id)}
                  >
                    Delete Department
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Departments);
