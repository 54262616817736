import React, { memo, useState } from "react";
import { handleModal } from "../../redux/actions/corfirmationModalAction";
import { useDispatch } from "react-redux";

const ConfirmationModal = ({ functionName, param }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(
      handleModal({
        is_show: false,
        functionName: "",
        param: "",
      })
    );
    setLoading(false);
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (param) {
      await functionName(param);
    } else {
      await functionName();
    }
    setLoading(false);
    handleCancel();
  };
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        minWidth: "100%",
        minHeight: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 1000000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: 30,
          backgroundColor:
            localStorage.getItem("theme") === "light" ? "white" : "#16191C",
          borderRadius: "20px",
          border:
            localStorage.getItem("theme") === "light"
              ? "none"
              : "3px solid gray",
        }}
      >
        <p style={{ fontSize: "20px" }}>Are you sure to perform this action?</p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            className="btn btn-block btn-lg btn-primary"
            style={{
              width: "100%",
              margin: "10px",
              height: "55px",
            }}
            onClick={handleSubmit}
          >
            {loading ? (
              <div
                className="spinner-grow text-light"
                role="status"
                style={{
                  backgroundColor: "white",
                  width: "15px",
                  height: "15px",
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Yes"
            )}
          </button>
          <button
            className="btn btn-block btn-lg btn-danger"
            style={{
              width: "100%",
              height: "55px",
              backgroundColor:
                localStorage.getItem("theme") === "light" ? "#e90707" : "white",
              color:
                localStorage.getItem("theme") === "light" ? "white" : "black",
              margin: "10px",
            }}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default memo(ConfirmationModal);
